const Xi = (t) => {
  const e = {
    PARENT: "/parent/",
    TUTOR: "/tutor/",
    APPLICANT: "/tutor/",
    SUPER_ADMIN: "/admin/",
    ADMIN: "/admin/",
    MANAGER: "/admin/",
    COORDINATOR: "/admin/"
  };
  if (!Object.keys(e).includes(t))
    throw new Error(`Cannot find root path, role ${t} is unknown`);
  return e[t];
}, Ki = (t, e) => {
  const r = Xi(t);
  e.query.redirect && String(e.query.redirect).startsWith(r) ? window.location.assign(`${import.meta.env.VITE_APP_ROOT_URL}${String(e.query.redirect)}`) : window.location.assign(`${import.meta.env.VITE_APP_ROOT_URL}${r}`);
};
var Pt = function(t, e) {
  return Pt = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(r, n) {
    r.__proto__ = n;
  } || function(r, n) {
    for (var i in n)
      Object.prototype.hasOwnProperty.call(n, i) && (r[i] = n[i]);
  }, Pt(t, e);
};
function W(t, e) {
  if (typeof e != "function" && e !== null)
    throw new TypeError("Class extends value " + String(e) + " is not a constructor or null");
  Pt(t, e);
  function r() {
    this.constructor = t;
  }
  t.prototype = e === null ? Object.create(e) : (r.prototype = e.prototype, new r());
}
var d = function() {
  return d = Object.assign || function(e) {
    for (var r, n = 1, i = arguments.length; n < i; n++) {
      r = arguments[n];
      for (var a in r)
        Object.prototype.hasOwnProperty.call(r, a) && (e[a] = r[a]);
    }
    return e;
  }, d.apply(this, arguments);
};
function ae(t, e) {
  var r = {};
  for (var n in t)
    Object.prototype.hasOwnProperty.call(t, n) && e.indexOf(n) < 0 && (r[n] = t[n]);
  if (t != null && typeof Object.getOwnPropertySymbols == "function")
    for (var i = 0, n = Object.getOwnPropertySymbols(t); i < n.length; i++)
      e.indexOf(n[i]) < 0 && Object.prototype.propertyIsEnumerable.call(t, n[i]) && (r[n[i]] = t[n[i]]);
  return r;
}
function te(t, e, r, n) {
  function i(a) {
    return a instanceof r ? a : new r(function(o) {
      o(a);
    });
  }
  return new (r || (r = Promise))(function(a, o) {
    function s(f) {
      try {
        u(n.next(f));
      } catch (h) {
        o(h);
      }
    }
    function c(f) {
      try {
        u(n.throw(f));
      } catch (h) {
        o(h);
      }
    }
    function u(f) {
      f.done ? a(f.value) : i(f.value).then(s, c);
    }
    u((n = n.apply(t, e || [])).next());
  });
}
function re(t, e) {
  var r = { label: 0, sent: function() {
    if (a[0] & 1)
      throw a[1];
    return a[1];
  }, trys: [], ops: [] }, n, i, a, o;
  return o = { next: s(0), throw: s(1), return: s(2) }, typeof Symbol == "function" && (o[Symbol.iterator] = function() {
    return this;
  }), o;
  function s(u) {
    return function(f) {
      return c([u, f]);
    };
  }
  function c(u) {
    if (n)
      throw new TypeError("Generator is already executing.");
    for (; o && (o = 0, u[0] && (r = 0)), r; )
      try {
        if (n = 1, i && (a = u[0] & 2 ? i.return : u[0] ? i.throw || ((a = i.return) && a.call(i), 0) : i.next) && !(a = a.call(i, u[1])).done)
          return a;
        switch (i = 0, a && (u = [u[0] & 2, a.value]), u[0]) {
          case 0:
          case 1:
            a = u;
            break;
          case 4:
            return r.label++, { value: u[1], done: !1 };
          case 5:
            r.label++, i = u[1], u = [0];
            continue;
          case 7:
            u = r.ops.pop(), r.trys.pop();
            continue;
          default:
            if (a = r.trys, !(a = a.length > 0 && a[a.length - 1]) && (u[0] === 6 || u[0] === 2)) {
              r = 0;
              continue;
            }
            if (u[0] === 3 && (!a || u[1] > a[0] && u[1] < a[3])) {
              r.label = u[1];
              break;
            }
            if (u[0] === 6 && r.label < a[1]) {
              r.label = a[1], a = u;
              break;
            }
            if (a && r.label < a[2]) {
              r.label = a[2], r.ops.push(u);
              break;
            }
            a[2] && r.ops.pop(), r.trys.pop();
            continue;
        }
        u = e.call(t, r);
      } catch (f) {
        u = [6, f], i = 0;
      } finally {
        n = a = 0;
      }
    if (u[0] & 5)
      throw u[1];
    return { value: u[0] ? u[1] : void 0, done: !0 };
  }
}
function H(t, e, r) {
  if (r || arguments.length === 2)
    for (var n = 0, i = e.length, a; n < i; n++)
      (a || !(n in e)) && (a || (a = Array.prototype.slice.call(e, 0, n)), a[n] = e[n]);
  return t.concat(a || Array.prototype.slice.call(e));
}
var bt = "Invariant Violation", Tr = Object.setPrototypeOf, Zi = Tr === void 0 ? function(t, e) {
  return t.__proto__ = e, t;
} : Tr, Ln = (
  /** @class */
  function(t) {
    W(e, t);
    function e(r) {
      r === void 0 && (r = bt);
      var n = t.call(this, typeof r == "number" ? bt + ": " + r + " (see https://github.com/apollographql/invariant-packages)" : r) || this;
      return n.framesToPop = 1, n.name = bt, Zi(n, e.prototype), n;
    }
    return e;
  }(Error)
);
function he(t, e) {
  if (!t)
    throw new Ln(e);
}
var xn = ["debug", "log", "warn", "error", "silent"], ea = xn.indexOf("log");
function Je(t) {
  return function() {
    if (xn.indexOf(t) >= ea) {
      var e = console[t] || console.log;
      return e.apply(console, arguments);
    }
  };
}
(function(t) {
  t.debug = Je("debug"), t.log = Je("log"), t.warn = Je("warn"), t.error = Je("error");
})(he || (he = {}));
var rr = "3.10.4";
function G(t) {
  try {
    return t();
  } catch {
  }
}
const Mt = G(function() {
  return globalThis;
}) || G(function() {
  return window;
}) || G(function() {
  return self;
}) || G(function() {
  return global;
}) || // We don't expect the Function constructor ever to be invoked at runtime, as
// long as at least one of globalThis, window, self, or global is defined, so
// we are under no obligation to make it easy for static analysis tools to
// detect syntactic usage of the Function constructor. If you think you can
// improve your static analysis to detect this obfuscation, think again. This
// is an arms race you cannot win, at least not in JavaScript.
G(function() {
  return G.constructor("return this")();
});
var Ir = /* @__PURE__ */ new Map();
function Ct(t) {
  var e = Ir.get(t) || 1;
  return Ir.set(t, e + 1), "".concat(t, ":").concat(e, ":").concat(Math.random().toString(36).slice(2));
}
function qn(t, e) {
  e === void 0 && (e = 0);
  var r = Ct("stringifyForDisplay");
  return JSON.stringify(t, function(n, i) {
    return i === void 0 ? r : i;
  }, e).split(JSON.stringify(r)).join("<undefined>");
}
function Xe(t) {
  return function(e) {
    for (var r = [], n = 1; n < arguments.length; n++)
      r[n - 1] = arguments[n];
    if (typeof e == "number") {
      var i = e;
      e = nr(i), e || (e = ir(i, r), r = []);
    }
    t.apply(void 0, [e].concat(r));
  };
}
var I = Object.assign(function(e, r) {
  for (var n = [], i = 2; i < arguments.length; i++)
    n[i - 2] = arguments[i];
  e || he(e, nr(r, n) || ir(r, n));
}, {
  debug: Xe(he.debug),
  log: Xe(he.log),
  warn: Xe(he.warn),
  error: Xe(he.error)
});
function U(t) {
  for (var e = [], r = 1; r < arguments.length; r++)
    e[r - 1] = arguments[r];
  return new Ln(nr(t, e) || ir(t, e));
}
var Dr = Symbol.for("ApolloErrorMessageHandler_" + rr);
function jn(t) {
  return typeof t == "string" ? t : qn(t, 2).slice(0, 1e3);
}
function nr(t, e) {
  if (e === void 0 && (e = []), !!t)
    return Mt[Dr] && Mt[Dr](t, e.map(jn));
}
function ir(t, e) {
  if (e === void 0 && (e = []), !!t)
    return "An error occurred! For more details, see the full error text at https://go.apollo.dev/c/err#".concat(encodeURIComponent(JSON.stringify({
      version: rr,
      message: t,
      args: e.map(jn)
    })));
}
function ta(t, e) {
  if (!!!t)
    throw new Error(e);
}
const Vn = {
  Name: [],
  Document: ["definitions"],
  OperationDefinition: [
    "name",
    "variableDefinitions",
    "directives",
    "selectionSet"
  ],
  VariableDefinition: ["variable", "type", "defaultValue", "directives"],
  Variable: ["name"],
  SelectionSet: ["selections"],
  Field: ["alias", "name", "arguments", "directives", "selectionSet"],
  Argument: ["name", "value"],
  FragmentSpread: ["name", "directives"],
  InlineFragment: ["typeCondition", "directives", "selectionSet"],
  FragmentDefinition: [
    "name",
    // Note: fragment variable definitions are deprecated and will removed in v17.0.0
    "variableDefinitions",
    "typeCondition",
    "directives",
    "selectionSet"
  ],
  IntValue: [],
  FloatValue: [],
  StringValue: [],
  BooleanValue: [],
  NullValue: [],
  EnumValue: [],
  ListValue: ["values"],
  ObjectValue: ["fields"],
  ObjectField: ["name", "value"],
  Directive: ["name", "arguments"],
  NamedType: ["name"],
  ListType: ["type"],
  NonNullType: ["type"],
  SchemaDefinition: ["description", "directives", "operationTypes"],
  OperationTypeDefinition: ["type"],
  ScalarTypeDefinition: ["description", "name", "directives"],
  ObjectTypeDefinition: [
    "description",
    "name",
    "interfaces",
    "directives",
    "fields"
  ],
  FieldDefinition: ["description", "name", "arguments", "type", "directives"],
  InputValueDefinition: [
    "description",
    "name",
    "type",
    "defaultValue",
    "directives"
  ],
  InterfaceTypeDefinition: [
    "description",
    "name",
    "interfaces",
    "directives",
    "fields"
  ],
  UnionTypeDefinition: ["description", "name", "directives", "types"],
  EnumTypeDefinition: ["description", "name", "directives", "values"],
  EnumValueDefinition: ["description", "name", "directives"],
  InputObjectTypeDefinition: ["description", "name", "directives", "fields"],
  DirectiveDefinition: ["description", "name", "arguments", "locations"],
  SchemaExtension: ["directives", "operationTypes"],
  ScalarTypeExtension: ["name", "directives"],
  ObjectTypeExtension: ["name", "interfaces", "directives", "fields"],
  InterfaceTypeExtension: ["name", "interfaces", "directives", "fields"],
  UnionTypeExtension: ["name", "directives", "types"],
  EnumTypeExtension: ["name", "directives", "values"],
  InputObjectTypeExtension: ["name", "directives", "fields"]
}, ra = new Set(Object.keys(Vn));
function kr(t) {
  const e = t == null ? void 0 : t.kind;
  return typeof e == "string" && ra.has(e);
}
var Rr;
(function(t) {
  t.QUERY = "query", t.MUTATION = "mutation", t.SUBSCRIPTION = "subscription";
})(Rr || (Rr = {}));
var V;
(function(t) {
  t.NAME = "Name", t.DOCUMENT = "Document", t.OPERATION_DEFINITION = "OperationDefinition", t.VARIABLE_DEFINITION = "VariableDefinition", t.SELECTION_SET = "SelectionSet", t.FIELD = "Field", t.ARGUMENT = "Argument", t.FRAGMENT_SPREAD = "FragmentSpread", t.INLINE_FRAGMENT = "InlineFragment", t.FRAGMENT_DEFINITION = "FragmentDefinition", t.VARIABLE = "Variable", t.INT = "IntValue", t.FLOAT = "FloatValue", t.STRING = "StringValue", t.BOOLEAN = "BooleanValue", t.NULL = "NullValue", t.ENUM = "EnumValue", t.LIST = "ListValue", t.OBJECT = "ObjectValue", t.OBJECT_FIELD = "ObjectField", t.DIRECTIVE = "Directive", t.NAMED_TYPE = "NamedType", t.LIST_TYPE = "ListType", t.NON_NULL_TYPE = "NonNullType", t.SCHEMA_DEFINITION = "SchemaDefinition", t.OPERATION_TYPE_DEFINITION = "OperationTypeDefinition", t.SCALAR_TYPE_DEFINITION = "ScalarTypeDefinition", t.OBJECT_TYPE_DEFINITION = "ObjectTypeDefinition", t.FIELD_DEFINITION = "FieldDefinition", t.INPUT_VALUE_DEFINITION = "InputValueDefinition", t.INTERFACE_TYPE_DEFINITION = "InterfaceTypeDefinition", t.UNION_TYPE_DEFINITION = "UnionTypeDefinition", t.ENUM_TYPE_DEFINITION = "EnumTypeDefinition", t.ENUM_VALUE_DEFINITION = "EnumValueDefinition", t.INPUT_OBJECT_TYPE_DEFINITION = "InputObjectTypeDefinition", t.DIRECTIVE_DEFINITION = "DirectiveDefinition", t.SCHEMA_EXTENSION = "SchemaExtension", t.SCALAR_TYPE_EXTENSION = "ScalarTypeExtension", t.OBJECT_TYPE_EXTENSION = "ObjectTypeExtension", t.INTERFACE_TYPE_EXTENSION = "InterfaceTypeExtension", t.UNION_TYPE_EXTENSION = "UnionTypeExtension", t.ENUM_TYPE_EXTENSION = "EnumTypeExtension", t.INPUT_OBJECT_TYPE_EXTENSION = "InputObjectTypeExtension";
})(V || (V = {}));
function Nr(t) {
  return t === 9 || t === 32;
}
function na(t, e) {
  const r = t.replace(/"""/g, '\\"""'), n = r.split(/\r\n|[\n\r]/g), i = n.length === 1, a = n.length > 1 && n.slice(1).every((p) => p.length === 0 || Nr(p.charCodeAt(0))), o = r.endsWith('\\"""'), s = t.endsWith('"') && !o, c = t.endsWith("\\"), u = s || c, f = (
    // add leading and trailing new lines only if it improves readability
    !i || t.length > 70 || u || a || o
  );
  let h = "";
  const l = i && Nr(t.charCodeAt(0));
  return (f && !l || a) && (h += `
`), h += r, (f || u) && (h += `
`), '"""' + h + '"""';
}
const ia = 10, Qn = 2;
function aa(t) {
  return st(t, []);
}
function st(t, e) {
  switch (typeof t) {
    case "string":
      return JSON.stringify(t);
    case "function":
      return t.name ? `[function ${t.name}]` : "[function]";
    case "object":
      return oa(t, e);
    default:
      return String(t);
  }
}
function oa(t, e) {
  if (t === null)
    return "null";
  if (e.includes(t))
    return "[Circular]";
  const r = [...e, t];
  if (sa(t)) {
    const n = t.toJSON();
    if (n !== t)
      return typeof n == "string" ? n : st(n, r);
  } else if (Array.isArray(t))
    return ca(t, r);
  return ua(t, r);
}
function sa(t) {
  return typeof t.toJSON == "function";
}
function ua(t, e) {
  const r = Object.entries(t);
  return r.length === 0 ? "{}" : e.length > Qn ? "[" + fa(t) + "]" : "{ " + r.map(
    ([i, a]) => i + ": " + st(a, e)
  ).join(", ") + " }";
}
function ca(t, e) {
  if (t.length === 0)
    return "[]";
  if (e.length > Qn)
    return "[Array]";
  const r = Math.min(ia, t.length), n = t.length - r, i = [];
  for (let a = 0; a < r; ++a)
    i.push(st(t[a], e));
  return n === 1 ? i.push("... 1 more item") : n > 1 && i.push(`... ${n} more items`), "[" + i.join(", ") + "]";
}
function fa(t) {
  const e = Object.prototype.toString.call(t).replace(/^\[object /, "").replace(/]$/, "");
  if (e === "Object" && typeof t.constructor == "function") {
    const r = t.constructor.name;
    if (typeof r == "string" && r !== "")
      return r;
  }
  return e;
}
function la(t) {
  return `"${t.replace(ha, pa)}"`;
}
const ha = /[\x00-\x1f\x22\x5c\x7f-\x9f]/g;
function pa(t) {
  return da[t.charCodeAt(0)];
}
const da = [
  "\\u0000",
  "\\u0001",
  "\\u0002",
  "\\u0003",
  "\\u0004",
  "\\u0005",
  "\\u0006",
  "\\u0007",
  "\\b",
  "\\t",
  "\\n",
  "\\u000B",
  "\\f",
  "\\r",
  "\\u000E",
  "\\u000F",
  "\\u0010",
  "\\u0011",
  "\\u0012",
  "\\u0013",
  "\\u0014",
  "\\u0015",
  "\\u0016",
  "\\u0017",
  "\\u0018",
  "\\u0019",
  "\\u001A",
  "\\u001B",
  "\\u001C",
  "\\u001D",
  "\\u001E",
  "\\u001F",
  "",
  "",
  '\\"',
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  // 2F
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  // 3F
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  // 4F
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "\\\\",
  "",
  "",
  "",
  // 5F
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  // 6F
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "\\u007F",
  "\\u0080",
  "\\u0081",
  "\\u0082",
  "\\u0083",
  "\\u0084",
  "\\u0085",
  "\\u0086",
  "\\u0087",
  "\\u0088",
  "\\u0089",
  "\\u008A",
  "\\u008B",
  "\\u008C",
  "\\u008D",
  "\\u008E",
  "\\u008F",
  "\\u0090",
  "\\u0091",
  "\\u0092",
  "\\u0093",
  "\\u0094",
  "\\u0095",
  "\\u0096",
  "\\u0097",
  "\\u0098",
  "\\u0099",
  "\\u009A",
  "\\u009B",
  "\\u009C",
  "\\u009D",
  "\\u009E",
  "\\u009F"
], ar = Object.freeze({});
function K(t, e, r = Vn) {
  const n = /* @__PURE__ */ new Map();
  for (const v of Object.values(V))
    n.set(v, va(e, v));
  let i, a = Array.isArray(t), o = [t], s = -1, c = [], u = t, f, h;
  const l = [], p = [];
  do {
    s++;
    const v = s === o.length, E = v && c.length !== 0;
    if (v) {
      if (f = p.length === 0 ? void 0 : l[l.length - 1], u = h, h = p.pop(), E)
        if (a) {
          u = u.slice();
          let S = 0;
          for (const [O, _] of c) {
            const T = O - S;
            _ === null ? (u.splice(T, 1), S++) : u[T] = _;
          }
        } else {
          u = Object.defineProperties(
            {},
            Object.getOwnPropertyDescriptors(u)
          );
          for (const [S, O] of c)
            u[S] = O;
        }
      s = i.index, o = i.keys, c = i.edits, a = i.inArray, i = i.prev;
    } else if (h) {
      if (f = a ? s : o[s], u = h[f], u == null)
        continue;
      l.push(f);
    }
    let g;
    if (!Array.isArray(u)) {
      var y, m;
      kr(u) || ta(!1, `Invalid AST Node: ${aa(u)}.`);
      const S = v ? (y = n.get(u.kind)) === null || y === void 0 ? void 0 : y.leave : (m = n.get(u.kind)) === null || m === void 0 ? void 0 : m.enter;
      if (g = S == null ? void 0 : S.call(e, u, f, h, l, p), g === ar)
        break;
      if (g === !1) {
        if (!v) {
          l.pop();
          continue;
        }
      } else if (g !== void 0 && (c.push([f, g]), !v))
        if (kr(g))
          u = g;
        else {
          l.pop();
          continue;
        }
    }
    if (g === void 0 && E && c.push([f, u]), v)
      l.pop();
    else {
      var b;
      i = {
        inArray: a,
        index: s,
        keys: o,
        edits: c,
        prev: i
      }, a = Array.isArray(u), o = a ? u : (b = r[u.kind]) !== null && b !== void 0 ? b : [], s = -1, c = [], h && p.push(h), h = u;
    }
  } while (i !== void 0);
  return c.length !== 0 ? c[c.length - 1][1] : t;
}
function va(t, e) {
  const r = t[e];
  return typeof r == "object" ? r : typeof r == "function" ? {
    enter: r,
    leave: void 0
  } : {
    enter: t.enter,
    leave: t.leave
  };
}
function ya(t) {
  return K(t, ga);
}
const ma = 80, ga = {
  Name: {
    leave: (t) => t.value
  },
  Variable: {
    leave: (t) => "$" + t.name
  },
  // Document
  Document: {
    leave: (t) => w(t.definitions, `

`)
  },
  OperationDefinition: {
    leave(t) {
      const e = R("(", w(t.variableDefinitions, ", "), ")"), r = w(
        [
          t.operation,
          w([t.name, e]),
          w(t.directives, " ")
        ],
        " "
      );
      return (r === "query" ? "" : r + " ") + t.selectionSet;
    }
  },
  VariableDefinition: {
    leave: ({ variable: t, type: e, defaultValue: r, directives: n }) => t + ": " + e + R(" = ", r) + R(" ", w(n, " "))
  },
  SelectionSet: {
    leave: ({ selections: t }) => B(t)
  },
  Field: {
    leave({ alias: t, name: e, arguments: r, directives: n, selectionSet: i }) {
      const a = R("", t, ": ") + e;
      let o = a + R("(", w(r, ", "), ")");
      return o.length > ma && (o = a + R(`(
`, Ze(w(r, `
`)), `
)`)), w([o, w(n, " "), i], " ");
    }
  },
  Argument: {
    leave: ({ name: t, value: e }) => t + ": " + e
  },
  // Fragments
  FragmentSpread: {
    leave: ({ name: t, directives: e }) => "..." + t + R(" ", w(e, " "))
  },
  InlineFragment: {
    leave: ({ typeCondition: t, directives: e, selectionSet: r }) => w(
      [
        "...",
        R("on ", t),
        w(e, " "),
        r
      ],
      " "
    )
  },
  FragmentDefinition: {
    leave: ({ name: t, typeCondition: e, variableDefinitions: r, directives: n, selectionSet: i }) => (
      // or removed in the future.
      `fragment ${t}${R("(", w(r, ", "), ")")} on ${e} ${R("", w(n, " "), " ")}` + i
    )
  },
  // Value
  IntValue: {
    leave: ({ value: t }) => t
  },
  FloatValue: {
    leave: ({ value: t }) => t
  },
  StringValue: {
    leave: ({ value: t, block: e }) => e ? na(t) : la(t)
  },
  BooleanValue: {
    leave: ({ value: t }) => t ? "true" : "false"
  },
  NullValue: {
    leave: () => "null"
  },
  EnumValue: {
    leave: ({ value: t }) => t
  },
  ListValue: {
    leave: ({ values: t }) => "[" + w(t, ", ") + "]"
  },
  ObjectValue: {
    leave: ({ fields: t }) => "{" + w(t, ", ") + "}"
  },
  ObjectField: {
    leave: ({ name: t, value: e }) => t + ": " + e
  },
  // Directive
  Directive: {
    leave: ({ name: t, arguments: e }) => "@" + t + R("(", w(e, ", "), ")")
  },
  // Type
  NamedType: {
    leave: ({ name: t }) => t
  },
  ListType: {
    leave: ({ type: t }) => "[" + t + "]"
  },
  NonNullType: {
    leave: ({ type: t }) => t + "!"
  },
  // Type System Definitions
  SchemaDefinition: {
    leave: ({ description: t, directives: e, operationTypes: r }) => R("", t, `
`) + w(["schema", w(e, " "), B(r)], " ")
  },
  OperationTypeDefinition: {
    leave: ({ operation: t, type: e }) => t + ": " + e
  },
  ScalarTypeDefinition: {
    leave: ({ description: t, name: e, directives: r }) => R("", t, `
`) + w(["scalar", e, w(r, " ")], " ")
  },
  ObjectTypeDefinition: {
    leave: ({ description: t, name: e, interfaces: r, directives: n, fields: i }) => R("", t, `
`) + w(
      [
        "type",
        e,
        R("implements ", w(r, " & ")),
        w(n, " "),
        B(i)
      ],
      " "
    )
  },
  FieldDefinition: {
    leave: ({ description: t, name: e, arguments: r, type: n, directives: i }) => R("", t, `
`) + e + (Ar(r) ? R(`(
`, Ze(w(r, `
`)), `
)`) : R("(", w(r, ", "), ")")) + ": " + n + R(" ", w(i, " "))
  },
  InputValueDefinition: {
    leave: ({ description: t, name: e, type: r, defaultValue: n, directives: i }) => R("", t, `
`) + w(
      [e + ": " + r, R("= ", n), w(i, " ")],
      " "
    )
  },
  InterfaceTypeDefinition: {
    leave: ({ description: t, name: e, interfaces: r, directives: n, fields: i }) => R("", t, `
`) + w(
      [
        "interface",
        e,
        R("implements ", w(r, " & ")),
        w(n, " "),
        B(i)
      ],
      " "
    )
  },
  UnionTypeDefinition: {
    leave: ({ description: t, name: e, directives: r, types: n }) => R("", t, `
`) + w(
      ["union", e, w(r, " "), R("= ", w(n, " | "))],
      " "
    )
  },
  EnumTypeDefinition: {
    leave: ({ description: t, name: e, directives: r, values: n }) => R("", t, `
`) + w(["enum", e, w(r, " "), B(n)], " ")
  },
  EnumValueDefinition: {
    leave: ({ description: t, name: e, directives: r }) => R("", t, `
`) + w([e, w(r, " ")], " ")
  },
  InputObjectTypeDefinition: {
    leave: ({ description: t, name: e, directives: r, fields: n }) => R("", t, `
`) + w(["input", e, w(r, " "), B(n)], " ")
  },
  DirectiveDefinition: {
    leave: ({ description: t, name: e, arguments: r, repeatable: n, locations: i }) => R("", t, `
`) + "directive @" + e + (Ar(r) ? R(`(
`, Ze(w(r, `
`)), `
)`) : R("(", w(r, ", "), ")")) + (n ? " repeatable" : "") + " on " + w(i, " | ")
  },
  SchemaExtension: {
    leave: ({ directives: t, operationTypes: e }) => w(
      ["extend schema", w(t, " "), B(e)],
      " "
    )
  },
  ScalarTypeExtension: {
    leave: ({ name: t, directives: e }) => w(["extend scalar", t, w(e, " ")], " ")
  },
  ObjectTypeExtension: {
    leave: ({ name: t, interfaces: e, directives: r, fields: n }) => w(
      [
        "extend type",
        t,
        R("implements ", w(e, " & ")),
        w(r, " "),
        B(n)
      ],
      " "
    )
  },
  InterfaceTypeExtension: {
    leave: ({ name: t, interfaces: e, directives: r, fields: n }) => w(
      [
        "extend interface",
        t,
        R("implements ", w(e, " & ")),
        w(r, " "),
        B(n)
      ],
      " "
    )
  },
  UnionTypeExtension: {
    leave: ({ name: t, directives: e, types: r }) => w(
      [
        "extend union",
        t,
        w(e, " "),
        R("= ", w(r, " | "))
      ],
      " "
    )
  },
  EnumTypeExtension: {
    leave: ({ name: t, directives: e, values: r }) => w(["extend enum", t, w(e, " "), B(r)], " ")
  },
  InputObjectTypeExtension: {
    leave: ({ name: t, directives: e, fields: r }) => w(["extend input", t, w(e, " "), B(r)], " ")
  }
};
function w(t, e = "") {
  var r;
  return (r = t == null ? void 0 : t.filter((n) => n).join(e)) !== null && r !== void 0 ? r : "";
}
function B(t) {
  return R(`{
`, Ze(w(t, `
`)), `
}`);
}
function R(t, e, r = "") {
  return e != null && e !== "" ? t + e + r : "";
}
function Ze(t) {
  return R("  ", t.replace(/\n/g, `
  `));
}
function Ar(t) {
  var e;
  return (e = t == null ? void 0 : t.some((r) => r.includes(`
`))) !== null && e !== void 0 ? e : !1;
}
function Fr(t) {
  return t.kind === V.FIELD || t.kind === V.FRAGMENT_SPREAD || t.kind === V.INLINE_FRAGMENT;
}
function Be(t, e) {
  var r = t.directives;
  return !r || !r.length ? !0 : Oa(r).every(function(n) {
    var i = n.directive, a = n.ifArgument, o = !1;
    return a.value.kind === "Variable" ? (o = e && e[a.value.name.value], I(o !== void 0, 68, i.name.value)) : o = a.value.value, i.name.value === "skip" ? !o : o;
  });
}
function je(t, e, r) {
  var n = new Set(t), i = n.size;
  return K(e, {
    Directive: function(a) {
      if (n.delete(a.name.value) && (!r || !n.size))
        return ar;
    }
  }), r ? !n.size : n.size < i;
}
function ba(t) {
  return t && je(["client", "export"], t, !0);
}
function Ea(t) {
  var e = t.name.value;
  return e === "skip" || e === "include";
}
function Oa(t) {
  var e = [];
  return t && t.length && t.forEach(function(r) {
    if (Ea(r)) {
      var n = r.arguments, i = r.name.value;
      I(n && n.length === 1, 69, i);
      var a = n[0];
      I(a.name && a.name.value === "if", 70, i);
      var o = a.value;
      I(o && (o.kind === "Variable" || o.kind === "BooleanValue"), 71, i), e.push({ directive: r, ifArgument: a });
    }
  }), e;
}
const Sa = () => /* @__PURE__ */ Object.create(null), { forEach: _a, slice: Pr } = Array.prototype, { hasOwnProperty: wa } = Object.prototype;
let De = class Un {
  constructor(e = !0, r = Sa) {
    this.weakness = e, this.makeData = r;
  }
  lookup() {
    return this.lookupArray(arguments);
  }
  lookupArray(e) {
    let r = this;
    return _a.call(e, (n) => r = r.getChildTrie(n)), wa.call(r, "data") ? r.data : r.data = this.makeData(Pr.call(e));
  }
  peek() {
    return this.peekArray(arguments);
  }
  peekArray(e) {
    let r = this;
    for (let n = 0, i = e.length; r && n < i; ++n) {
      const a = r.mapFor(e[n], !1);
      r = a && a.get(e[n]);
    }
    return r && r.data;
  }
  remove() {
    return this.removeArray(arguments);
  }
  removeArray(e) {
    let r;
    if (e.length) {
      const n = e[0], i = this.mapFor(n, !1), a = i && i.get(n);
      a && (r = a.removeArray(Pr.call(e, 1)), !a.data && !a.weak && !(a.strong && a.strong.size) && i.delete(n));
    } else
      r = this.data, delete this.data;
    return r;
  }
  getChildTrie(e) {
    const r = this.mapFor(e, !0);
    let n = r.get(e);
    return n || r.set(e, n = new Un(this.weakness, this.makeData)), n;
  }
  mapFor(e, r) {
    return this.weakness && Ta(e) ? this.weak || (r ? this.weak = /* @__PURE__ */ new WeakMap() : void 0) : this.strong || (r ? this.strong = /* @__PURE__ */ new Map() : void 0);
  }
};
function Ta(t) {
  switch (typeof t) {
    case "object":
      if (t === null)
        break;
    case "function":
      return !0;
  }
  return !1;
}
var ke = typeof WeakMap == "function" && !G(function() {
  return navigator.product == "ReactNative" && !global.HermesInternal;
}), zn = typeof WeakSet == "function", Wn = typeof Symbol == "function" && typeof Symbol.for == "function", ut = Wn && Symbol.asyncIterator;
G(function() {
  return window.document.createElement;
});
G(function() {
  return navigator.userAgent.indexOf("jsdom") >= 0;
});
function L(t) {
  return t !== null && typeof t == "object";
}
function Ia(t, e) {
  var r = e, n = [];
  t.definitions.forEach(function(a) {
    if (a.kind === "OperationDefinition")
      throw U(
        72,
        a.operation,
        a.name ? " named '".concat(a.name.value, "'") : ""
      );
    a.kind === "FragmentDefinition" && n.push(a);
  }), typeof r > "u" && (I(n.length === 1, 73, n.length), r = n[0].name.value);
  var i = d(d({}, t), { definitions: H([
    {
      kind: "OperationDefinition",
      // OperationTypeNode is an enum
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: r
            }
          }
        ]
      }
    }
  ], t.definitions, !0) });
  return i;
}
function ct(t) {
  t === void 0 && (t = []);
  var e = {};
  return t.forEach(function(r) {
    e[r.name.value] = r;
  }), e;
}
function ft(t, e) {
  switch (t.kind) {
    case "InlineFragment":
      return t;
    case "FragmentSpread": {
      var r = t.name.value;
      if (typeof e == "function")
        return e(r);
      var n = e && e[r];
      return I(n, 74, r), n || null;
    }
    default:
      return null;
  }
}
function Da() {
}
class Lt {
  constructor(e = 1 / 0, r = Da) {
    this.max = e, this.dispose = r, this.map = /* @__PURE__ */ new Map(), this.newest = null, this.oldest = null;
  }
  has(e) {
    return this.map.has(e);
  }
  get(e) {
    const r = this.getNode(e);
    return r && r.value;
  }
  get size() {
    return this.map.size;
  }
  getNode(e) {
    const r = this.map.get(e);
    if (r && r !== this.newest) {
      const { older: n, newer: i } = r;
      i && (i.older = n), n && (n.newer = i), r.older = this.newest, r.older.newer = r, r.newer = null, this.newest = r, r === this.oldest && (this.oldest = i);
    }
    return r;
  }
  set(e, r) {
    let n = this.getNode(e);
    return n ? n.value = r : (n = {
      key: e,
      value: r,
      newer: null,
      older: this.newest
    }, this.newest && (this.newest.newer = n), this.newest = n, this.oldest = this.oldest || n, this.map.set(e, n), n.value);
  }
  clean() {
    for (; this.oldest && this.map.size > this.max; )
      this.delete(this.oldest.key);
  }
  delete(e) {
    const r = this.map.get(e);
    return r ? (r === this.newest && (this.newest = r.older), r === this.oldest && (this.oldest = r.newer), r.newer && (r.newer.older = r.older), r.older && (r.older.newer = r.newer), this.map.delete(e), this.dispose(r.value, e), !0) : !1;
  }
}
function xt() {
}
const ka = xt, Ra = typeof WeakRef < "u" ? WeakRef : function(t) {
  return { deref: () => t };
}, Na = typeof WeakMap < "u" ? WeakMap : Map, Aa = typeof FinalizationRegistry < "u" ? FinalizationRegistry : function() {
  return {
    register: xt,
    unregister: xt
  };
}, Fa = 10024;
class it {
  constructor(e = 1 / 0, r = ka) {
    this.max = e, this.dispose = r, this.map = new Na(), this.newest = null, this.oldest = null, this.unfinalizedNodes = /* @__PURE__ */ new Set(), this.finalizationScheduled = !1, this.size = 0, this.finalize = () => {
      const n = this.unfinalizedNodes.values();
      for (let i = 0; i < Fa; i++) {
        const a = n.next().value;
        if (!a)
          break;
        this.unfinalizedNodes.delete(a);
        const o = a.key;
        delete a.key, a.keyRef = new Ra(o), this.registry.register(o, a, a);
      }
      this.unfinalizedNodes.size > 0 ? queueMicrotask(this.finalize) : this.finalizationScheduled = !1;
    }, this.registry = new Aa(this.deleteNode.bind(this));
  }
  has(e) {
    return this.map.has(e);
  }
  get(e) {
    const r = this.getNode(e);
    return r && r.value;
  }
  getNode(e) {
    const r = this.map.get(e);
    if (r && r !== this.newest) {
      const { older: n, newer: i } = r;
      i && (i.older = n), n && (n.newer = i), r.older = this.newest, r.older.newer = r, r.newer = null, this.newest = r, r === this.oldest && (this.oldest = i);
    }
    return r;
  }
  set(e, r) {
    let n = this.getNode(e);
    return n ? n.value = r : (n = {
      key: e,
      value: r,
      newer: null,
      older: this.newest
    }, this.newest && (this.newest.newer = n), this.newest = n, this.oldest = this.oldest || n, this.scheduleFinalization(n), this.map.set(e, n), this.size++, n.value);
  }
  clean() {
    for (; this.oldest && this.size > this.max; )
      this.deleteNode(this.oldest);
  }
  deleteNode(e) {
    e === this.newest && (this.newest = e.older), e === this.oldest && (this.oldest = e.newer), e.newer && (e.newer.older = e.older), e.older && (e.older.newer = e.newer), this.size--;
    const r = e.key || e.keyRef && e.keyRef.deref();
    this.dispose(e.value, r), e.keyRef ? this.registry.unregister(e) : this.unfinalizedNodes.delete(e), r && this.map.delete(r);
  }
  delete(e) {
    const r = this.map.get(e);
    return r ? (this.deleteNode(r), !0) : !1;
  }
  scheduleFinalization(e) {
    this.unfinalizedNodes.add(e), this.finalizationScheduled || (this.finalizationScheduled = !0, queueMicrotask(this.finalize));
  }
}
var Et = /* @__PURE__ */ new WeakSet();
function Bn(t) {
  t.size <= (t.max || -1) || Et.has(t) || (Et.add(t), setTimeout(function() {
    t.clean(), Et.delete(t);
  }, 100));
}
var Gn = function(t, e) {
  var r = new it(t, e);
  return r.set = function(n, i) {
    var a = it.prototype.set.call(this, n, i);
    return Bn(this), a;
  }, r;
}, Pa = function(t, e) {
  var r = new Lt(t, e);
  return r.set = function(n, i) {
    var a = Lt.prototype.set.call(this, n, i);
    return Bn(this), a;
  }, r;
}, Ma = Symbol.for("apollo.cacheSize"), Z = d({}, Mt[Ma]), le = {};
function Hn(t, e) {
  le[t] = e;
}
var Ca = globalThis.__DEV__ !== !1 ? ja : void 0, La = globalThis.__DEV__ !== !1 ? Va : void 0, xa = globalThis.__DEV__ !== !1 ? $n : void 0;
function qa() {
  var t = {
    parser: 1e3,
    canonicalStringify: 1e3,
    print: 2e3,
    "documentTransform.cache": 2e3,
    "queryManager.getDocumentInfo": 2e3,
    "PersistedQueryLink.persistedQueryHashes": 2e3,
    "fragmentRegistry.transform": 2e3,
    "fragmentRegistry.lookup": 1e3,
    "fragmentRegistry.findFragmentSpreads": 4e3,
    "cache.fragmentQueryDocuments": 1e3,
    "removeTypenameFromVariables.getVariableDefinitions": 2e3,
    "inMemoryCache.maybeBroadcastWatch": 5e3,
    "inMemoryCache.executeSelectionSet": 5e4,
    "inMemoryCache.executeSubSelectedArray": 1e4
  };
  return Object.fromEntries(Object.entries(t).map(function(e) {
    var r = e[0], n = e[1];
    return [
      r,
      Z[r] || n
    ];
  }));
}
function ja() {
  var t, e, r, n, i;
  if (globalThis.__DEV__ === !1)
    throw new Error("only supported in development mode");
  return {
    limits: qa(),
    sizes: d({ print: (t = le.print) === null || t === void 0 ? void 0 : t.call(le), parser: (e = le.parser) === null || e === void 0 ? void 0 : e.call(le), canonicalStringify: (r = le.canonicalStringify) === null || r === void 0 ? void 0 : r.call(le), links: jt(this.link), queryManager: {
      getDocumentInfo: this.queryManager.transformCache.size,
      documentTransforms: Jn(this.queryManager.documentTransform)
    } }, (i = (n = this.cache).getMemoryInternals) === null || i === void 0 ? void 0 : i.call(n))
  };
}
function $n() {
  return {
    cache: {
      fragmentQueryDocuments: ne(this.getFragmentDoc)
    }
  };
}
function Va() {
  var t = this.config.fragments;
  return d(d({}, $n.apply(this)), { addTypenameDocumentTransform: Jn(this.addTypenameTransform), inMemoryCache: {
    executeSelectionSet: ne(this.storeReader.executeSelectionSet),
    executeSubSelectedArray: ne(this.storeReader.executeSubSelectedArray),
    maybeBroadcastWatch: ne(this.maybeBroadcastWatch)
  }, fragmentRegistry: {
    findFragmentSpreads: ne(t == null ? void 0 : t.findFragmentSpreads),
    lookup: ne(t == null ? void 0 : t.lookup),
    transform: ne(t == null ? void 0 : t.transform)
  } });
}
function Qa(t) {
  return !!t && "dirtyKey" in t;
}
function ne(t) {
  return Qa(t) ? t.size : void 0;
}
function Yn(t) {
  return t != null;
}
function Jn(t) {
  return qt(t).map(function(e) {
    return { cache: e };
  });
}
function qt(t) {
  return t ? H(H([
    ne(t == null ? void 0 : t.performWork)
  ], qt(t == null ? void 0 : t.left), !0), qt(t == null ? void 0 : t.right), !0).filter(Yn) : [];
}
function jt(t) {
  var e;
  return t ? H(H([
    (e = t == null ? void 0 : t.getMemoryInternals) === null || e === void 0 ? void 0 : e.call(t)
  ], jt(t == null ? void 0 : t.left), !0), jt(t == null ? void 0 : t.right), !0).filter(Yn) : [];
}
var ie = Object.assign(function(e) {
  return JSON.stringify(e, Ua);
}, {
  reset: function() {
    me = new Pa(
      Z.canonicalStringify || 1e3
      /* defaultCacheSizes.canonicalStringify */
    );
  }
});
globalThis.__DEV__ !== !1 && Hn("canonicalStringify", function() {
  return me.size;
});
var me;
ie.reset();
function Ua(t, e) {
  if (e && typeof e == "object") {
    var r = Object.getPrototypeOf(e);
    if (r === Object.prototype || r === null) {
      var n = Object.keys(e);
      if (n.every(za))
        return e;
      var i = JSON.stringify(n), a = me.get(i);
      if (!a) {
        n.sort();
        var o = JSON.stringify(n);
        a = me.get(o) || n, me.set(i, a), me.set(o, a);
      }
      var s = Object.create(r);
      return a.forEach(function(c) {
        s[c] = e[c];
      }), s;
    }
  }
  return e;
}
function za(t, e, r) {
  return e === 0 || r[e - 1] <= t;
}
function Ee(t) {
  return { __ref: String(t) };
}
function F(t) {
  return !!(t && typeof t == "object" && typeof t.__ref == "string");
}
function Wa(t) {
  return L(t) && t.kind === "Document" && Array.isArray(t.definitions);
}
function Ba(t) {
  return t.kind === "StringValue";
}
function Ga(t) {
  return t.kind === "BooleanValue";
}
function Ha(t) {
  return t.kind === "IntValue";
}
function $a(t) {
  return t.kind === "FloatValue";
}
function Ya(t) {
  return t.kind === "Variable";
}
function Ja(t) {
  return t.kind === "ObjectValue";
}
function Xa(t) {
  return t.kind === "ListValue";
}
function Ka(t) {
  return t.kind === "EnumValue";
}
function Za(t) {
  return t.kind === "NullValue";
}
function _e(t, e, r, n) {
  if (Ha(r) || $a(r))
    t[e.value] = Number(r.value);
  else if (Ga(r) || Ba(r))
    t[e.value] = r.value;
  else if (Ja(r)) {
    var i = {};
    r.fields.map(function(o) {
      return _e(i, o.name, o.value, n);
    }), t[e.value] = i;
  } else if (Ya(r)) {
    var a = (n || {})[r.name.value];
    t[e.value] = a;
  } else if (Xa(r))
    t[e.value] = r.values.map(function(o) {
      var s = {};
      return _e(s, e, o, n), s[e.value];
    });
  else if (Ka(r))
    t[e.value] = r.value;
  else if (Za(r))
    t[e.value] = null;
  else
    throw U(83, e.value, r.kind);
}
function eo(t, e) {
  var r = null;
  t.directives && (r = {}, t.directives.forEach(function(i) {
    r[i.name.value] = {}, i.arguments && i.arguments.forEach(function(a) {
      var o = a.name, s = a.value;
      return _e(r[i.name.value], o, s, e);
    });
  }));
  var n = null;
  return t.arguments && t.arguments.length && (n = {}, t.arguments.forEach(function(i) {
    var a = i.name, o = i.value;
    return _e(n, a, o, e);
  })), Xn(t.name.value, n, r);
}
var to = [
  "connection",
  "include",
  "skip",
  "client",
  "rest",
  "export",
  "nonreactive"
], Re = ie, Xn = Object.assign(function(t, e, r) {
  if (e && r && r.connection && r.connection.key)
    if (r.connection.filter && r.connection.filter.length > 0) {
      var n = r.connection.filter ? r.connection.filter : [];
      n.sort();
      var i = {};
      return n.forEach(function(s) {
        i[s] = e[s];
      }), "".concat(r.connection.key, "(").concat(Re(i), ")");
    } else
      return r.connection.key;
  var a = t;
  if (e) {
    var o = Re(e);
    a += "(".concat(o, ")");
  }
  return r && Object.keys(r).forEach(function(s) {
    to.indexOf(s) === -1 && (r[s] && Object.keys(r[s]).length ? a += "@".concat(s, "(").concat(Re(r[s]), ")") : a += "@".concat(s));
  }), a;
}, {
  setStringify: function(t) {
    var e = Re;
    return Re = t, e;
  }
});
function lt(t, e) {
  if (t.arguments && t.arguments.length) {
    var r = {};
    return t.arguments.forEach(function(n) {
      var i = n.name, a = n.value;
      return _e(r, i, a, e);
    }), r;
  }
  return null;
}
function oe(t) {
  return t.alias ? t.alias.value : t.name.value;
}
function Vt(t, e, r) {
  for (var n, i = 0, a = e.selections; i < a.length; i++) {
    var o = a[i];
    if (se(o)) {
      if (o.name.value === "__typename")
        return t[oe(o)];
    } else
      n ? n.push(o) : n = [o];
  }
  if (typeof t.__typename == "string")
    return t.__typename;
  if (n)
    for (var s = 0, c = n; s < c.length; s++) {
      var o = c[s], u = Vt(t, ft(o, r).selectionSet, r);
      if (typeof u == "string")
        return u;
    }
}
function se(t) {
  return t.kind === "Field";
}
function ro(t) {
  return t.kind === "InlineFragment";
}
function Ge(t) {
  I(t && t.kind === "Document", 75);
  var e = t.definitions.filter(function(r) {
    return r.kind !== "FragmentDefinition";
  }).map(function(r) {
    if (r.kind !== "OperationDefinition")
      throw U(76, r.kind);
    return r;
  });
  return I(e.length <= 1, 77, e.length), t;
}
function He(t) {
  return Ge(t), t.definitions.filter(function(e) {
    return e.kind === "OperationDefinition";
  })[0];
}
function Qt(t) {
  return t.definitions.filter(function(e) {
    return e.kind === "OperationDefinition" && !!e.name;
  }).map(function(e) {
    return e.name.value;
  })[0] || null;
}
function ht(t) {
  return t.definitions.filter(function(e) {
    return e.kind === "FragmentDefinition";
  });
}
function Kn(t) {
  var e = He(t);
  return I(e && e.operation === "query", 78), e;
}
function no(t) {
  I(t.kind === "Document", 79), I(t.definitions.length <= 1, 80);
  var e = t.definitions[0];
  return I(e.kind === "FragmentDefinition", 81), e;
}
function $e(t) {
  Ge(t);
  for (var e, r = 0, n = t.definitions; r < n.length; r++) {
    var i = n[r];
    if (i.kind === "OperationDefinition") {
      var a = i.operation;
      if (a === "query" || a === "mutation" || a === "subscription")
        return i;
    }
    i.kind === "FragmentDefinition" && !e && (e = i);
  }
  if (e)
    return e;
  throw U(82);
}
function or(t) {
  var e = /* @__PURE__ */ Object.create(null), r = t && t.variableDefinitions;
  return r && r.length && r.forEach(function(n) {
    n.defaultValue && _e(e, n.variable.name, n.defaultValue);
  }), e;
}
const io = () => /* @__PURE__ */ Object.create(null), { forEach: ao, slice: oo } = Array.prototype, { hasOwnProperty: so } = Object.prototype;
class sr {
  constructor(e = !0, r = io) {
    this.weakness = e, this.makeData = r;
  }
  lookup(...e) {
    return this.lookupArray(e);
  }
  lookupArray(e) {
    let r = this;
    return ao.call(e, (n) => r = r.getChildTrie(n)), so.call(r, "data") ? r.data : r.data = this.makeData(oo.call(e));
  }
  peek(...e) {
    return this.peekArray(e);
  }
  peekArray(e) {
    let r = this;
    for (let n = 0, i = e.length; r && n < i; ++n) {
      const a = this.weakness && Mr(e[n]) ? r.weak : r.strong;
      r = a && a.get(e[n]);
    }
    return r && r.data;
  }
  getChildTrie(e) {
    const r = this.weakness && Mr(e) ? this.weak || (this.weak = /* @__PURE__ */ new WeakMap()) : this.strong || (this.strong = /* @__PURE__ */ new Map());
    let n = r.get(e);
    return n || r.set(e, n = new sr(this.weakness, this.makeData)), n;
  }
}
function Mr(t) {
  switch (typeof t) {
    case "object":
      if (t === null)
        break;
    case "function":
      return !0;
  }
  return !1;
}
let Q = null;
const Cr = {};
let uo = 1;
const co = () => class {
  constructor() {
    this.id = [
      "slot",
      uo++,
      Date.now(),
      Math.random().toString(36).slice(2)
    ].join(":");
  }
  hasValue() {
    for (let e = Q; e; e = e.parent)
      if (this.id in e.slots) {
        const r = e.slots[this.id];
        if (r === Cr)
          break;
        return e !== Q && (Q.slots[this.id] = r), !0;
      }
    return Q && (Q.slots[this.id] = Cr), !1;
  }
  getValue() {
    if (this.hasValue())
      return Q.slots[this.id];
  }
  withValue(e, r, n, i) {
    const a = {
      __proto__: null,
      [this.id]: e
    }, o = Q;
    Q = { parent: o, slots: a };
    try {
      return r.apply(i, n);
    } finally {
      Q = o;
    }
  }
  // Capture the current context and wrap a callback function so that it
  // reestablishes the captured context when called.
  static bind(e) {
    const r = Q;
    return function() {
      const n = Q;
      try {
        return Q = r, e.apply(this, arguments);
      } finally {
        Q = n;
      }
    };
  }
  // Immediately run a callback function without any captured context.
  static noContext(e, r, n) {
    if (Q) {
      const i = Q;
      try {
        return Q = null, e.apply(n, r);
      } finally {
        Q = i;
      }
    } else
      return e.apply(n, r);
  }
};
function Lr(t) {
  try {
    return t();
  } catch {
  }
}
const Ot = "@wry/context:Slot", fo = (
  // Prefer globalThis when available.
  // https://github.com/benjamn/wryware/issues/347
  Lr(() => globalThis) || // Fall back to global, which works in Node.js and may be converted by some
  // bundlers to the appropriate identifier (window, self, ...) depending on the
  // bundling target. https://github.com/endojs/endo/issues/576#issuecomment-1178515224
  Lr(() => global) || // Otherwise, use a dummy host that's local to this module. We used to fall
  // back to using the Array constructor as a namespace, but that was flagged in
  // https://github.com/benjamn/wryware/issues/347, and can be avoided.
  /* @__PURE__ */ Object.create(null)
), xr = fo, Zn = xr[Ot] || // Earlier versions of this package stored the globalKey property on the Array
// constructor, so we check there as well, to prevent Slot class duplication.
Array[Ot] || function(t) {
  try {
    Object.defineProperty(xr, Ot, {
      value: t,
      enumerable: !1,
      writable: !1,
      // When it was possible for globalHost to be the Array constructor (a
      // legacy Slot dedup strategy), it was important for the property to be
      // configurable:true so it could be deleted. That does not seem to be as
      // important when globalHost is the global object, but I don't want to
      // cause similar problems again, and configurable:true seems safest.
      // https://github.com/endojs/endo/issues/576#issuecomment-1178274008
      configurable: !0
    });
  } finally {
    return t;
  }
}(co()), pt = new Zn(), { hasOwnProperty: lo } = Object.prototype, ur = Array.from || function(t) {
  const e = [];
  return t.forEach((r) => e.push(r)), e;
};
function cr(t) {
  const { unsubscribe: e } = t;
  typeof e == "function" && (t.unsubscribe = void 0, e());
}
const Ve = [], ho = 100;
function we(t, e) {
  if (!t)
    throw new Error(e || "assertion failure");
}
function ei(t, e) {
  const r = t.length;
  return (
    // Unknown values are not equal to each other.
    r > 0 && // Both values must be ordinary (or both exceptional) to be equal.
    r === e.length && // The underlying value or exception must be the same.
    t[r - 1] === e[r - 1]
  );
}
function ti(t) {
  switch (t.length) {
    case 0:
      throw new Error("unknown value");
    case 1:
      return t[0];
    case 2:
      throw t[1];
  }
}
function ri(t) {
  return t.slice(0);
}
class dt {
  constructor(e) {
    this.fn = e, this.parents = /* @__PURE__ */ new Set(), this.childValues = /* @__PURE__ */ new Map(), this.dirtyChildren = null, this.dirty = !0, this.recomputing = !1, this.value = [], this.deps = null, ++dt.count;
  }
  peek() {
    if (this.value.length === 1 && !ue(this))
      return qr(this), this.value[0];
  }
  // This is the most important method of the Entry API, because it
  // determines whether the cached this.value can be returned immediately,
  // or must be recomputed. The overall performance of the caching system
  // depends on the truth of the following observations: (1) this.dirty is
  // usually false, (2) this.dirtyChildren is usually null/empty, and thus
  // (3) valueGet(this.value) is usually returned without recomputation.
  recompute(e) {
    return we(!this.recomputing, "already recomputing"), qr(this), ue(this) ? po(this, e) : ti(this.value);
  }
  setDirty() {
    this.dirty || (this.dirty = !0, ni(this), cr(this));
  }
  dispose() {
    this.setDirty(), ui(this), fr(this, (e, r) => {
      e.setDirty(), ci(e, this);
    });
  }
  forget() {
    this.dispose();
  }
  dependOn(e) {
    e.add(this), this.deps || (this.deps = Ve.pop() || /* @__PURE__ */ new Set()), this.deps.add(e);
  }
  forgetDeps() {
    this.deps && (ur(this.deps).forEach((e) => e.delete(this)), this.deps.clear(), Ve.push(this.deps), this.deps = null);
  }
}
dt.count = 0;
function qr(t) {
  const e = pt.getValue();
  if (e)
    return t.parents.add(e), e.childValues.has(t) || e.childValues.set(t, []), ue(t) ? ai(e, t) : oi(e, t), e;
}
function po(t, e) {
  return ui(t), pt.withValue(t, vo, [t, e]), mo(t, e) && yo(t), ti(t.value);
}
function vo(t, e) {
  t.recomputing = !0;
  const { normalizeResult: r } = t;
  let n;
  r && t.value.length === 1 && (n = ri(t.value)), t.value.length = 0;
  try {
    if (t.value[0] = t.fn.apply(null, e), r && n && !ei(n, t.value))
      try {
        t.value[0] = r(t.value[0], n[0]);
      } catch {
      }
  } catch (i) {
    t.value[1] = i;
  }
  t.recomputing = !1;
}
function ue(t) {
  return t.dirty || !!(t.dirtyChildren && t.dirtyChildren.size);
}
function yo(t) {
  t.dirty = !1, !ue(t) && ii(t);
}
function ni(t) {
  fr(t, ai);
}
function ii(t) {
  fr(t, oi);
}
function fr(t, e) {
  const r = t.parents.size;
  if (r) {
    const n = ur(t.parents);
    for (let i = 0; i < r; ++i)
      e(n[i], t);
  }
}
function ai(t, e) {
  we(t.childValues.has(e)), we(ue(e));
  const r = !ue(t);
  if (!t.dirtyChildren)
    t.dirtyChildren = Ve.pop() || /* @__PURE__ */ new Set();
  else if (t.dirtyChildren.has(e))
    return;
  t.dirtyChildren.add(e), r && ni(t);
}
function oi(t, e) {
  we(t.childValues.has(e)), we(!ue(e));
  const r = t.childValues.get(e);
  r.length === 0 ? t.childValues.set(e, ri(e.value)) : ei(r, e.value) || t.setDirty(), si(t, e), !ue(t) && ii(t);
}
function si(t, e) {
  const r = t.dirtyChildren;
  r && (r.delete(e), r.size === 0 && (Ve.length < ho && Ve.push(r), t.dirtyChildren = null));
}
function ui(t) {
  t.childValues.size > 0 && t.childValues.forEach((e, r) => {
    ci(t, r);
  }), t.forgetDeps(), we(t.dirtyChildren === null);
}
function ci(t, e) {
  e.parents.delete(t), t.childValues.delete(e), si(t, e);
}
function mo(t, e) {
  if (typeof t.subscribe == "function")
    try {
      cr(t), t.unsubscribe = t.subscribe.apply(null, e);
    } catch {
      return t.setDirty(), !1;
    }
  return !0;
}
const go = {
  setDirty: !0,
  dispose: !0,
  forget: !0
  // Fully remove parent Entry from LRU cache and computation graph
};
function fi(t) {
  const e = /* @__PURE__ */ new Map();
  function r(n) {
    const i = pt.getValue();
    if (i) {
      let a = e.get(n);
      a || e.set(n, a = /* @__PURE__ */ new Set()), i.dependOn(a);
    }
  }
  return r.dirty = function(i, a) {
    const o = e.get(i);
    if (o) {
      const s = a && lo.call(go, a) ? a : "setDirty";
      ur(o).forEach((c) => c[s]()), e.delete(i), cr(o);
    }
  }, r;
}
let jr;
function bo(...t) {
  return (jr || (jr = new sr(typeof WeakMap == "function"))).lookupArray(t);
}
const St = /* @__PURE__ */ new Set();
function Qe(t, { max: e = Math.pow(2, 16), keyArgs: r, makeCacheKey: n = bo, normalizeResult: i, subscribe: a, cache: o = Lt } = /* @__PURE__ */ Object.create(null)) {
  const s = typeof o == "function" ? new o(e, (l) => l.dispose()) : o, c = function() {
    const l = n.apply(null, r ? r.apply(null, arguments) : arguments);
    if (l === void 0)
      return t.apply(null, arguments);
    let p = s.get(l);
    p || (s.set(l, p = new dt(t)), p.normalizeResult = i, p.subscribe = a, p.forget = () => s.delete(l));
    const y = p.recompute(Array.prototype.slice.call(arguments));
    return s.set(l, p), St.add(s), pt.hasValue() || (St.forEach((m) => m.clean()), St.clear()), y;
  };
  Object.defineProperty(c, "size", {
    get: () => s.size,
    configurable: !1,
    enumerable: !1
  }), Object.freeze(c.options = {
    max: e,
    keyArgs: r,
    makeCacheKey: n,
    normalizeResult: i,
    subscribe: a,
    cache: s
  });
  function u(l) {
    const p = l && s.get(l);
    p && p.setDirty();
  }
  c.dirtyKey = u, c.dirty = function() {
    u(n.apply(null, arguments));
  };
  function f(l) {
    const p = l && s.get(l);
    if (p)
      return p.peek();
  }
  c.peekKey = f, c.peek = function() {
    return f(n.apply(null, arguments));
  };
  function h(l) {
    return l ? s.delete(l) : !1;
  }
  return c.forgetKey = h, c.forget = function() {
    return h(n.apply(null, arguments));
  }, c.makeCacheKey = n, c.getKey = r ? function() {
    return n.apply(null, r.apply(null, arguments));
  } : n, Object.freeze(c);
}
function Eo(t) {
  return t;
}
var li = (
  /** @class */
  function() {
    function t(e, r) {
      r === void 0 && (r = /* @__PURE__ */ Object.create(null)), this.resultCache = zn ? /* @__PURE__ */ new WeakSet() : /* @__PURE__ */ new Set(), this.transform = e, r.getCacheKey && (this.getCacheKey = r.getCacheKey), this.cached = r.cache !== !1, this.resetCache();
    }
    return t.prototype.getCacheKey = function(e) {
      return [e];
    }, t.identity = function() {
      return new t(Eo, { cache: !1 });
    }, t.split = function(e, r, n) {
      return n === void 0 && (n = t.identity()), Object.assign(new t(
        function(i) {
          var a = e(i) ? r : n;
          return a.transformDocument(i);
        },
        // Reasonably assume both `left` and `right` transforms handle their own caching
        { cache: !1 }
      ), { left: r, right: n });
    }, t.prototype.resetCache = function() {
      var e = this;
      if (this.cached) {
        var r = new De(ke);
        this.performWork = Qe(t.prototype.performWork.bind(this), {
          makeCacheKey: function(n) {
            var i = e.getCacheKey(n);
            if (i)
              return I(Array.isArray(i), 67), r.lookupArray(i);
          },
          max: Z["documentTransform.cache"],
          cache: it
        });
      }
    }, t.prototype.performWork = function(e) {
      return Ge(e), this.transform(e);
    }, t.prototype.transformDocument = function(e) {
      if (this.resultCache.has(e))
        return e;
      var r = this.performWork(e);
      return this.resultCache.add(r), r;
    }, t.prototype.concat = function(e) {
      var r = this;
      return Object.assign(new t(
        function(n) {
          return e.transformDocument(r.transformDocument(n));
        },
        // Reasonably assume both transforms handle their own caching
        { cache: !1 }
      ), {
        left: this,
        right: e
      });
    }, t;
  }()
), Me, vt = Object.assign(function(t) {
  var e = Me.get(t);
  return e || (e = ya(t), Me.set(t, e)), e;
}, {
  reset: function() {
    Me = new Gn(
      Z.print || 2e3
      /* defaultCacheSizes.print */
    );
  }
});
vt.reset();
globalThis.__DEV__ !== !1 && Hn("print", function() {
  return Me ? Me.size : 0;
});
var x = Array.isArray;
function X(t) {
  return Array.isArray(t) && t.length > 0;
}
var Vr = {
  kind: V.FIELD,
  name: {
    kind: V.NAME,
    value: "__typename"
  }
};
function hi(t, e) {
  return !t || t.selectionSet.selections.every(function(r) {
    return r.kind === V.FRAGMENT_SPREAD && hi(e[r.name.value], e);
  });
}
function Oo(t) {
  return hi(He(t) || no(t), ct(ht(t))) ? null : t;
}
function So(t) {
  var e = /* @__PURE__ */ new Map(), r = /* @__PURE__ */ new Map();
  return t.forEach(function(n) {
    n && (n.name ? e.set(n.name, n) : n.test && r.set(n.test, n));
  }), function(n) {
    var i = e.get(n.name.value);
    return !i && r.size && r.forEach(function(a, o) {
      o(n) && (i = a);
    }), i;
  };
}
function Qr(t) {
  var e = /* @__PURE__ */ new Map();
  return function(n) {
    n === void 0 && (n = t);
    var i = e.get(n);
    return i || e.set(n, i = {
      // Variable and fragment spread names used directly within this
      // operation or fragment definition, as identified by key. These sets
      // will be populated during the first traversal of the document in
      // removeDirectivesFromDocument below.
      variables: /* @__PURE__ */ new Set(),
      fragmentSpreads: /* @__PURE__ */ new Set()
    }), i;
  };
}
function pi(t, e) {
  Ge(e);
  for (var r = Qr(""), n = Qr(""), i = function(v) {
    for (var E = 0, g = void 0; E < v.length && (g = v[E]); ++E)
      if (!x(g)) {
        if (g.kind === V.OPERATION_DEFINITION)
          return r(g.name && g.name.value);
        if (g.kind === V.FRAGMENT_DEFINITION)
          return n(g.name.value);
      }
    return globalThis.__DEV__ !== !1 && I.error(84), null;
  }, a = 0, o = e.definitions.length - 1; o >= 0; --o)
    e.definitions[o].kind === V.OPERATION_DEFINITION && ++a;
  var s = So(t), c = function(v) {
    return X(v) && v.map(s).some(function(E) {
      return E && E.remove;
    });
  }, u = /* @__PURE__ */ new Map(), f = !1, h = {
    enter: function(v) {
      if (c(v.directives))
        return f = !0, null;
    }
  }, l = K(e, {
    // These two AST node types share the same implementation, defined above.
    Field: h,
    InlineFragment: h,
    VariableDefinition: {
      enter: function() {
        return !1;
      }
    },
    Variable: {
      enter: function(v, E, g, S, O) {
        var _ = i(O);
        _ && _.variables.add(v.name.value);
      }
    },
    FragmentSpread: {
      enter: function(v, E, g, S, O) {
        if (c(v.directives))
          return f = !0, null;
        var _ = i(O);
        _ && _.fragmentSpreads.add(v.name.value);
      }
    },
    FragmentDefinition: {
      enter: function(v, E, g, S) {
        u.set(JSON.stringify(S), v);
      },
      leave: function(v, E, g, S) {
        var O = u.get(JSON.stringify(S));
        if (v === O)
          return v;
        if (
          // This logic applies only if the document contains one or more
          // operations, since removing all fragments from a document containing
          // only fragments makes the document useless.
          a > 0 && v.selectionSet.selections.every(function(_) {
            return _.kind === V.FIELD && _.name.value === "__typename";
          })
        )
          return n(v.name.value).removed = !0, f = !0, null;
      }
    },
    Directive: {
      leave: function(v) {
        if (s(v))
          return f = !0, null;
      }
    }
  });
  if (!f)
    return e;
  var p = function(v) {
    return v.transitiveVars || (v.transitiveVars = new Set(v.variables), v.removed || v.fragmentSpreads.forEach(function(E) {
      p(n(E)).transitiveVars.forEach(function(g) {
        v.transitiveVars.add(g);
      });
    })), v;
  }, y = /* @__PURE__ */ new Set();
  l.definitions.forEach(function(v) {
    v.kind === V.OPERATION_DEFINITION ? p(r(v.name && v.name.value)).fragmentSpreads.forEach(function(E) {
      y.add(E);
    }) : v.kind === V.FRAGMENT_DEFINITION && // If there are no operations in the document, then all fragment
    // definitions count as usages of their own fragment names. This heuristic
    // prevents accidentally removing all fragment definitions from the
    // document just because it contains no operations that use the fragments.
    a === 0 && !n(v.name.value).removed && y.add(v.name.value);
  }), y.forEach(function(v) {
    p(n(v)).fragmentSpreads.forEach(function(E) {
      y.add(E);
    });
  });
  var m = function(v) {
    return !!// A fragment definition will be removed if there are no spreads that refer
    // to it, or the fragment was explicitly removed because it had no fields
    // other than __typename.
    (!y.has(v) || n(v).removed);
  }, b = {
    enter: function(v) {
      if (m(v.name.value))
        return null;
    }
  };
  return Oo(K(l, {
    // If the fragment is going to be removed, then leaving any dangling
    // FragmentSpread nodes with the same name would be a mistake.
    FragmentSpread: b,
    // This is where the fragment definition is actually removed.
    FragmentDefinition: b,
    OperationDefinition: {
      leave: function(v) {
        if (v.variableDefinitions) {
          var E = p(
            // If an operation is anonymous, we use the empty string as its key.
            r(v.name && v.name.value)
          ).transitiveVars;
          if (E.size < v.variableDefinitions.length)
            return d(d({}, v), { variableDefinitions: v.variableDefinitions.filter(function(g) {
              return E.has(g.variable.name.value);
            }) });
        }
      }
    }
  }));
}
var lr = Object.assign(function(t) {
  return K(t, {
    SelectionSet: {
      enter: function(e, r, n) {
        if (!(n && n.kind === V.OPERATION_DEFINITION)) {
          var i = e.selections;
          if (i) {
            var a = i.some(function(s) {
              return se(s) && (s.name.value === "__typename" || s.name.value.lastIndexOf("__", 0) === 0);
            });
            if (!a) {
              var o = n;
              if (!(se(o) && o.directives && o.directives.some(function(s) {
                return s.name.value === "export";
              })))
                return d(d({}, e), { selections: H(H([], i, !0), [Vr], !1) });
            }
          }
        }
      }
    }
  });
}, {
  added: function(t) {
    return t === Vr;
  }
});
function _o(t) {
  var e = $e(t), r = e.operation;
  if (r === "query")
    return t;
  var n = K(t, {
    OperationDefinition: {
      enter: function(i) {
        return d(d({}, i), { operation: "query" });
      }
    }
  });
  return n;
}
function di(t) {
  Ge(t);
  var e = pi([
    {
      test: function(r) {
        return r.name.value === "client";
      },
      remove: !0
    }
  ], t);
  return e;
}
var wo = Object.prototype.hasOwnProperty;
function Ur() {
  for (var t = [], e = 0; e < arguments.length; e++)
    t[e] = arguments[e];
  return yt(t);
}
function yt(t) {
  var e = t[0] || {}, r = t.length;
  if (r > 1)
    for (var n = new ce(), i = 1; i < r; ++i)
      e = n.merge(e, t[i]);
  return e;
}
var To = function(t, e, r) {
  return this.merge(t[r], e[r]);
}, ce = (
  /** @class */
  function() {
    function t(e) {
      e === void 0 && (e = To), this.reconciler = e, this.isObject = L, this.pastCopies = /* @__PURE__ */ new Set();
    }
    return t.prototype.merge = function(e, r) {
      for (var n = this, i = [], a = 2; a < arguments.length; a++)
        i[a - 2] = arguments[a];
      return L(r) && L(e) ? (Object.keys(r).forEach(function(o) {
        if (wo.call(e, o)) {
          var s = e[o];
          if (r[o] !== s) {
            var c = n.reconciler.apply(n, H([
              e,
              r,
              o
            ], i, !1));
            c !== s && (e = n.shallowCopyForMerge(e), e[o] = c);
          }
        } else
          e = n.shallowCopyForMerge(e), e[o] = r[o];
      }), e) : r;
    }, t.prototype.shallowCopyForMerge = function(e) {
      return L(e) && (this.pastCopies.has(e) || (Array.isArray(e) ? e = e.slice(0) : e = d({ __proto__: Object.getPrototypeOf(e) }, e), this.pastCopies.add(e))), e;
    }, t;
  }()
);
function Io(t, e) {
  var r = typeof Symbol < "u" && t[Symbol.iterator] || t["@@iterator"];
  if (r)
    return (r = r.call(t)).next.bind(r);
  if (Array.isArray(t) || (r = Do(t)) || e) {
    r && (t = r);
    var n = 0;
    return function() {
      return n >= t.length ? { done: !0 } : { done: !1, value: t[n++] };
    };
  }
  throw new TypeError(`Invalid attempt to iterate non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
function Do(t, e) {
  if (t) {
    if (typeof t == "string")
      return zr(t, e);
    var r = Object.prototype.toString.call(t).slice(8, -1);
    if (r === "Object" && t.constructor && (r = t.constructor.name), r === "Map" || r === "Set")
      return Array.from(t);
    if (r === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r))
      return zr(t, e);
  }
}
function zr(t, e) {
  (e == null || e > t.length) && (e = t.length);
  for (var r = 0, n = new Array(e); r < e; r++)
    n[r] = t[r];
  return n;
}
function Wr(t, e) {
  for (var r = 0; r < e.length; r++) {
    var n = e[r];
    n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(t, n.key, n);
  }
}
function hr(t, e, r) {
  return e && Wr(t.prototype, e), r && Wr(t, r), Object.defineProperty(t, "prototype", { writable: !1 }), t;
}
var pr = function() {
  return typeof Symbol == "function";
}, dr = function(t) {
  return pr() && !!Symbol[t];
}, vr = function(t) {
  return dr(t) ? Symbol[t] : "@@" + t;
};
pr() && !dr("observable") && (Symbol.observable = Symbol("observable"));
var ko = vr("iterator"), Ut = vr("observable"), vi = vr("species");
function at(t, e) {
  var r = t[e];
  if (r != null) {
    if (typeof r != "function")
      throw new TypeError(r + " is not a function");
    return r;
  }
}
function Ne(t) {
  var e = t.constructor;
  return e !== void 0 && (e = e[vi], e === null && (e = void 0)), e !== void 0 ? e : A;
}
function Ro(t) {
  return t instanceof A;
}
function Te(t) {
  Te.log ? Te.log(t) : setTimeout(function() {
    throw t;
  });
}
function et(t) {
  Promise.resolve().then(function() {
    try {
      t();
    } catch (e) {
      Te(e);
    }
  });
}
function yi(t) {
  var e = t._cleanup;
  if (e !== void 0 && (t._cleanup = void 0, !!e))
    try {
      if (typeof e == "function")
        e();
      else {
        var r = at(e, "unsubscribe");
        r && r.call(e);
      }
    } catch (n) {
      Te(n);
    }
}
function zt(t) {
  t._observer = void 0, t._queue = void 0, t._state = "closed";
}
function No(t) {
  var e = t._queue;
  if (e) {
    t._queue = void 0, t._state = "ready";
    for (var r = 0; r < e.length && (mi(t, e[r].type, e[r].value), t._state !== "closed"); ++r)
      ;
  }
}
function mi(t, e, r) {
  t._state = "running";
  var n = t._observer;
  try {
    var i = at(n, e);
    switch (e) {
      case "next":
        i && i.call(n, r);
        break;
      case "error":
        if (zt(t), i)
          i.call(n, r);
        else
          throw r;
        break;
      case "complete":
        zt(t), i && i.call(n);
        break;
    }
  } catch (a) {
    Te(a);
  }
  t._state === "closed" ? yi(t) : t._state === "running" && (t._state = "ready");
}
function _t(t, e, r) {
  if (t._state !== "closed") {
    if (t._state === "buffering") {
      t._queue.push({
        type: e,
        value: r
      });
      return;
    }
    if (t._state !== "ready") {
      t._state = "buffering", t._queue = [{
        type: e,
        value: r
      }], et(function() {
        return No(t);
      });
      return;
    }
    mi(t, e, r);
  }
}
var Ao = /* @__PURE__ */ function() {
  function t(r, n) {
    this._cleanup = void 0, this._observer = r, this._queue = void 0, this._state = "initializing";
    var i = new Fo(this);
    try {
      this._cleanup = n.call(void 0, i);
    } catch (a) {
      i.error(a);
    }
    this._state === "initializing" && (this._state = "ready");
  }
  var e = t.prototype;
  return e.unsubscribe = function() {
    this._state !== "closed" && (zt(this), yi(this));
  }, hr(t, [{
    key: "closed",
    get: function() {
      return this._state === "closed";
    }
  }]), t;
}(), Fo = /* @__PURE__ */ function() {
  function t(r) {
    this._subscription = r;
  }
  var e = t.prototype;
  return e.next = function(n) {
    _t(this._subscription, "next", n);
  }, e.error = function(n) {
    _t(this._subscription, "error", n);
  }, e.complete = function() {
    _t(this._subscription, "complete");
  }, hr(t, [{
    key: "closed",
    get: function() {
      return this._subscription._state === "closed";
    }
  }]), t;
}(), A = /* @__PURE__ */ function() {
  function t(r) {
    if (!(this instanceof t))
      throw new TypeError("Observable cannot be called as a function");
    if (typeof r != "function")
      throw new TypeError("Observable initializer must be a function");
    this._subscriber = r;
  }
  var e = t.prototype;
  return e.subscribe = function(n) {
    return (typeof n != "object" || n === null) && (n = {
      next: n,
      error: arguments[1],
      complete: arguments[2]
    }), new Ao(n, this._subscriber);
  }, e.forEach = function(n) {
    var i = this;
    return new Promise(function(a, o) {
      if (typeof n != "function") {
        o(new TypeError(n + " is not a function"));
        return;
      }
      function s() {
        c.unsubscribe(), a();
      }
      var c = i.subscribe({
        next: function(u) {
          try {
            n(u, s);
          } catch (f) {
            o(f), c.unsubscribe();
          }
        },
        error: o,
        complete: a
      });
    });
  }, e.map = function(n) {
    var i = this;
    if (typeof n != "function")
      throw new TypeError(n + " is not a function");
    var a = Ne(this);
    return new a(function(o) {
      return i.subscribe({
        next: function(s) {
          try {
            s = n(s);
          } catch (c) {
            return o.error(c);
          }
          o.next(s);
        },
        error: function(s) {
          o.error(s);
        },
        complete: function() {
          o.complete();
        }
      });
    });
  }, e.filter = function(n) {
    var i = this;
    if (typeof n != "function")
      throw new TypeError(n + " is not a function");
    var a = Ne(this);
    return new a(function(o) {
      return i.subscribe({
        next: function(s) {
          try {
            if (!n(s))
              return;
          } catch (c) {
            return o.error(c);
          }
          o.next(s);
        },
        error: function(s) {
          o.error(s);
        },
        complete: function() {
          o.complete();
        }
      });
    });
  }, e.reduce = function(n) {
    var i = this;
    if (typeof n != "function")
      throw new TypeError(n + " is not a function");
    var a = Ne(this), o = arguments.length > 1, s = !1, c = arguments[1], u = c;
    return new a(function(f) {
      return i.subscribe({
        next: function(h) {
          var l = !s;
          if (s = !0, !l || o)
            try {
              u = n(u, h);
            } catch (p) {
              return f.error(p);
            }
          else
            u = h;
        },
        error: function(h) {
          f.error(h);
        },
        complete: function() {
          if (!s && !o)
            return f.error(new TypeError("Cannot reduce an empty sequence"));
          f.next(u), f.complete();
        }
      });
    });
  }, e.concat = function() {
    for (var n = this, i = arguments.length, a = new Array(i), o = 0; o < i; o++)
      a[o] = arguments[o];
    var s = Ne(this);
    return new s(function(c) {
      var u, f = 0;
      function h(l) {
        u = l.subscribe({
          next: function(p) {
            c.next(p);
          },
          error: function(p) {
            c.error(p);
          },
          complete: function() {
            f === a.length ? (u = void 0, c.complete()) : h(s.from(a[f++]));
          }
        });
      }
      return h(n), function() {
        u && (u.unsubscribe(), u = void 0);
      };
    });
  }, e.flatMap = function(n) {
    var i = this;
    if (typeof n != "function")
      throw new TypeError(n + " is not a function");
    var a = Ne(this);
    return new a(function(o) {
      var s = [], c = i.subscribe({
        next: function(f) {
          if (n)
            try {
              f = n(f);
            } catch (l) {
              return o.error(l);
            }
          var h = a.from(f).subscribe({
            next: function(l) {
              o.next(l);
            },
            error: function(l) {
              o.error(l);
            },
            complete: function() {
              var l = s.indexOf(h);
              l >= 0 && s.splice(l, 1), u();
            }
          });
          s.push(h);
        },
        error: function(f) {
          o.error(f);
        },
        complete: function() {
          u();
        }
      });
      function u() {
        c.closed && s.length === 0 && o.complete();
      }
      return function() {
        s.forEach(function(f) {
          return f.unsubscribe();
        }), c.unsubscribe();
      };
    });
  }, e[Ut] = function() {
    return this;
  }, t.from = function(n) {
    var i = typeof this == "function" ? this : t;
    if (n == null)
      throw new TypeError(n + " is not an object");
    var a = at(n, Ut);
    if (a) {
      var o = a.call(n);
      if (Object(o) !== o)
        throw new TypeError(o + " is not an object");
      return Ro(o) && o.constructor === i ? o : new i(function(s) {
        return o.subscribe(s);
      });
    }
    if (dr("iterator") && (a = at(n, ko), a))
      return new i(function(s) {
        et(function() {
          if (!s.closed) {
            for (var c = Io(a.call(n)), u; !(u = c()).done; ) {
              var f = u.value;
              if (s.next(f), s.closed)
                return;
            }
            s.complete();
          }
        });
      });
    if (Array.isArray(n))
      return new i(function(s) {
        et(function() {
          if (!s.closed) {
            for (var c = 0; c < n.length; ++c)
              if (s.next(n[c]), s.closed)
                return;
            s.complete();
          }
        });
      });
    throw new TypeError(n + " is not observable");
  }, t.of = function() {
    for (var n = arguments.length, i = new Array(n), a = 0; a < n; a++)
      i[a] = arguments[a];
    var o = typeof this == "function" ? this : t;
    return new o(function(s) {
      et(function() {
        if (!s.closed) {
          for (var c = 0; c < i.length; ++c)
            if (s.next(i[c]), s.closed)
              return;
          s.complete();
        }
      });
    });
  }, hr(t, null, [{
    key: vi,
    get: function() {
      return this;
    }
  }]), t;
}();
pr() && Object.defineProperty(A, Symbol("extensions"), {
  value: {
    symbol: Ut,
    hostReportError: Te
  },
  configurable: !0
});
function Po(t) {
  var e, r = t.Symbol;
  if (typeof r == "function")
    if (r.observable)
      e = r.observable;
    else {
      typeof r.for == "function" ? e = r.for("https://github.com/benlesh/symbol-observable") : e = r("https://github.com/benlesh/symbol-observable");
      try {
        r.observable = e;
      } catch {
      }
    }
  else
    e = "@@observable";
  return e;
}
var ve;
typeof self < "u" ? ve = self : typeof window < "u" ? ve = window : typeof global < "u" ? ve = global : typeof module < "u" ? ve = module : ve = Function("return this")();
Po(ve);
var Br = A.prototype, Gr = "@@observable";
Br[Gr] || (Br[Gr] = function() {
  return this;
});
var Mo = Object.prototype.toString;
function gi(t) {
  return Wt(t);
}
function Wt(t, e) {
  switch (Mo.call(t)) {
    case "[object Array]": {
      if (e = e || /* @__PURE__ */ new Map(), e.has(t))
        return e.get(t);
      var r = t.slice(0);
      return e.set(t, r), r.forEach(function(i, a) {
        r[a] = Wt(i, e);
      }), r;
    }
    case "[object Object]": {
      if (e = e || /* @__PURE__ */ new Map(), e.has(t))
        return e.get(t);
      var n = Object.create(Object.getPrototypeOf(t));
      return e.set(t, n), Object.keys(t).forEach(function(i) {
        n[i] = Wt(t[i], e);
      }), n;
    }
    default:
      return t;
  }
}
function Co(t) {
  var e = /* @__PURE__ */ new Set([t]);
  return e.forEach(function(r) {
    L(r) && Lo(r) === r && Object.getOwnPropertyNames(r).forEach(function(n) {
      L(r[n]) && e.add(r[n]);
    });
  }), t;
}
function Lo(t) {
  if (globalThis.__DEV__ !== !1 && !Object.isFrozen(t))
    try {
      Object.freeze(t);
    } catch (e) {
      if (e instanceof TypeError)
        return null;
      throw e;
    }
  return t;
}
function Bt(t) {
  return globalThis.__DEV__ !== !1 && Co(t), t;
}
function Ce(t, e, r) {
  var n = [];
  t.forEach(function(i) {
    return i[e] && n.push(i);
  }), n.forEach(function(i) {
    return i[e](r);
  });
}
function wt(t, e, r) {
  return new A(function(n) {
    var i = {
      // Normally we would initialize promiseQueue to Promise.resolve(), but
      // in this case, for backwards compatibility, we need to be careful to
      // invoke the first callback synchronously.
      then: function(c) {
        return new Promise(function(u) {
          return u(c());
        });
      }
    };
    function a(c, u) {
      return function(f) {
        if (c) {
          var h = function() {
            return n.closed ? (
              /* will be swallowed */
              0
            ) : c(f);
          };
          i = i.then(h, h).then(function(l) {
            return n.next(l);
          }, function(l) {
            return n.error(l);
          });
        } else
          n[u](f);
      };
    }
    var o = {
      next: a(e, "next"),
      error: a(r, "error"),
      complete: function() {
        i.then(function() {
          return n.complete();
        });
      }
    }, s = t.subscribe(o);
    return function() {
      return s.unsubscribe();
    };
  });
}
function bi(t) {
  function e(r) {
    Object.defineProperty(t, r, { value: A });
  }
  return Wn && Symbol.species && e(Symbol.species), e("@@species"), t;
}
function Hr(t) {
  return t && typeof t.then == "function";
}
var ye = (
  /** @class */
  function(t) {
    W(e, t);
    function e(r) {
      var n = t.call(this, function(i) {
        return n.addObserver(i), function() {
          return n.removeObserver(i);
        };
      }) || this;
      return n.observers = /* @__PURE__ */ new Set(), n.promise = new Promise(function(i, a) {
        n.resolve = i, n.reject = a;
      }), n.handlers = {
        next: function(i) {
          n.sub !== null && (n.latest = ["next", i], n.notify("next", i), Ce(n.observers, "next", i));
        },
        error: function(i) {
          var a = n.sub;
          a !== null && (a && setTimeout(function() {
            return a.unsubscribe();
          }), n.sub = null, n.latest = ["error", i], n.reject(i), n.notify("error", i), Ce(n.observers, "error", i));
        },
        complete: function() {
          var i = n, a = i.sub, o = i.sources, s = o === void 0 ? [] : o;
          if (a !== null) {
            var c = s.shift();
            c ? Hr(c) ? c.then(function(u) {
              return n.sub = u.subscribe(n.handlers);
            }, n.handlers.error) : n.sub = c.subscribe(n.handlers) : (a && setTimeout(function() {
              return a.unsubscribe();
            }), n.sub = null, n.latest && n.latest[0] === "next" ? n.resolve(n.latest[1]) : n.resolve(), n.notify("complete"), Ce(n.observers, "complete"));
          }
        }
      }, n.nextResultListeners = /* @__PURE__ */ new Set(), n.cancel = function(i) {
        n.reject(i), n.sources = [], n.handlers.complete();
      }, n.promise.catch(function(i) {
      }), typeof r == "function" && (r = [new A(r)]), Hr(r) ? r.then(function(i) {
        return n.start(i);
      }, n.handlers.error) : n.start(r), n;
    }
    return e.prototype.start = function(r) {
      this.sub === void 0 && (this.sources = Array.from(r), this.handlers.complete());
    }, e.prototype.deliverLastMessage = function(r) {
      if (this.latest) {
        var n = this.latest[0], i = r[n];
        i && i.call(r, this.latest[1]), this.sub === null && n === "next" && r.complete && r.complete();
      }
    }, e.prototype.addObserver = function(r) {
      this.observers.has(r) || (this.deliverLastMessage(r), this.observers.add(r));
    }, e.prototype.removeObserver = function(r) {
      this.observers.delete(r) && this.observers.size < 1 && this.handlers.complete();
    }, e.prototype.notify = function(r, n) {
      var i = this.nextResultListeners;
      i.size && (this.nextResultListeners = /* @__PURE__ */ new Set(), i.forEach(function(a) {
        return a(r, n);
      }));
    }, e.prototype.beforeNext = function(r) {
      var n = !1;
      this.nextResultListeners.add(function(i, a) {
        n || (n = !0, r(i, a));
      });
    }, e;
  }(A)
);
bi(ye);
function Oe(t) {
  return "incremental" in t;
}
function xo(t) {
  return "hasNext" in t && "data" in t;
}
function qo(t) {
  return Oe(t) || xo(t);
}
function jo(t) {
  return L(t) && "payload" in t;
}
function Ei(t, e) {
  var r = t, n = new ce();
  return Oe(e) && X(e.incremental) && e.incremental.forEach(function(i) {
    for (var a = i.data, o = i.path, s = o.length - 1; s >= 0; --s) {
      var c = o[s], u = !isNaN(+c), f = u ? [] : {};
      f[c] = a, a = f;
    }
    r = n.merge(r, a);
  }), r;
}
function tt(t) {
  var e = Gt(t);
  return X(e);
}
function Gt(t) {
  var e = X(t.errors) ? t.errors.slice(0) : [];
  return Oe(t) && X(t.incremental) && t.incremental.forEach(function(r) {
    r.errors && e.push.apply(e, r.errors);
  }), e;
}
function Ie() {
  for (var t = [], e = 0; e < arguments.length; e++)
    t[e] = arguments[e];
  var r = /* @__PURE__ */ Object.create(null);
  return t.forEach(function(n) {
    n && Object.keys(n).forEach(function(i) {
      var a = n[i];
      a !== void 0 && (r[i] = a);
    });
  }), r;
}
function Tt(t, e) {
  return Ie(t, e, e.variables && {
    variables: Ie(d(d({}, t && t.variables), e.variables))
  });
}
function It(t) {
  return new A(function(e) {
    e.error(t);
  });
}
function Vo(t) {
  return new A(function(e) {
    t.then(function(r) {
      e.next(r), e.complete();
    }).catch(e.error.bind(e));
  });
}
var Oi = function(t, e, r) {
  var n = new Error(r);
  throw n.name = "ServerError", n.response = t, n.statusCode = t.status, n.result = e, n;
};
function Qo(t) {
  for (var e = [
    "query",
    "operationName",
    "variables",
    "extensions",
    "context"
  ], r = 0, n = Object.keys(t); r < n.length; r++) {
    var i = n[r];
    if (e.indexOf(i) < 0)
      throw U(43, i);
  }
  return t;
}
function Uo(t, e) {
  var r = d({}, t), n = function(a) {
    typeof a == "function" ? r = d(d({}, r), a(r)) : r = d(d({}, r), a);
  }, i = function() {
    return d({}, r);
  };
  return Object.defineProperty(e, "setContext", {
    enumerable: !1,
    value: n
  }), Object.defineProperty(e, "getContext", {
    enumerable: !1,
    value: i
  }), e;
}
function zo(t) {
  var e = {
    variables: t.variables || {},
    extensions: t.extensions || {},
    operationName: t.operationName,
    query: t.query
  };
  return e.operationName || (e.operationName = typeof e.query != "string" ? Qt(e.query) || void 0 : ""), e;
}
function Wo(t, e) {
  var r = d({}, t), n = new Set(Object.keys(t));
  return K(e, {
    Variable: function(i, a, o) {
      o && o.kind !== "VariableDefinition" && n.delete(i.name.value);
    }
  }), n.forEach(function(i) {
    delete r[i];
  }), r;
}
function $r(t, e) {
  return e ? e(t) : A.of();
}
function Ae(t) {
  return typeof t == "function" ? new $(t) : t;
}
function Ke(t) {
  return t.request.length <= 1;
}
var $ = (
  /** @class */
  function() {
    function t(e) {
      e && (this.request = e);
    }
    return t.empty = function() {
      return new t(function() {
        return A.of();
      });
    }, t.from = function(e) {
      return e.length === 0 ? t.empty() : e.map(Ae).reduce(function(r, n) {
        return r.concat(n);
      });
    }, t.split = function(e, r, n) {
      var i = Ae(r), a = Ae(n || new t($r)), o;
      return Ke(i) && Ke(a) ? o = new t(function(s) {
        return e(s) ? i.request(s) || A.of() : a.request(s) || A.of();
      }) : o = new t(function(s, c) {
        return e(s) ? i.request(s, c) || A.of() : a.request(s, c) || A.of();
      }), Object.assign(o, { left: i, right: a });
    }, t.execute = function(e, r) {
      return e.request(Uo(r.context, zo(Qo(r)))) || A.of();
    }, t.concat = function(e, r) {
      var n = Ae(e);
      if (Ke(n))
        return globalThis.__DEV__ !== !1 && I.warn(35, n), n;
      var i = Ae(r), a;
      return Ke(i) ? a = new t(function(o) {
        return n.request(o, function(s) {
          return i.request(s) || A.of();
        }) || A.of();
      }) : a = new t(function(o, s) {
        return n.request(o, function(c) {
          return i.request(c, s) || A.of();
        }) || A.of();
      }), Object.assign(a, { left: n, right: i });
    }, t.prototype.split = function(e, r, n) {
      return this.concat(t.split(e, r, n || new t($r)));
    }, t.prototype.concat = function(e) {
      return t.concat(this, e);
    }, t.prototype.request = function(e, r) {
      throw U(36);
    }, t.prototype.onError = function(e, r) {
      if (r && r.error)
        return r.error(e), !1;
      throw e;
    }, t.prototype.setOnError = function(e) {
      return this.onError = e, this;
    }, t;
  }()
), Ht = $.execute;
function Bo(t) {
  var e, r = t[Symbol.asyncIterator]();
  return e = {
    next: function() {
      return r.next();
    }
  }, e[Symbol.asyncIterator] = function() {
    return this;
  }, e;
}
function Go(t) {
  var e = null, r = null, n = !1, i = [], a = [];
  function o(h) {
    if (!r) {
      if (a.length) {
        var l = a.shift();
        if (Array.isArray(l) && l[0])
          return l[0]({ value: h, done: !1 });
      }
      i.push(h);
    }
  }
  function s(h) {
    r = h;
    var l = a.slice();
    l.forEach(function(p) {
      p[1](h);
    }), !e || e();
  }
  function c() {
    n = !0;
    var h = a.slice();
    h.forEach(function(l) {
      l[0]({ value: void 0, done: !0 });
    }), !e || e();
  }
  e = function() {
    e = null, t.removeListener("data", o), t.removeListener("error", s), t.removeListener("end", c), t.removeListener("finish", c), t.removeListener("close", c);
  }, t.on("data", o), t.on("error", s), t.on("end", c), t.on("finish", c), t.on("close", c);
  function u() {
    return new Promise(function(h, l) {
      if (r)
        return l(r);
      if (i.length)
        return h({ value: i.shift(), done: !1 });
      if (n)
        return h({ value: void 0, done: !0 });
      a.push([h, l]);
    });
  }
  var f = {
    next: function() {
      return u();
    }
  };
  return ut && (f[Symbol.asyncIterator] = function() {
    return this;
  }), f;
}
function Ho(t) {
  var e = !1, r = {
    next: function() {
      return e ? Promise.resolve({
        value: void 0,
        done: !0
      }) : (e = !0, new Promise(function(n, i) {
        t.then(function(a) {
          n({ value: a, done: !1 });
        }).catch(i);
      }));
    }
  };
  return ut && (r[Symbol.asyncIterator] = function() {
    return this;
  }), r;
}
function Yr(t) {
  var e = {
    next: function() {
      return t.read();
    }
  };
  return ut && (e[Symbol.asyncIterator] = function() {
    return this;
  }), e;
}
function $o(t) {
  return !!t.body;
}
function Yo(t) {
  return !!t.getReader;
}
function Jo(t) {
  return !!(ut && t[Symbol.asyncIterator]);
}
function Xo(t) {
  return !!t.stream;
}
function Ko(t) {
  return !!t.arrayBuffer;
}
function Zo(t) {
  return !!t.pipe;
}
function es(t) {
  var e = t;
  if ($o(t) && (e = t.body), Jo(e))
    return Bo(e);
  if (Yo(e))
    return Yr(e.getReader());
  if (Xo(e))
    return Yr(e.stream().getReader());
  if (Ko(e))
    return Ho(e.arrayBuffer());
  if (Zo(e))
    return Go(e);
  throw new Error("Unknown body type for responseIterator. Please pass a streamable response.");
}
var yr = Symbol();
function ts(t) {
  return t.extensions ? Array.isArray(t.extensions[yr]) : !1;
}
function rs(t) {
  return t.hasOwnProperty("graphQLErrors");
}
var ns = function(t) {
  var e = H(H(H([], t.graphQLErrors, !0), t.clientErrors, !0), t.protocolErrors, !0);
  return t.networkError && e.push(t.networkError), e.map(function(r) {
    return L(r) && r.message || "Error message not found.";
  }).join(`
`);
}, de = (
  /** @class */
  function(t) {
    W(e, t);
    function e(r) {
      var n = r.graphQLErrors, i = r.protocolErrors, a = r.clientErrors, o = r.networkError, s = r.errorMessage, c = r.extraInfo, u = t.call(this, s) || this;
      return u.name = "ApolloError", u.graphQLErrors = n || [], u.protocolErrors = i || [], u.clientErrors = a || [], u.networkError = o || null, u.message = s || ns(u), u.extraInfo = c, u.__proto__ = e.prototype, u;
    }
    return e;
  }(Error)
), Jr = Object.prototype.hasOwnProperty;
function is(t, e) {
  return te(this, void 0, void 0, function() {
    var r, n, i, a, o, s, c, u, f, h, l, p, y, m, b, v, E, g, S, O, _, T, D, k;
    return re(this, function(N) {
      switch (N.label) {
        case 0:
          if (TextDecoder === void 0)
            throw new Error("TextDecoder must be defined in the environment: please import a polyfill.");
          r = new TextDecoder("utf-8"), n = (k = t.headers) === null || k === void 0 ? void 0 : k.get("content-type"), i = "boundary=", a = n != null && n.includes(i) ? n == null ? void 0 : n.substring((n == null ? void 0 : n.indexOf(i)) + i.length).replace(/['"]/g, "").replace(/\;(.*)/gm, "").trim() : "-", o = `\r
--`.concat(a), s = "", c = es(t), u = !0, N.label = 1;
        case 1:
          return u ? [4, c.next()] : [3, 3];
        case 2:
          for (f = N.sent(), h = f.value, l = f.done, p = typeof h == "string" ? h : r.decode(h), y = s.length - o.length + 1, u = !l, s += p, m = s.indexOf(o, y); m > -1; ) {
            if (b = void 0, T = [
              s.slice(0, m),
              s.slice(m + o.length)
            ], b = T[0], s = T[1], v = b.indexOf(`\r
\r
`), E = as(b.slice(0, v)), g = E["content-type"], g && g.toLowerCase().indexOf("application/json") === -1)
              throw new Error("Unsupported patch content type: application/json is required.");
            if (S = b.slice(v), S) {
              if (O = Si(t, S), Object.keys(O).length > 1 || "data" in O || "incremental" in O || "errors" in O || "payload" in O)
                if (jo(O)) {
                  if (_ = {}, "payload" in O) {
                    if (Object.keys(O).length === 1 && O.payload === null)
                      return [
                        2
                        /*return*/
                      ];
                    _ = d({}, O.payload);
                  }
                  "errors" in O && (_ = d(d({}, _), { extensions: d(d({}, "extensions" in _ ? _.extensions : null), (D = {}, D[yr] = O.errors, D)) })), e(_);
                } else
                  e(O);
              else if (
                // If the chunk contains only a "hasNext: false", we can call
                // observer.complete() immediately.
                Object.keys(O).length === 1 && "hasNext" in O && !O.hasNext
              )
                return [
                  2
                  /*return*/
                ];
            }
            m = s.indexOf(o);
          }
          return [3, 1];
        case 3:
          return [
            2
            /*return*/
          ];
      }
    });
  });
}
function as(t) {
  var e = {};
  return t.split(`
`).forEach(function(r) {
    var n = r.indexOf(":");
    if (n > -1) {
      var i = r.slice(0, n).trim().toLowerCase(), a = r.slice(n + 1).trim();
      e[i] = a;
    }
  }), e;
}
function Si(t, e) {
  if (t.status >= 300) {
    var r = function() {
      try {
        return JSON.parse(e);
      } catch {
        return e;
      }
    };
    Oi(t, r(), "Response not successful: Received status code ".concat(t.status));
  }
  try {
    return JSON.parse(e);
  } catch (i) {
    var n = i;
    throw n.name = "ServerParseError", n.response = t, n.statusCode = t.status, n.bodyText = e, n;
  }
}
function os(t, e) {
  t.result && t.result.errors && t.result.data && e.next(t.result), e.error(t);
}
function _i(t) {
  return function(e) {
    return e.text().then(function(r) {
      return Si(e, r);
    }).then(function(r) {
      return !Array.isArray(r) && !Jr.call(r, "data") && !Jr.call(r, "errors") && Oi(e, r, "Server response was missing for query '".concat(Array.isArray(t) ? t.map(function(n) {
        return n.operationName;
      }) : t.operationName, "'.")), r;
    });
  };
}
var Ue = function(t, e) {
  var r;
  try {
    r = JSON.stringify(t);
  } catch (i) {
    var n = U(39, e, i.message);
    throw n.parseError = i, n;
  }
  return r;
}, ss = {
  includeQuery: !0,
  includeExtensions: !1,
  preserveHeaderCase: !1
}, us = {
  // headers are case insensitive (https://stackoverflow.com/a/5259004)
  accept: "*/*",
  // The content-type header describes the type of the body of the request, and
  // so it typically only is sent with requests that actually have bodies. One
  // could imagine that Apollo Client would remove this header when constructing
  // a GET request (which has no body), but we historically have not done that.
  // This means that browsers will preflight all Apollo Client requests (even
  // GET requests). Apollo Server's CSRF prevention feature (introduced in
  // AS3.7) takes advantage of this fact and does not block requests with this
  // header. If you want to drop this header from GET requests, then you should
  // probably replace it with a `apollo-require-preflight` header, or servers
  // with CSRF prevention enabled might block your GET request. See
  // https://www.apollographql.com/docs/apollo-server/security/cors/#preventing-cross-site-request-forgery-csrf
  // for more details.
  "content-type": "application/json"
}, cs = {
  method: "POST"
}, wi = {
  http: ss,
  headers: us,
  options: cs
}, Ti = function(t, e) {
  return e(t);
};
function Ii(t, e) {
  for (var r = [], n = 2; n < arguments.length; n++)
    r[n - 2] = arguments[n];
  var i = {}, a = {};
  r.forEach(function(h) {
    i = d(d(d({}, i), h.options), { headers: d(d({}, i.headers), h.headers) }), h.credentials && (i.credentials = h.credentials), a = d(d({}, a), h.http);
  }), i.headers && (i.headers = fs(i.headers, a.preserveHeaderCase));
  var o = t.operationName, s = t.extensions, c = t.variables, u = t.query, f = { operationName: o, variables: c };
  return a.includeExtensions && (f.extensions = s), a.includeQuery && (f.query = e(u, vt)), {
    options: i,
    body: f
  };
}
function fs(t, e) {
  if (!e) {
    var r = /* @__PURE__ */ Object.create(null);
    return Object.keys(Object(t)).forEach(function(a) {
      r[a.toLowerCase()] = t[a];
    }), r;
  }
  var n = /* @__PURE__ */ Object.create(null);
  Object.keys(Object(t)).forEach(function(a) {
    n[a.toLowerCase()] = {
      originalName: a,
      value: t[a]
    };
  });
  var i = /* @__PURE__ */ Object.create(null);
  return Object.keys(n).forEach(function(a) {
    i[n[a].originalName] = n[a].value;
  }), i;
}
var ls = function(t) {
  if (!t && typeof fetch > "u")
    throw U(37);
}, hs = function() {
  if (typeof AbortController > "u")
    return { controller: !1, signal: !1 };
  var t = new AbortController(), e = t.signal;
  return { controller: t, signal: e };
}, Di = function(t, e) {
  var r = t.getContext(), n = r.uri;
  return n || (typeof e == "function" ? e(t) : e || "/graphql");
};
function ki(t, e) {
  var r = [], n = function(h, l) {
    r.push("".concat(h, "=").concat(encodeURIComponent(l)));
  };
  if ("query" in e && n("query", e.query), e.operationName && n("operationName", e.operationName), e.variables) {
    var i = void 0;
    try {
      i = Ue(e.variables, "Variables map");
    } catch (h) {
      return { parseError: h };
    }
    n("variables", i);
  }
  if (e.extensions) {
    var a = void 0;
    try {
      a = Ue(e.extensions, "Extensions map");
    } catch (h) {
      return { parseError: h };
    }
    n("extensions", a);
  }
  var o = "", s = t, c = t.indexOf("#");
  c !== -1 && (o = t.substr(c), s = t.substr(0, c));
  var u = s.indexOf("?") === -1 ? "?" : "&", f = s + u + r.join("&") + o;
  return { newURI: f };
}
var Xr = G(function() {
  return fetch;
}), ps = function(t) {
  t === void 0 && (t = {});
  var e = t.uri, r = e === void 0 ? "/graphql" : e, n = t.fetch, i = t.print, a = i === void 0 ? Ti : i, o = t.includeExtensions, s = t.preserveHeaderCase, c = t.useGETForQueries, u = t.includeUnusedVariables, f = u === void 0 ? !1 : u, h = ae(t, ["uri", "fetch", "print", "includeExtensions", "preserveHeaderCase", "useGETForQueries", "includeUnusedVariables"]);
  globalThis.__DEV__ !== !1 && ls(n || Xr);
  var l = {
    http: { includeExtensions: o, preserveHeaderCase: s },
    options: h.fetchOptions,
    credentials: h.credentials,
    headers: h.headers
  };
  return new $(function(p) {
    var y = Di(p, r), m = p.getContext(), b = {};
    if (m.clientAwareness) {
      var v = m.clientAwareness, E = v.name, g = v.version;
      E && (b["apollographql-client-name"] = E), g && (b["apollographql-client-version"] = g);
    }
    var S = d(d({}, b), m.headers), O = {
      http: m.http,
      options: m.fetchOptions,
      credentials: m.credentials,
      headers: S
    };
    if (je(["client"], p.query)) {
      var _ = di(p.query);
      if (!_)
        return It(new Error("HttpLink: Trying to send a client-only query to the server. To send to the server, ensure a non-client field is added to the query or set the `transformOptions.removeClientFields` option to `true`."));
      p.query = _;
    }
    var T = Ii(p, a, wi, l, O), D = T.options, k = T.body;
    k.variables && !f && (k.variables = Wo(k.variables, p.query));
    var N;
    !D.signal && typeof AbortController < "u" && (N = new AbortController(), D.signal = N.signal);
    var C = function(z) {
      return z.kind === "OperationDefinition" && z.operation === "mutation";
    }, Y = function(z) {
      return z.kind === "OperationDefinition" && z.operation === "subscription";
    }, q = Y($e(p.query)), J = je(["defer"], p.query);
    if (c && !p.query.definitions.some(C) && (D.method = "GET"), J || q) {
      D.headers = D.headers || {};
      var mt = "multipart/mixed;";
      q && J && globalThis.__DEV__ !== !1 && I.warn(38), q ? mt += "boundary=graphql;subscriptionSpec=1.0,application/json" : J && (mt += "deferSpec=20220824,application/json"), D.headers.accept = mt;
    }
    if (D.method === "GET") {
      var Or = ki(y, k), Yi = Or.newURI, Sr = Or.parseError;
      if (Sr)
        return It(Sr);
      y = Yi;
    } else
      try {
        D.body = Ue(k, "Payload");
      } catch (z) {
        return It(z);
      }
    return new A(function(z) {
      var Ji = n || G(function() {
        return fetch;
      }) || Xr, _r = z.next.bind(z);
      return Ji(y, D).then(function(pe) {
        var gt;
        p.setContext({ response: pe });
        var wr = (gt = pe.headers) === null || gt === void 0 ? void 0 : gt.get("content-type");
        return wr !== null && /^multipart\/mixed/i.test(wr) ? is(pe, _r) : _i(p)(pe).then(_r);
      }).then(function() {
        N = void 0, z.complete();
      }).catch(function(pe) {
        N = void 0, os(pe, z);
      }), function() {
        N && N.abort();
      };
    });
  });
}, ds = (
  /** @class */
  function(t) {
    W(e, t);
    function e(r) {
      r === void 0 && (r = {});
      var n = t.call(this, ps(r).request) || this;
      return n.options = r, n;
    }
    return e;
  }($)
);
const { toString: Kr, hasOwnProperty: vs } = Object.prototype, Zr = Function.prototype.toString, $t = /* @__PURE__ */ new Map();
function M(t, e) {
  try {
    return Yt(t, e);
  } finally {
    $t.clear();
  }
}
function Yt(t, e) {
  if (t === e)
    return !0;
  const r = Kr.call(t), n = Kr.call(e);
  if (r !== n)
    return !1;
  switch (r) {
    case "[object Array]":
      if (t.length !== e.length)
        return !1;
    case "[object Object]": {
      if (tn(t, e))
        return !0;
      const i = en(t), a = en(e), o = i.length;
      if (o !== a.length)
        return !1;
      for (let s = 0; s < o; ++s)
        if (!vs.call(e, i[s]))
          return !1;
      for (let s = 0; s < o; ++s) {
        const c = i[s];
        if (!Yt(t[c], e[c]))
          return !1;
      }
      return !0;
    }
    case "[object Error]":
      return t.name === e.name && t.message === e.message;
    case "[object Number]":
      if (t !== t)
        return e !== e;
    case "[object Boolean]":
    case "[object Date]":
      return +t == +e;
    case "[object RegExp]":
    case "[object String]":
      return t == `${e}`;
    case "[object Map]":
    case "[object Set]": {
      if (t.size !== e.size)
        return !1;
      if (tn(t, e))
        return !0;
      const i = t.entries(), a = r === "[object Map]";
      for (; ; ) {
        const o = i.next();
        if (o.done)
          break;
        const [s, c] = o.value;
        if (!e.has(s) || a && !Yt(c, e.get(s)))
          return !1;
      }
      return !0;
    }
    case "[object Uint16Array]":
    case "[object Uint8Array]":
    case "[object Uint32Array]":
    case "[object Int32Array]":
    case "[object Int8Array]":
    case "[object Int16Array]":
    case "[object ArrayBuffer]":
      t = new Uint8Array(t), e = new Uint8Array(e);
    case "[object DataView]": {
      let i = t.byteLength;
      if (i === e.byteLength)
        for (; i-- && t[i] === e[i]; )
          ;
      return i === -1;
    }
    case "[object AsyncFunction]":
    case "[object GeneratorFunction]":
    case "[object AsyncGeneratorFunction]":
    case "[object Function]": {
      const i = Zr.call(t);
      return i !== Zr.call(e) ? !1 : !gs(i, ms);
    }
  }
  return !1;
}
function en(t) {
  return Object.keys(t).filter(ys, t);
}
function ys(t) {
  return this[t] !== void 0;
}
const ms = "{ [native code] }";
function gs(t, e) {
  const r = t.length - e.length;
  return r >= 0 && t.indexOf(e, r) === r;
}
function tn(t, e) {
  let r = $t.get(t);
  if (r) {
    if (r.has(e))
      return !0;
  } else
    $t.set(t, r = /* @__PURE__ */ new Set());
  return r.add(e), !1;
}
function Ri(t, e, r, n) {
  var i = e.data, a = ae(e, ["data"]), o = r.data, s = ae(r, ["data"]);
  return M(a, s) && rt($e(t).selectionSet, i, o, {
    fragmentMap: ct(ht(t)),
    variables: n
  });
}
function rt(t, e, r, n) {
  if (e === r)
    return !0;
  var i = /* @__PURE__ */ new Set();
  return t.selections.every(function(a) {
    if (i.has(a) || (i.add(a), !Be(a, n.variables)) || rn(a))
      return !0;
    if (se(a)) {
      var o = oe(a), s = e && e[o], c = r && r[o], u = a.selectionSet;
      if (!u)
        return M(s, c);
      var f = Array.isArray(s), h = Array.isArray(c);
      if (f !== h)
        return !1;
      if (f && h) {
        var l = s.length;
        if (c.length !== l)
          return !1;
        for (var p = 0; p < l; ++p)
          if (!rt(u, s[p], c[p], n))
            return !1;
        return !0;
      }
      return rt(u, s, c, n);
    } else {
      var y = ft(a, n.fragmentMap);
      if (y)
        return rn(y) ? !0 : rt(
          y.selectionSet,
          // Notice that we reuse the same aResult and bResult values here,
          // since the fragment ...spread does not specify a field name, but
          // consists of multiple fields (within the fragment's selection set)
          // that should be applied to the current result value(s).
          e,
          r,
          n
        );
    }
  });
}
function rn(t) {
  return !!t.directives && t.directives.some(bs);
}
function bs(t) {
  return t.name.value === "nonreactive";
}
var Ni = (
  /** @class */
  function() {
    function t() {
      this.assumeImmutableResults = !1, this.getFragmentDoc = Qe(Ia, {
        max: Z["cache.fragmentQueryDocuments"] || 1e3,
        cache: it
      });
    }
    return t.prototype.batch = function(e) {
      var r = this, n = typeof e.optimistic == "string" ? e.optimistic : e.optimistic === !1 ? null : void 0, i;
      return this.performTransaction(function() {
        return i = e.update(r);
      }, n), i;
    }, t.prototype.recordOptimisticTransaction = function(e, r) {
      this.performTransaction(e, r);
    }, t.prototype.transformDocument = function(e) {
      return e;
    }, t.prototype.transformForLink = function(e) {
      return e;
    }, t.prototype.identify = function(e) {
    }, t.prototype.gc = function() {
      return [];
    }, t.prototype.modify = function(e) {
      return !1;
    }, t.prototype.readQuery = function(e, r) {
      return r === void 0 && (r = !!e.optimistic), this.read(d(d({}, e), { rootId: e.id || "ROOT_QUERY", optimistic: r }));
    }, t.prototype.watchFragment = function(e) {
      var r = this, n = e.fragment, i = e.fragmentName, a = e.from, o = e.optimistic, s = o === void 0 ? !0 : o, c = this.getFragmentDoc(n, i), u = {
        returnPartialData: !0,
        id: typeof a == "string" ? a : this.identify(a),
        query: c,
        optimistic: s
      }, f;
      return new A(function(h) {
        return r.watch(d(d({}, u), { immediate: !0, callback: function(l) {
          if (
            // Always ensure we deliver the first result
            !(f && Ri(c, { data: f == null ? void 0 : f.result }, { data: l.result }))
          ) {
            var p = {
              data: l.result,
              complete: !!l.complete
            };
            l.missing && (p.missing = yt(l.missing.map(function(y) {
              return y.missing;
            }))), f = l, h.next(p);
          }
        } }));
      });
    }, t.prototype.readFragment = function(e, r) {
      return r === void 0 && (r = !!e.optimistic), this.read(d(d({}, e), { query: this.getFragmentDoc(e.fragment, e.fragmentName), rootId: e.id, optimistic: r }));
    }, t.prototype.writeQuery = function(e) {
      var r = e.id, n = e.data, i = ae(e, ["id", "data"]);
      return this.write(Object.assign(i, {
        dataId: r || "ROOT_QUERY",
        result: n
      }));
    }, t.prototype.writeFragment = function(e) {
      var r = e.id, n = e.data, i = e.fragment, a = e.fragmentName, o = ae(e, ["id", "data", "fragment", "fragmentName"]);
      return this.write(Object.assign(o, {
        query: this.getFragmentDoc(i, a),
        dataId: r,
        result: n
      }));
    }, t.prototype.updateQuery = function(e, r) {
      return this.batch({
        update: function(n) {
          var i = n.readQuery(e), a = r(i);
          return a == null ? i : (n.writeQuery(d(d({}, e), { data: a })), a);
        }
      });
    }, t.prototype.updateFragment = function(e, r) {
      return this.batch({
        update: function(n) {
          var i = n.readFragment(e), a = r(i);
          return a == null ? i : (n.writeFragment(d(d({}, e), { data: a })), a);
        }
      });
    }, t;
  }()
);
globalThis.__DEV__ !== !1 && (Ni.prototype.getMemoryInternals = xa);
var Ai = (
  /** @class */
  function(t) {
    W(e, t);
    function e(r, n, i, a) {
      var o, s = t.call(this, r) || this;
      if (s.message = r, s.path = n, s.query = i, s.variables = a, Array.isArray(s.path)) {
        s.missing = s.message;
        for (var c = s.path.length - 1; c >= 0; --c)
          s.missing = (o = {}, o[s.path[c]] = s.missing, o);
      } else
        s.missing = s.path;
      return s.__proto__ = e.prototype, s;
    }
    return e;
  }(Error)
), j = Object.prototype.hasOwnProperty;
function Fe(t) {
  return t == null;
}
function Fi(t, e) {
  var r = t.__typename, n = t.id, i = t._id;
  if (typeof r == "string" && (e && (e.keyObject = Fe(n) ? Fe(i) ? void 0 : { _id: i } : { id: n }), Fe(n) && !Fe(i) && (n = i), !Fe(n)))
    return "".concat(r, ":").concat(typeof n == "number" || typeof n == "string" ? n : JSON.stringify(n));
}
var Pi = {
  dataIdFromObject: Fi,
  addTypename: !0,
  resultCaching: !0,
  // Thanks to the shouldCanonizeResults helper, this should be the only line
  // you have to change to reenable canonization by default in the future.
  canonizeResults: !1
};
function Es(t) {
  return Ie(Pi, t);
}
function Mi(t) {
  var e = t.canonizeResults;
  return e === void 0 ? Pi.canonizeResults : e;
}
function Os(t, e) {
  return F(e) ? t.get(e.__ref, "__typename") : e && e.__typename;
}
var Ci = /^[_a-z][_0-9a-z]*/i;
function fe(t) {
  var e = t.match(Ci);
  return e ? e[0] : t;
}
function Jt(t, e, r) {
  return L(e) ? x(e) ? e.every(function(n) {
    return Jt(t, n, r);
  }) : t.selections.every(function(n) {
    if (se(n) && Be(n, r)) {
      var i = oe(n);
      return j.call(e, i) && (!n.selectionSet || Jt(n.selectionSet, e[i], r));
    }
    return !0;
  }) : !1;
}
function ge(t) {
  return L(t) && !F(t) && !x(t);
}
function Ss() {
  return new ce();
}
function Li(t, e) {
  var r = ct(ht(t));
  return {
    fragmentMap: r,
    lookupFragment: function(n) {
      var i = r[n];
      return !i && e && (i = e.lookup(n)), i || null;
    }
  };
}
var nt = /* @__PURE__ */ Object.create(null), Dt = function() {
  return nt;
}, nn = /* @__PURE__ */ Object.create(null), ze = (
  /** @class */
  function() {
    function t(e, r) {
      var n = this;
      this.policies = e, this.group = r, this.data = /* @__PURE__ */ Object.create(null), this.rootIds = /* @__PURE__ */ Object.create(null), this.refs = /* @__PURE__ */ Object.create(null), this.getFieldValue = function(i, a) {
        return Bt(F(i) ? n.get(i.__ref, a) : i && i[a]);
      }, this.canRead = function(i) {
        return F(i) ? n.has(i.__ref) : typeof i == "object";
      }, this.toReference = function(i, a) {
        if (typeof i == "string")
          return Ee(i);
        if (F(i))
          return i;
        var o = n.policies.identify(i)[0];
        if (o) {
          var s = Ee(o);
          return a && n.merge(o, i), s;
        }
      };
    }
    return t.prototype.toObject = function() {
      return d({}, this.data);
    }, t.prototype.has = function(e) {
      return this.lookup(e, !0) !== void 0;
    }, t.prototype.get = function(e, r) {
      if (this.group.depend(e, r), j.call(this.data, e)) {
        var n = this.data[e];
        if (n && j.call(n, r))
          return n[r];
      }
      if (r === "__typename" && j.call(this.policies.rootTypenamesById, e))
        return this.policies.rootTypenamesById[e];
      if (this instanceof ee)
        return this.parent.get(e, r);
    }, t.prototype.lookup = function(e, r) {
      if (r && this.group.depend(e, "__exists"), j.call(this.data, e))
        return this.data[e];
      if (this instanceof ee)
        return this.parent.lookup(e, r);
      if (this.policies.rootTypenamesById[e])
        return /* @__PURE__ */ Object.create(null);
    }, t.prototype.merge = function(e, r) {
      var n = this, i;
      F(e) && (e = e.__ref), F(r) && (r = r.__ref);
      var a = typeof e == "string" ? this.lookup(i = e) : e, o = typeof r == "string" ? this.lookup(i = r) : r;
      if (o) {
        I(typeof i == "string", 1);
        var s = new ce(ws).merge(a, o);
        if (this.data[i] = s, s !== a && (delete this.refs[i], this.group.caching)) {
          var c = /* @__PURE__ */ Object.create(null);
          a || (c.__exists = 1), Object.keys(o).forEach(function(u) {
            if (!a || a[u] !== s[u]) {
              c[u] = 1;
              var f = fe(u);
              f !== u && !n.policies.hasKeyArgs(s.__typename, f) && (c[f] = 1), s[u] === void 0 && !(n instanceof ee) && delete s[u];
            }
          }), c.__typename && !(a && a.__typename) && // Since we return default root __typename strings
          // automatically from store.get, we don't need to dirty the
          // ROOT_QUERY.__typename field if merged.__typename is equal
          // to the default string (usually "Query").
          this.policies.rootTypenamesById[i] === s.__typename && delete c.__typename, Object.keys(c).forEach(function(u) {
            return n.group.dirty(i, u);
          });
        }
      }
    }, t.prototype.modify = function(e, r) {
      var n = this, i = this.lookup(e);
      if (i) {
        var a = /* @__PURE__ */ Object.create(null), o = !1, s = !0, c = {
          DELETE: nt,
          INVALIDATE: nn,
          isReference: F,
          toReference: this.toReference,
          canRead: this.canRead,
          readField: function(u, f) {
            return n.policies.readField(typeof u == "string" ? {
              fieldName: u,
              from: f || Ee(e)
            } : u, { store: n });
          }
        };
        if (Object.keys(i).forEach(function(u) {
          var f = fe(u), h = i[u];
          if (h !== void 0) {
            var l = typeof r == "function" ? r : r[u] || r[f];
            if (l) {
              var p = l === Dt ? nt : l(Bt(h), d(d({}, c), { fieldName: f, storeFieldName: u, storage: n.getStorage(e, u) }));
              if (p === nn)
                n.group.dirty(e, u);
              else if (p === nt && (p = void 0), p !== h && (a[u] = p, o = !0, h = p, globalThis.__DEV__ !== !1)) {
                var y = function(O) {
                  if (n.lookup(O.__ref) === void 0)
                    return globalThis.__DEV__ !== !1 && I.warn(2, O), !0;
                };
                if (F(p))
                  y(p);
                else if (Array.isArray(p))
                  for (var m = !1, b = void 0, v = 0, E = p; v < E.length; v++) {
                    var g = E[v];
                    if (F(g)) {
                      if (m = !0, y(g))
                        break;
                    } else if (typeof g == "object" && g) {
                      var S = n.policies.identify(g)[0];
                      S && (b = g);
                    }
                    if (m && b !== void 0) {
                      globalThis.__DEV__ !== !1 && I.warn(3, b);
                      break;
                    }
                  }
              }
            }
            h !== void 0 && (s = !1);
          }
        }), o)
          return this.merge(e, a), s && (this instanceof ee ? this.data[e] = void 0 : delete this.data[e], this.group.dirty(e, "__exists")), !0;
      }
      return !1;
    }, t.prototype.delete = function(e, r, n) {
      var i, a = this.lookup(e);
      if (a) {
        var o = this.getFieldValue(a, "__typename"), s = r && n ? this.policies.getStoreFieldName({ typename: o, fieldName: r, args: n }) : r;
        return this.modify(e, s ? (i = {}, i[s] = Dt, i) : Dt);
      }
      return !1;
    }, t.prototype.evict = function(e, r) {
      var n = !1;
      return e.id && (j.call(this.data, e.id) && (n = this.delete(e.id, e.fieldName, e.args)), this instanceof ee && this !== r && (n = this.parent.evict(e, r) || n), (e.fieldName || n) && this.group.dirty(e.id, e.fieldName || "__exists")), n;
    }, t.prototype.clear = function() {
      this.replace(null);
    }, t.prototype.extract = function() {
      var e = this, r = this.toObject(), n = [];
      return this.getRootIdSet().forEach(function(i) {
        j.call(e.policies.rootTypenamesById, i) || n.push(i);
      }), n.length && (r.__META = { extraRootIds: n.sort() }), r;
    }, t.prototype.replace = function(e) {
      var r = this;
      if (Object.keys(this.data).forEach(function(a) {
        e && j.call(e, a) || r.delete(a);
      }), e) {
        var n = e.__META, i = ae(e, ["__META"]);
        Object.keys(i).forEach(function(a) {
          r.merge(a, i[a]);
        }), n && n.extraRootIds.forEach(this.retain, this);
      }
    }, t.prototype.retain = function(e) {
      return this.rootIds[e] = (this.rootIds[e] || 0) + 1;
    }, t.prototype.release = function(e) {
      if (this.rootIds[e] > 0) {
        var r = --this.rootIds[e];
        return r || delete this.rootIds[e], r;
      }
      return 0;
    }, t.prototype.getRootIdSet = function(e) {
      return e === void 0 && (e = /* @__PURE__ */ new Set()), Object.keys(this.rootIds).forEach(e.add, e), this instanceof ee ? this.parent.getRootIdSet(e) : Object.keys(this.policies.rootTypenamesById).forEach(e.add, e), e;
    }, t.prototype.gc = function() {
      var e = this, r = this.getRootIdSet(), n = this.toObject();
      r.forEach(function(o) {
        j.call(n, o) && (Object.keys(e.findChildRefIds(o)).forEach(r.add, r), delete n[o]);
      });
      var i = Object.keys(n);
      if (i.length) {
        for (var a = this; a instanceof ee; )
          a = a.parent;
        i.forEach(function(o) {
          return a.delete(o);
        });
      }
      return i;
    }, t.prototype.findChildRefIds = function(e) {
      if (!j.call(this.refs, e)) {
        var r = this.refs[e] = /* @__PURE__ */ Object.create(null), n = this.data[e];
        if (!n)
          return r;
        var i = /* @__PURE__ */ new Set([n]);
        i.forEach(function(a) {
          F(a) && (r[a.__ref] = !0), L(a) && Object.keys(a).forEach(function(o) {
            var s = a[o];
            L(s) && i.add(s);
          });
        });
      }
      return this.refs[e];
    }, t.prototype.makeCacheKey = function() {
      return this.group.keyMaker.lookupArray(arguments);
    }, t;
  }()
), xi = (
  /** @class */
  function() {
    function t(e, r) {
      r === void 0 && (r = null), this.caching = e, this.parent = r, this.d = null, this.resetCaching();
    }
    return t.prototype.resetCaching = function() {
      this.d = this.caching ? fi() : null, this.keyMaker = new De(ke);
    }, t.prototype.depend = function(e, r) {
      if (this.d) {
        this.d(kt(e, r));
        var n = fe(r);
        n !== r && this.d(kt(e, n)), this.parent && this.parent.depend(e, r);
      }
    }, t.prototype.dirty = function(e, r) {
      this.d && this.d.dirty(
        kt(e, r),
        // When storeFieldName === "__exists", that means the entity identified
        // by dataId has either disappeared from the cache or was newly added,
        // so the result caching system would do well to "forget everything it
        // knows" about that object. To achieve that kind of invalidation, we
        // not only dirty the associated result cache entry, but also remove it
        // completely from the dependency graph. For the optimism implementation
        // details, see https://github.com/benjamn/optimism/pull/195.
        r === "__exists" ? "forget" : "setDirty"
      );
    }, t;
  }()
);
function kt(t, e) {
  return e + "#" + t;
}
function an(t, e) {
  Le(t) && t.group.depend(e, "__exists");
}
(function(t) {
  var e = (
    /** @class */
    function(r) {
      W(n, r);
      function n(i) {
        var a = i.policies, o = i.resultCaching, s = o === void 0 ? !0 : o, c = i.seed, u = r.call(this, a, new xi(s)) || this;
        return u.stump = new _s(u), u.storageTrie = new De(ke), c && u.replace(c), u;
      }
      return n.prototype.addLayer = function(i, a) {
        return this.stump.addLayer(i, a);
      }, n.prototype.removeLayer = function() {
        return this;
      }, n.prototype.getStorage = function() {
        return this.storageTrie.lookupArray(arguments);
      }, n;
    }(t)
  );
  t.Root = e;
})(ze || (ze = {}));
var ee = (
  /** @class */
  function(t) {
    W(e, t);
    function e(r, n, i, a) {
      var o = t.call(this, n.policies, a) || this;
      return o.id = r, o.parent = n, o.replay = i, o.group = a, i(o), o;
    }
    return e.prototype.addLayer = function(r, n) {
      return new e(r, this, n, this.group);
    }, e.prototype.removeLayer = function(r) {
      var n = this, i = this.parent.removeLayer(r);
      return r === this.id ? (this.group.caching && Object.keys(this.data).forEach(function(a) {
        var o = n.data[a], s = i.lookup(a);
        s ? o ? o !== s && Object.keys(o).forEach(function(c) {
          M(o[c], s[c]) || n.group.dirty(a, c);
        }) : (n.group.dirty(a, "__exists"), Object.keys(s).forEach(function(c) {
          n.group.dirty(a, c);
        })) : n.delete(a);
      }), i) : i === this.parent ? this : i.addLayer(this.id, this.replay);
    }, e.prototype.toObject = function() {
      return d(d({}, this.parent.toObject()), this.data);
    }, e.prototype.findChildRefIds = function(r) {
      var n = this.parent.findChildRefIds(r);
      return j.call(this.data, r) ? d(d({}, n), t.prototype.findChildRefIds.call(this, r)) : n;
    }, e.prototype.getStorage = function() {
      for (var r = this.parent; r.parent; )
        r = r.parent;
      return r.getStorage.apply(
        r,
        // @ts-expect-error
        arguments
      );
    }, e;
  }(ze)
), _s = (
  /** @class */
  function(t) {
    W(e, t);
    function e(r) {
      return t.call(this, "EntityStore.Stump", r, function() {
      }, new xi(r.group.caching, r.group)) || this;
    }
    return e.prototype.removeLayer = function() {
      return this;
    }, e.prototype.merge = function(r, n) {
      return this.parent.merge(r, n);
    }, e;
  }(ee)
);
function ws(t, e, r) {
  var n = t[r], i = e[r];
  return M(n, i) ? n : i;
}
function Le(t) {
  return !!(t instanceof ze && t.group.caching);
}
function Ts(t) {
  return L(t) ? x(t) ? t.slice(0) : d({ __proto__: Object.getPrototypeOf(t) }, t) : t;
}
var on = (
  /** @class */
  function() {
    function t() {
      this.known = new (zn ? WeakSet : Set)(), this.pool = new De(ke), this.passes = /* @__PURE__ */ new WeakMap(), this.keysByJSON = /* @__PURE__ */ new Map(), this.empty = this.admit({});
    }
    return t.prototype.isKnown = function(e) {
      return L(e) && this.known.has(e);
    }, t.prototype.pass = function(e) {
      if (L(e)) {
        var r = Ts(e);
        return this.passes.set(r, e), r;
      }
      return e;
    }, t.prototype.admit = function(e) {
      var r = this;
      if (L(e)) {
        var n = this.passes.get(e);
        if (n)
          return n;
        var i = Object.getPrototypeOf(e);
        switch (i) {
          case Array.prototype: {
            if (this.known.has(e))
              return e;
            var a = e.map(this.admit, this), o = this.pool.lookupArray(a);
            return o.array || (this.known.add(o.array = a), globalThis.__DEV__ !== !1 && Object.freeze(a)), o.array;
          }
          case null:
          case Object.prototype: {
            if (this.known.has(e))
              return e;
            var s = Object.getPrototypeOf(e), c = [s], u = this.sortedKeys(e);
            c.push(u.json);
            var f = c.length;
            u.sorted.forEach(function(p) {
              c.push(r.admit(e[p]));
            });
            var o = this.pool.lookupArray(c);
            if (!o.object) {
              var h = o.object = Object.create(s);
              this.known.add(h), u.sorted.forEach(function(p, y) {
                h[p] = c[f + y];
              }), globalThis.__DEV__ !== !1 && Object.freeze(h);
            }
            return o.object;
          }
        }
      }
      return e;
    }, t.prototype.sortedKeys = function(e) {
      var r = Object.keys(e), n = this.pool.lookupArray(r);
      if (!n.keys) {
        r.sort();
        var i = JSON.stringify(r);
        (n.keys = this.keysByJSON.get(i)) || this.keysByJSON.set(i, n.keys = { sorted: r, json: i });
      }
      return n.keys;
    }, t;
  }()
);
function sn(t) {
  return [
    t.selectionSet,
    t.objectOrReference,
    t.context,
    // We split out this property so we can pass different values
    // independently without modifying options.context itself.
    t.context.canonizeResults
  ];
}
var Is = (
  /** @class */
  function() {
    function t(e) {
      var r = this;
      this.knownResults = new (ke ? WeakMap : Map)(), this.config = Ie(e, {
        addTypename: e.addTypename !== !1,
        canonizeResults: Mi(e)
      }), this.canon = e.canon || new on(), this.executeSelectionSet = Qe(function(n) {
        var i, a = n.context.canonizeResults, o = sn(n);
        o[3] = !a;
        var s = (i = r.executeSelectionSet).peek.apply(i, o);
        return s ? a ? d(d({}, s), {
          // If we previously read this result without canonizing it, we can
          // reuse that result simply by canonizing it now.
          result: r.canon.admit(s.result)
        }) : s : (an(n.context.store, n.enclosingRef.__ref), r.execSelectionSetImpl(n));
      }, {
        max: this.config.resultCacheMaxSize || Z["inMemoryCache.executeSelectionSet"] || 5e4,
        keyArgs: sn,
        // Note that the parameters of makeCacheKey are determined by the
        // array returned by keyArgs.
        makeCacheKey: function(n, i, a, o) {
          if (Le(a.store))
            return a.store.makeCacheKey(n, F(i) ? i.__ref : i, a.varString, o);
        }
      }), this.executeSubSelectedArray = Qe(function(n) {
        return an(n.context.store, n.enclosingRef.__ref), r.execSubSelectedArrayImpl(n);
      }, {
        max: this.config.resultCacheMaxSize || Z["inMemoryCache.executeSubSelectedArray"] || 1e4,
        makeCacheKey: function(n) {
          var i = n.field, a = n.array, o = n.context;
          if (Le(o.store))
            return o.store.makeCacheKey(i, a, o.varString);
        }
      });
    }
    return t.prototype.resetCanon = function() {
      this.canon = new on();
    }, t.prototype.diffQueryAgainstStore = function(e) {
      var r = e.store, n = e.query, i = e.rootId, a = i === void 0 ? "ROOT_QUERY" : i, o = e.variables, s = e.returnPartialData, c = s === void 0 ? !0 : s, u = e.canonizeResults, f = u === void 0 ? this.config.canonizeResults : u, h = this.config.cache.policies;
      o = d(d({}, or(Kn(n))), o);
      var l = Ee(a), p = this.executeSelectionSet({
        selectionSet: $e(n).selectionSet,
        objectOrReference: l,
        enclosingRef: l,
        context: d({ store: r, query: n, policies: h, variables: o, varString: ie(o), canonizeResults: f }, Li(n, this.config.fragments))
      }), y;
      if (p.missing && (y = [
        new Ai(Ds(p.missing), p.missing, n, o)
      ], !c))
        throw y[0];
      return {
        result: p.result,
        complete: !y,
        missing: y
      };
    }, t.prototype.isFresh = function(e, r, n, i) {
      if (Le(i.store) && this.knownResults.get(e) === n) {
        var a = this.executeSelectionSet.peek(
          n,
          r,
          i,
          // If result is canonical, then it could only have been previously
          // cached by the canonizing version of executeSelectionSet, so we can
          // avoid checking both possibilities here.
          this.canon.isKnown(e)
        );
        if (a && e === a.result)
          return !0;
      }
      return !1;
    }, t.prototype.execSelectionSetImpl = function(e) {
      var r = this, n = e.selectionSet, i = e.objectOrReference, a = e.enclosingRef, o = e.context;
      if (F(i) && !o.policies.rootTypenamesById[i.__ref] && !o.store.has(i.__ref))
        return {
          result: this.canon.empty,
          missing: "Dangling reference to missing ".concat(i.__ref, " object")
        };
      var s = o.variables, c = o.policies, u = o.store, f = u.getFieldValue(i, "__typename"), h = [], l, p = new ce();
      this.config.addTypename && typeof f == "string" && !c.rootIdsByTypename[f] && h.push({ __typename: f });
      function y(g, S) {
        var O;
        return g.missing && (l = p.merge(l, (O = {}, O[S] = g.missing, O))), g.result;
      }
      var m = new Set(n.selections);
      m.forEach(function(g) {
        var S, O;
        if (Be(g, s))
          if (se(g)) {
            var _ = c.readField({
              fieldName: g.name.value,
              field: g,
              variables: o.variables,
              from: i
            }, o), T = oe(g);
            _ === void 0 ? lr.added(g) || (l = p.merge(l, (S = {}, S[T] = "Can't find field '".concat(g.name.value, "' on ").concat(F(i) ? i.__ref + " object" : "object " + JSON.stringify(i, null, 2)), S))) : x(_) ? _.length > 0 && (_ = y(r.executeSubSelectedArray({
              field: g,
              array: _,
              enclosingRef: a,
              context: o
            }), T)) : g.selectionSet ? _ != null && (_ = y(r.executeSelectionSet({
              selectionSet: g.selectionSet,
              objectOrReference: _,
              enclosingRef: F(_) ? _ : a,
              context: o
            }), T)) : o.canonizeResults && (_ = r.canon.pass(_)), _ !== void 0 && h.push((O = {}, O[T] = _, O));
          } else {
            var D = ft(g, o.lookupFragment);
            if (!D && g.kind === V.FRAGMENT_SPREAD)
              throw U(9, g.name.value);
            D && c.fragmentMatches(D, f) && D.selectionSet.selections.forEach(m.add, m);
          }
      });
      var b = yt(h), v = { result: b, missing: l }, E = o.canonizeResults ? this.canon.admit(v) : Bt(v);
      return E.result && this.knownResults.set(E.result, n), E;
    }, t.prototype.execSubSelectedArrayImpl = function(e) {
      var r = this, n = e.field, i = e.array, a = e.enclosingRef, o = e.context, s, c = new ce();
      function u(f, h) {
        var l;
        return f.missing && (s = c.merge(s, (l = {}, l[h] = f.missing, l))), f.result;
      }
      return n.selectionSet && (i = i.filter(o.store.canRead)), i = i.map(function(f, h) {
        return f === null ? null : x(f) ? u(r.executeSubSelectedArray({
          field: n,
          array: f,
          enclosingRef: a,
          context: o
        }), h) : n.selectionSet ? u(r.executeSelectionSet({
          selectionSet: n.selectionSet,
          objectOrReference: f,
          enclosingRef: F(f) ? f : a,
          context: o
        }), h) : (globalThis.__DEV__ !== !1 && ks(o.store, n, f), f);
      }), {
        result: o.canonizeResults ? this.canon.admit(i) : i,
        missing: s
      };
    }, t;
  }()
);
function Ds(t) {
  try {
    JSON.stringify(t, function(e, r) {
      if (typeof r == "string")
        throw r;
      return r;
    });
  } catch (e) {
    return e;
  }
}
function ks(t, e, r) {
  if (!e.selectionSet) {
    var n = /* @__PURE__ */ new Set([r]);
    n.forEach(function(i) {
      L(i) && (I(
        !F(i),
        10,
        Os(t, i),
        e.name.value
      ), Object.values(i).forEach(n.add, n));
    });
  }
}
var mr = new Zn(), un = /* @__PURE__ */ new WeakMap();
function xe(t) {
  var e = un.get(t);
  return e || un.set(t, e = {
    vars: /* @__PURE__ */ new Set(),
    dep: fi()
  }), e;
}
function cn(t) {
  xe(t).vars.forEach(function(e) {
    return e.forgetCache(t);
  });
}
function Rs(t) {
  xe(t).vars.forEach(function(e) {
    return e.attachCache(t);
  });
}
function Ns(t) {
  var e = /* @__PURE__ */ new Set(), r = /* @__PURE__ */ new Set(), n = function(a) {
    if (arguments.length > 0) {
      if (t !== a) {
        t = a, e.forEach(function(c) {
          xe(c).dep.dirty(n), As(c);
        });
        var o = Array.from(r);
        r.clear(), o.forEach(function(c) {
          return c(t);
        });
      }
    } else {
      var s = mr.getValue();
      s && (i(s), xe(s).dep(n));
    }
    return t;
  };
  n.onNextChange = function(a) {
    return r.add(a), function() {
      r.delete(a);
    };
  };
  var i = n.attachCache = function(a) {
    return e.add(a), xe(a).vars.add(n), n;
  };
  return n.forgetCache = function(a) {
    return e.delete(a);
  }, n;
}
function As(t) {
  t.broadcastWatches && t.broadcastWatches();
}
var fn = /* @__PURE__ */ Object.create(null);
function gr(t) {
  var e = JSON.stringify(t);
  return fn[e] || (fn[e] = /* @__PURE__ */ Object.create(null));
}
function ln(t) {
  var e = gr(t);
  return e.keyFieldsFn || (e.keyFieldsFn = function(r, n) {
    var i = function(o, s) {
      return n.readField(s, o);
    }, a = n.keyObject = br(t, function(o) {
      var s = Se(
        n.storeObject,
        o,
        // Using context.readField to extract paths from context.storeObject
        // allows the extraction to see through Reference objects and respect
        // custom read functions.
        i
      );
      return s === void 0 && r !== n.storeObject && j.call(r, o[0]) && (s = Se(r, o, ji)), I(s !== void 0, 4, o.join("."), r), s;
    });
    return "".concat(n.typename, ":").concat(JSON.stringify(a));
  });
}
function hn(t) {
  var e = gr(t);
  return e.keyArgsFn || (e.keyArgsFn = function(r, n) {
    var i = n.field, a = n.variables, o = n.fieldName, s = br(t, function(u) {
      var f = u[0], h = f.charAt(0);
      if (h === "@") {
        if (i && X(i.directives)) {
          var l = f.slice(1), p = i.directives.find(function(v) {
            return v.name.value === l;
          }), y = p && lt(p, a);
          return y && Se(
            y,
            // If keyPath.length === 1, this code calls extractKeyPath with an
            // empty path, which works because it uses directiveArgs as the
            // extracted value.
            u.slice(1)
          );
        }
        return;
      }
      if (h === "$") {
        var m = f.slice(1);
        if (a && j.call(a, m)) {
          var b = u.slice(0);
          return b[0] = m, Se(a, b);
        }
        return;
      }
      if (r)
        return Se(r, u);
    }), c = JSON.stringify(s);
    return (r || c !== "{}") && (o += ":" + c), o;
  });
}
function br(t, e) {
  var r = new ce();
  return qi(t).reduce(function(n, i) {
    var a, o = e(i);
    if (o !== void 0) {
      for (var s = i.length - 1; s >= 0; --s)
        o = (a = {}, a[i[s]] = o, a);
      n = r.merge(n, o);
    }
    return n;
  }, /* @__PURE__ */ Object.create(null));
}
function qi(t) {
  var e = gr(t);
  if (!e.paths) {
    var r = e.paths = [], n = [];
    t.forEach(function(i, a) {
      x(i) ? (qi(i).forEach(function(o) {
        return r.push(n.concat(o));
      }), n.length = 0) : (n.push(i), x(t[a + 1]) || (r.push(n.slice(0)), n.length = 0));
    });
  }
  return e.paths;
}
function ji(t, e) {
  return t[e];
}
function Se(t, e, r) {
  return r = r || ji, Vi(e.reduce(function n(i, a) {
    return x(i) ? i.map(function(o) {
      return n(o, a);
    }) : i && r(i, a);
  }, t));
}
function Vi(t) {
  return L(t) ? x(t) ? t.map(Vi) : br(Object.keys(t).sort(), function(e) {
    return Se(t, e);
  }) : t;
}
function Xt(t) {
  return t.args !== void 0 ? t.args : t.field ? lt(t.field, t.variables) : null;
}
var Fs = function() {
}, pn = function(t, e) {
  return e.fieldName;
}, dn = function(t, e, r) {
  var n = r.mergeObjects;
  return n(t, e);
}, vn = function(t, e) {
  return e;
}, Ps = (
  /** @class */
  function() {
    function t(e) {
      this.config = e, this.typePolicies = /* @__PURE__ */ Object.create(null), this.toBeAdded = /* @__PURE__ */ Object.create(null), this.supertypeMap = /* @__PURE__ */ new Map(), this.fuzzySubtypes = /* @__PURE__ */ new Map(), this.rootIdsByTypename = /* @__PURE__ */ Object.create(null), this.rootTypenamesById = /* @__PURE__ */ Object.create(null), this.usingPossibleTypes = !1, this.config = d({ dataIdFromObject: Fi }, e), this.cache = this.config.cache, this.setRootTypename("Query"), this.setRootTypename("Mutation"), this.setRootTypename("Subscription"), e.possibleTypes && this.addPossibleTypes(e.possibleTypes), e.typePolicies && this.addTypePolicies(e.typePolicies);
    }
    return t.prototype.identify = function(e, r) {
      var n, i = this, a = r && (r.typename || ((n = r.storeObject) === null || n === void 0 ? void 0 : n.__typename)) || e.__typename;
      if (a === this.rootTypenamesById.ROOT_QUERY)
        return ["ROOT_QUERY"];
      for (var o = r && r.storeObject || e, s = d(d({}, r), { typename: a, storeObject: o, readField: r && r.readField || function() {
        var l = Er(arguments, o);
        return i.readField(l, {
          store: i.cache.data,
          variables: l.variables
        });
      } }), c, u = a && this.getTypePolicy(a), f = u && u.keyFn || this.config.dataIdFromObject; f; ) {
        var h = f(d(d({}, e), o), s);
        if (x(h))
          f = ln(h);
        else {
          c = h;
          break;
        }
      }
      return c = c ? String(c) : void 0, s.keyObject ? [c, s.keyObject] : [c];
    }, t.prototype.addTypePolicies = function(e) {
      var r = this;
      Object.keys(e).forEach(function(n) {
        var i = e[n], a = i.queryType, o = i.mutationType, s = i.subscriptionType, c = ae(i, ["queryType", "mutationType", "subscriptionType"]);
        a && r.setRootTypename("Query", n), o && r.setRootTypename("Mutation", n), s && r.setRootTypename("Subscription", n), j.call(r.toBeAdded, n) ? r.toBeAdded[n].push(c) : r.toBeAdded[n] = [c];
      });
    }, t.prototype.updateTypePolicy = function(e, r) {
      var n = this, i = this.getTypePolicy(e), a = r.keyFields, o = r.fields;
      function s(c, u) {
        c.merge = typeof u == "function" ? u : u === !0 ? dn : u === !1 ? vn : c.merge;
      }
      s(i, r.merge), i.keyFn = // Pass false to disable normalization for this typename.
      a === !1 ? Fs : x(a) ? ln(a) : typeof a == "function" ? a : i.keyFn, o && Object.keys(o).forEach(function(c) {
        var u = n.getFieldPolicy(e, c, !0), f = o[c];
        if (typeof f == "function")
          u.read = f;
        else {
          var h = f.keyArgs, l = f.read, p = f.merge;
          u.keyFn = // Pass false to disable argument-based differentiation of
          // field identities.
          h === !1 ? pn : x(h) ? hn(h) : typeof h == "function" ? h : u.keyFn, typeof l == "function" && (u.read = l), s(u, p);
        }
        u.read && u.merge && (u.keyFn = u.keyFn || pn);
      });
    }, t.prototype.setRootTypename = function(e, r) {
      r === void 0 && (r = e);
      var n = "ROOT_" + e.toUpperCase(), i = this.rootTypenamesById[n];
      r !== i && (I(!i || i === e, 5, e), i && delete this.rootIdsByTypename[i], this.rootIdsByTypename[r] = n, this.rootTypenamesById[n] = r);
    }, t.prototype.addPossibleTypes = function(e) {
      var r = this;
      this.usingPossibleTypes = !0, Object.keys(e).forEach(function(n) {
        r.getSupertypeSet(n, !0), e[n].forEach(function(i) {
          r.getSupertypeSet(i, !0).add(n);
          var a = i.match(Ci);
          (!a || a[0] !== i) && r.fuzzySubtypes.set(i, new RegExp(i));
        });
      });
    }, t.prototype.getTypePolicy = function(e) {
      var r = this;
      if (!j.call(this.typePolicies, e)) {
        var n = this.typePolicies[e] = /* @__PURE__ */ Object.create(null);
        n.fields = /* @__PURE__ */ Object.create(null);
        var i = this.supertypeMap.get(e);
        !i && this.fuzzySubtypes.size && (i = this.getSupertypeSet(e, !0), this.fuzzySubtypes.forEach(function(o, s) {
          if (o.test(e)) {
            var c = r.supertypeMap.get(s);
            c && c.forEach(function(u) {
              return i.add(u);
            });
          }
        })), i && i.size && i.forEach(function(o) {
          var s = r.getTypePolicy(o), c = s.fields, u = ae(s, ["fields"]);
          Object.assign(n, u), Object.assign(n.fields, c);
        });
      }
      var a = this.toBeAdded[e];
      return a && a.length && a.splice(0).forEach(function(o) {
        r.updateTypePolicy(e, o);
      }), this.typePolicies[e];
    }, t.prototype.getFieldPolicy = function(e, r, n) {
      if (e) {
        var i = this.getTypePolicy(e).fields;
        return i[r] || n && (i[r] = /* @__PURE__ */ Object.create(null));
      }
    }, t.prototype.getSupertypeSet = function(e, r) {
      var n = this.supertypeMap.get(e);
      return !n && r && this.supertypeMap.set(e, n = /* @__PURE__ */ new Set()), n;
    }, t.prototype.fragmentMatches = function(e, r, n, i) {
      var a = this;
      if (!e.typeCondition)
        return !0;
      if (!r)
        return !1;
      var o = e.typeCondition.name.value;
      if (r === o)
        return !0;
      if (this.usingPossibleTypes && this.supertypeMap.has(o))
        for (var s = this.getSupertypeSet(r, !0), c = [s], u = function(y) {
          var m = a.getSupertypeSet(y, !1);
          m && m.size && c.indexOf(m) < 0 && c.push(m);
        }, f = !!(n && this.fuzzySubtypes.size), h = !1, l = 0; l < c.length; ++l) {
          var p = c[l];
          if (p.has(o))
            return s.has(o) || (h && globalThis.__DEV__ !== !1 && I.warn(6, r, o), s.add(o)), !0;
          p.forEach(u), f && // Start checking fuzzy subtypes only after exhausting all
          // non-fuzzy subtypes (after the final iteration of the loop).
          l === c.length - 1 && // We could wait to compare fragment.selectionSet to result
          // after we verify the supertype, but this check is often less
          // expensive than that search, and we will have to do the
          // comparison anyway whenever we find a potential match.
          Jt(e.selectionSet, n, i) && (f = !1, h = !0, this.fuzzySubtypes.forEach(function(y, m) {
            var b = r.match(y);
            b && b[0] === r && u(m);
          }));
        }
      return !1;
    }, t.prototype.hasKeyArgs = function(e, r) {
      var n = this.getFieldPolicy(e, r, !1);
      return !!(n && n.keyFn);
    }, t.prototype.getStoreFieldName = function(e) {
      var r = e.typename, n = e.fieldName, i = this.getFieldPolicy(r, n, !1), a, o = i && i.keyFn;
      if (o && r)
        for (var s = {
          typename: r,
          fieldName: n,
          field: e.field || null,
          variables: e.variables
        }, c = Xt(e); o; ) {
          var u = o(c, s);
          if (x(u))
            o = hn(u);
          else {
            a = u || n;
            break;
          }
        }
      return a === void 0 && (a = e.field ? eo(e.field, e.variables) : Xn(n, Xt(e))), a === !1 ? n : n === fe(a) ? a : n + ":" + a;
    }, t.prototype.readField = function(e, r) {
      var n = e.from;
      if (n) {
        var i = e.field || e.fieldName;
        if (i) {
          if (e.typename === void 0) {
            var a = r.store.getFieldValue(n, "__typename");
            a && (e.typename = a);
          }
          var o = this.getStoreFieldName(e), s = fe(o), c = r.store.getFieldValue(n, o), u = this.getFieldPolicy(e.typename, s, !1), f = u && u.read;
          if (f) {
            var h = yn(this, n, e, r, r.store.getStorage(F(n) ? n.__ref : n, o));
            return mr.withValue(this.cache, f, [
              c,
              h
            ]);
          }
          return c;
        }
      }
    }, t.prototype.getReadFunction = function(e, r) {
      var n = this.getFieldPolicy(e, r, !1);
      return n && n.read;
    }, t.prototype.getMergeFunction = function(e, r, n) {
      var i = this.getFieldPolicy(e, r, !1), a = i && i.merge;
      return !a && n && (i = this.getTypePolicy(n), a = i && i.merge), a;
    }, t.prototype.runMergeFunction = function(e, r, n, i, a) {
      var o = n.field, s = n.typename, c = n.merge;
      return c === dn ? Qi(i.store)(e, r) : c === vn ? r : (i.overwrite && (e = void 0), c(e, r, yn(
        this,
        // Unlike options.readField for read functions, we do not fall
        // back to the current object if no foreignObjOrRef is provided,
        // because it's not clear what the current object should be for
        // merge functions: the (possibly undefined) existing object, or
        // the incoming object? If you think your merge function needs
        // to read sibling fields in order to produce a new value for
        // the current field, you might want to rethink your strategy,
        // because that's a recipe for making merge behavior sensitive
        // to the order in which fields are written into the cache.
        // However, readField(name, ref) is useful for merge functions
        // that need to deduplicate child objects and references.
        void 0,
        {
          typename: s,
          fieldName: o.name.value,
          field: o,
          variables: i.variables
        },
        i,
        a || /* @__PURE__ */ Object.create(null)
      )));
    }, t;
  }()
);
function yn(t, e, r, n, i) {
  var a = t.getStoreFieldName(r), o = fe(a), s = r.variables || n.variables, c = n.store, u = c.toReference, f = c.canRead;
  return {
    args: Xt(r),
    field: r.field || null,
    fieldName: o,
    storeFieldName: a,
    variables: s,
    isReference: F,
    toReference: u,
    storage: i,
    cache: t.cache,
    canRead: f,
    readField: function() {
      return t.readField(Er(arguments, e, s), n);
    },
    mergeObjects: Qi(n.store)
  };
}
function Er(t, e, r) {
  var n = t[0], i = t[1], a = t.length, o;
  return typeof n == "string" ? o = {
    fieldName: n,
    // Default to objectOrReference only when no second argument was
    // passed for the from parameter, not when undefined is explicitly
    // passed as the second argument.
    from: a > 1 ? i : e
  } : (o = d({}, n), j.call(o, "from") || (o.from = e)), globalThis.__DEV__ !== !1 && o.from === void 0 && globalThis.__DEV__ !== !1 && I.warn(7, qn(Array.from(t))), o.variables === void 0 && (o.variables = r), o;
}
function Qi(t) {
  return function(r, n) {
    if (x(r) || x(n))
      throw U(8);
    if (L(r) && L(n)) {
      var i = t.getFieldValue(r, "__typename"), a = t.getFieldValue(n, "__typename"), o = i && a && i !== a;
      if (o)
        return n;
      if (F(r) && ge(n))
        return t.merge(r.__ref, n), r;
      if (ge(r) && F(n))
        return t.merge(r, n.__ref), n;
      if (ge(r) && ge(n))
        return d(d({}, r), n);
    }
    return n;
  };
}
function Rt(t, e, r) {
  var n = "".concat(e).concat(r), i = t.flavors.get(n);
  return i || t.flavors.set(n, i = t.clientOnly === e && t.deferred === r ? t : d(d({}, t), { clientOnly: e, deferred: r })), i;
}
var Ms = (
  /** @class */
  function() {
    function t(e, r, n) {
      this.cache = e, this.reader = r, this.fragments = n;
    }
    return t.prototype.writeToStore = function(e, r) {
      var n = this, i = r.query, a = r.result, o = r.dataId, s = r.variables, c = r.overwrite, u = He(i), f = Ss();
      s = d(d({}, or(u)), s);
      var h = d(d({ store: e, written: /* @__PURE__ */ Object.create(null), merge: function(p, y) {
        return f.merge(p, y);
      }, variables: s, varString: ie(s) }, Li(i, this.fragments)), { overwrite: !!c, incomingById: /* @__PURE__ */ new Map(), clientOnly: !1, deferred: !1, flavors: /* @__PURE__ */ new Map() }), l = this.processSelectionSet({
        result: a || /* @__PURE__ */ Object.create(null),
        dataId: o,
        selectionSet: u.selectionSet,
        mergeTree: { map: /* @__PURE__ */ new Map() },
        context: h
      });
      if (!F(l))
        throw U(11, a);
      return h.incomingById.forEach(function(p, y) {
        var m = p.storeObject, b = p.mergeTree, v = p.fieldNodeSet, E = Ee(y);
        if (b && b.map.size) {
          var g = n.applyMerges(b, E, m, h);
          if (F(g))
            return;
          m = g;
        }
        if (globalThis.__DEV__ !== !1 && !h.overwrite) {
          var S = /* @__PURE__ */ Object.create(null);
          v.forEach(function(T) {
            T.selectionSet && (S[T.name.value] = !0);
          });
          var O = function(T) {
            return S[fe(T)] === !0;
          }, _ = function(T) {
            var D = b && b.map.get(T);
            return !!(D && D.info && D.info.merge);
          };
          Object.keys(m).forEach(function(T) {
            O(T) && !_(T) && Cs(E, m, T, h.store);
          });
        }
        e.merge(y, m);
      }), e.retain(l.__ref), l;
    }, t.prototype.processSelectionSet = function(e) {
      var r = this, n = e.dataId, i = e.result, a = e.selectionSet, o = e.context, s = e.mergeTree, c = this.cache.policies, u = /* @__PURE__ */ Object.create(null), f = n && c.rootTypenamesById[n] || Vt(i, a, o.fragmentMap) || n && o.store.get(n, "__typename");
      typeof f == "string" && (u.__typename = f);
      var h = function() {
        var g = Er(arguments, u, o.variables);
        if (F(g.from)) {
          var S = o.incomingById.get(g.from.__ref);
          if (S) {
            var O = c.readField(d(d({}, g), { from: S.storeObject }), o);
            if (O !== void 0)
              return O;
          }
        }
        return c.readField(g, o);
      }, l = /* @__PURE__ */ new Set();
      this.flattenFields(
        a,
        i,
        // This WriteContext will be the default context value for fields returned
        // by the flattenFields method, but some fields may be assigned a modified
        // context, depending on the presence of @client and other directives.
        o,
        f
      ).forEach(function(g, S) {
        var O, _ = oe(S), T = i[_];
        if (l.add(S), T !== void 0) {
          var D = c.getStoreFieldName({
            typename: f,
            fieldName: S.name.value,
            field: S,
            variables: g.variables
          }), k = mn(s, D), N = r.processFieldValue(
            T,
            S,
            // Reset context.clientOnly and context.deferred to their default
            // values before processing nested selection sets.
            S.selectionSet ? Rt(g, !1, !1) : g,
            k
          ), C = void 0;
          S.selectionSet && (F(N) || ge(N)) && (C = h("__typename", N));
          var Y = c.getMergeFunction(f, S.name.value, C);
          Y ? k.info = {
            // TODO Check compatibility against any existing childTree.field?
            field: S,
            typename: f,
            merge: Y
          } : gn(s, D), u = g.merge(u, (O = {}, O[D] = N, O));
        } else
          globalThis.__DEV__ !== !1 && !g.clientOnly && !g.deferred && !lr.added(S) && // If the field has a read function, it may be a synthetic field or
          // provide a default value, so its absence from the written data should
          // not be cause for alarm.
          !c.getReadFunction(f, S.name.value) && globalThis.__DEV__ !== !1 && I.error(12, oe(S), i);
      });
      try {
        var p = c.identify(i, {
          typename: f,
          selectionSet: a,
          fragmentMap: o.fragmentMap,
          storeObject: u,
          readField: h
        }), y = p[0], m = p[1];
        n = n || y, m && (u = o.merge(u, m));
      } catch (g) {
        if (!n)
          throw g;
      }
      if (typeof n == "string") {
        var b = Ee(n), v = o.written[n] || (o.written[n] = []);
        if (v.indexOf(a) >= 0 || (v.push(a), this.reader && this.reader.isFresh(i, b, a, o)))
          return b;
        var E = o.incomingById.get(n);
        return E ? (E.storeObject = o.merge(E.storeObject, u), E.mergeTree = Kt(E.mergeTree, s), l.forEach(function(g) {
          return E.fieldNodeSet.add(g);
        })) : o.incomingById.set(n, {
          storeObject: u,
          // Save a reference to mergeTree only if it is not empty, because
          // empty MergeTrees may be recycled by maybeRecycleChildMergeTree and
          // reused for entirely different parts of the result tree.
          mergeTree: ot(s) ? void 0 : s,
          fieldNodeSet: l
        }), b;
      }
      return u;
    }, t.prototype.processFieldValue = function(e, r, n, i) {
      var a = this;
      return !r.selectionSet || e === null ? globalThis.__DEV__ !== !1 ? gi(e) : e : x(e) ? e.map(function(o, s) {
        var c = a.processFieldValue(o, r, n, mn(i, s));
        return gn(i, s), c;
      }) : this.processSelectionSet({
        result: e,
        selectionSet: r.selectionSet,
        context: n,
        mergeTree: i
      });
    }, t.prototype.flattenFields = function(e, r, n, i) {
      i === void 0 && (i = Vt(r, e, n.fragmentMap));
      var a = /* @__PURE__ */ new Map(), o = this.cache.policies, s = new De(!1);
      return function c(u, f) {
        var h = s.lookup(
          u,
          // Because we take inheritedClientOnly and inheritedDeferred into
          // consideration here (in addition to selectionSet), it's possible for
          // the same selection set to be flattened more than once, if it appears
          // in the query with different @client and/or @directive configurations.
          f.clientOnly,
          f.deferred
        );
        h.visited || (h.visited = !0, u.selections.forEach(function(l) {
          if (Be(l, n.variables)) {
            var p = f.clientOnly, y = f.deferred;
            if (
              // Since the presence of @client or @defer on this field can only
              // cause clientOnly or deferred to become true, we can skip the
              // forEach loop if both clientOnly and deferred are already true.
              !(p && y) && X(l.directives) && l.directives.forEach(function(v) {
                var E = v.name.value;
                if (E === "client" && (p = !0), E === "defer") {
                  var g = lt(v, n.variables);
                  (!g || g.if !== !1) && (y = !0);
                }
              }), se(l)
            ) {
              var m = a.get(l);
              m && (p = p && m.clientOnly, y = y && m.deferred), a.set(l, Rt(n, p, y));
            } else {
              var b = ft(l, n.lookupFragment);
              if (!b && l.kind === V.FRAGMENT_SPREAD)
                throw U(13, l.name.value);
              b && o.fragmentMatches(b, i, r, n.variables) && c(b.selectionSet, Rt(n, p, y));
            }
          }
        }));
      }(e, n), a;
    }, t.prototype.applyMerges = function(e, r, n, i, a) {
      var o, s = this;
      if (e.map.size && !F(n)) {
        var c = (
          // Items in the same position in different arrays are not
          // necessarily related to each other, so when incoming is an array
          // we process its elements as if there was no existing data.
          !x(n) && // Likewise, existing must be either a Reference or a StoreObject
          // in order for its fields to be safe to merge with the fields of
          // the incoming object.
          (F(r) || ge(r)) ? r : void 0
        ), u = n;
        c && !a && (a = [F(c) ? c.__ref : c]);
        var f, h = function(l, p) {
          return x(l) ? typeof p == "number" ? l[p] : void 0 : i.store.getFieldValue(l, String(p));
        };
        e.map.forEach(function(l, p) {
          var y = h(c, p), m = h(u, p);
          if (m !== void 0) {
            a && a.push(p);
            var b = s.applyMerges(l, y, m, i, a);
            b !== m && (f = f || /* @__PURE__ */ new Map(), f.set(p, b)), a && I(a.pop() === p);
          }
        }), f && (n = x(u) ? u.slice(0) : d({}, u), f.forEach(function(l, p) {
          n[p] = l;
        }));
      }
      return e.info ? this.cache.policies.runMergeFunction(r, n, e.info, i, a && (o = i.store).getStorage.apply(o, a)) : n;
    }, t;
  }()
), Ui = [];
function mn(t, e) {
  var r = t.map;
  return r.has(e) || r.set(e, Ui.pop() || { map: /* @__PURE__ */ new Map() }), r.get(e);
}
function Kt(t, e) {
  if (t === e || !e || ot(e))
    return t;
  if (!t || ot(t))
    return e;
  var r = t.info && e.info ? d(d({}, t.info), e.info) : t.info || e.info, n = t.map.size && e.map.size, i = n ? /* @__PURE__ */ new Map() : t.map.size ? t.map : e.map, a = { info: r, map: i };
  if (n) {
    var o = new Set(e.map.keys());
    t.map.forEach(function(s, c) {
      a.map.set(c, Kt(s, e.map.get(c))), o.delete(c);
    }), o.forEach(function(s) {
      a.map.set(s, Kt(e.map.get(s), t.map.get(s)));
    });
  }
  return a;
}
function ot(t) {
  return !t || !(t.info || t.map.size);
}
function gn(t, e) {
  var r = t.map, n = r.get(e);
  n && ot(n) && (Ui.push(n), r.delete(e));
}
var bn = /* @__PURE__ */ new Set();
function Cs(t, e, r, n) {
  var i = function(h) {
    var l = n.getFieldValue(h, r);
    return typeof l == "object" && l;
  }, a = i(t);
  if (a) {
    var o = i(e);
    if (o && !F(a) && !M(a, o) && !Object.keys(a).every(function(h) {
      return n.getFieldValue(o, h) !== void 0;
    })) {
      var s = n.getFieldValue(t, "__typename") || n.getFieldValue(e, "__typename"), c = fe(r), u = "".concat(s, ".").concat(c);
      if (!bn.has(u)) {
        bn.add(u);
        var f = [];
        !x(a) && !x(o) && [a, o].forEach(function(h) {
          var l = n.getFieldValue(h, "__typename");
          typeof l == "string" && !f.includes(l) && f.push(l);
        }), globalThis.__DEV__ !== !1 && I.warn(14, c, s, f.length ? "either ensure all objects of type " + f.join(" and ") + " have an ID or a custom merge function, or " : "", u, d({}, a), d({}, o));
      }
    }
  }
}
var zi = (
  /** @class */
  function(t) {
    W(e, t);
    function e(r) {
      r === void 0 && (r = {});
      var n = t.call(this) || this;
      return n.watches = /* @__PURE__ */ new Set(), n.addTypenameTransform = new li(lr), n.assumeImmutableResults = !0, n.makeVar = Ns, n.txCount = 0, n.config = Es(r), n.addTypename = !!n.config.addTypename, n.policies = new Ps({
        cache: n,
        dataIdFromObject: n.config.dataIdFromObject,
        possibleTypes: n.config.possibleTypes,
        typePolicies: n.config.typePolicies
      }), n.init(), n;
    }
    return e.prototype.init = function() {
      var r = this.data = new ze.Root({
        policies: this.policies,
        resultCaching: this.config.resultCaching
      });
      this.optimisticData = r.stump, this.resetResultCache();
    }, e.prototype.resetResultCache = function(r) {
      var n = this, i = this.storeReader, a = this.config.fragments;
      this.storeWriter = new Ms(this, this.storeReader = new Is({
        cache: this,
        addTypename: this.addTypename,
        resultCacheMaxSize: this.config.resultCacheMaxSize,
        canonizeResults: Mi(this.config),
        canon: r ? void 0 : i && i.canon,
        fragments: a
      }), a), this.maybeBroadcastWatch = Qe(function(o, s) {
        return n.broadcastWatch(o, s);
      }, {
        max: this.config.resultCacheMaxSize || Z["inMemoryCache.maybeBroadcastWatch"] || 5e3,
        makeCacheKey: function(o) {
          var s = o.optimistic ? n.optimisticData : n.data;
          if (Le(s)) {
            var c = o.optimistic, u = o.id, f = o.variables;
            return s.makeCacheKey(
              o.query,
              // Different watches can have the same query, optimistic
              // status, rootId, and variables, but if their callbacks are
              // different, the (identical) result needs to be delivered to
              // each distinct callback. The easiest way to achieve that
              // separation is to include c.callback in the cache key for
              // maybeBroadcastWatch calls. See issue #5733.
              o.callback,
              ie({ optimistic: c, id: u, variables: f })
            );
          }
        }
      }), (/* @__PURE__ */ new Set([this.data.group, this.optimisticData.group])).forEach(function(o) {
        return o.resetCaching();
      });
    }, e.prototype.restore = function(r) {
      return this.init(), r && this.data.replace(r), this;
    }, e.prototype.extract = function(r) {
      return r === void 0 && (r = !1), (r ? this.optimisticData : this.data).extract();
    }, e.prototype.read = function(r) {
      var n = r.returnPartialData, i = n === void 0 ? !1 : n;
      try {
        return this.storeReader.diffQueryAgainstStore(d(d({}, r), { store: r.optimistic ? this.optimisticData : this.data, config: this.config, returnPartialData: i })).result || null;
      } catch (a) {
        if (a instanceof Ai)
          return null;
        throw a;
      }
    }, e.prototype.write = function(r) {
      try {
        return ++this.txCount, this.storeWriter.writeToStore(this.data, r);
      } finally {
        !--this.txCount && r.broadcast !== !1 && this.broadcastWatches();
      }
    }, e.prototype.modify = function(r) {
      if (j.call(r, "id") && !r.id)
        return !1;
      var n = r.optimistic ? this.optimisticData : this.data;
      try {
        return ++this.txCount, n.modify(r.id || "ROOT_QUERY", r.fields);
      } finally {
        !--this.txCount && r.broadcast !== !1 && this.broadcastWatches();
      }
    }, e.prototype.diff = function(r) {
      return this.storeReader.diffQueryAgainstStore(d(d({}, r), { store: r.optimistic ? this.optimisticData : this.data, rootId: r.id || "ROOT_QUERY", config: this.config }));
    }, e.prototype.watch = function(r) {
      var n = this;
      return this.watches.size || Rs(this), this.watches.add(r), r.immediate && this.maybeBroadcastWatch(r), function() {
        n.watches.delete(r) && !n.watches.size && cn(n), n.maybeBroadcastWatch.forget(r);
      };
    }, e.prototype.gc = function(r) {
      var n;
      ie.reset(), vt.reset(), this.addTypenameTransform.resetCache(), (n = this.config.fragments) === null || n === void 0 || n.resetCaches();
      var i = this.optimisticData.gc();
      return r && !this.txCount && (r.resetResultCache ? this.resetResultCache(r.resetResultIdentities) : r.resetResultIdentities && this.storeReader.resetCanon()), i;
    }, e.prototype.retain = function(r, n) {
      return (n ? this.optimisticData : this.data).retain(r);
    }, e.prototype.release = function(r, n) {
      return (n ? this.optimisticData : this.data).release(r);
    }, e.prototype.identify = function(r) {
      if (F(r))
        return r.__ref;
      try {
        return this.policies.identify(r)[0];
      } catch (n) {
        globalThis.__DEV__ !== !1 && I.warn(n);
      }
    }, e.prototype.evict = function(r) {
      if (!r.id) {
        if (j.call(r, "id"))
          return !1;
        r = d(d({}, r), { id: "ROOT_QUERY" });
      }
      try {
        return ++this.txCount, this.optimisticData.evict(r, this.data);
      } finally {
        !--this.txCount && r.broadcast !== !1 && this.broadcastWatches();
      }
    }, e.prototype.reset = function(r) {
      var n = this;
      return this.init(), ie.reset(), r && r.discardWatches ? (this.watches.forEach(function(i) {
        return n.maybeBroadcastWatch.forget(i);
      }), this.watches.clear(), cn(this)) : this.broadcastWatches(), Promise.resolve();
    }, e.prototype.removeOptimistic = function(r) {
      var n = this.optimisticData.removeLayer(r);
      n !== this.optimisticData && (this.optimisticData = n, this.broadcastWatches());
    }, e.prototype.batch = function(r) {
      var n = this, i = r.update, a = r.optimistic, o = a === void 0 ? !0 : a, s = r.removeOptimistic, c = r.onWatchUpdated, u, f = function(l) {
        var p = n, y = p.data, m = p.optimisticData;
        ++n.txCount, l && (n.data = n.optimisticData = l);
        try {
          return u = i(n);
        } finally {
          --n.txCount, n.data = y, n.optimisticData = m;
        }
      }, h = /* @__PURE__ */ new Set();
      return c && !this.txCount && this.broadcastWatches(d(d({}, r), { onWatchUpdated: function(l) {
        return h.add(l), !1;
      } })), typeof o == "string" ? this.optimisticData = this.optimisticData.addLayer(o, f) : o === !1 ? f(this.data) : f(), typeof s == "string" && (this.optimisticData = this.optimisticData.removeLayer(s)), c && h.size ? (this.broadcastWatches(d(d({}, r), { onWatchUpdated: function(l, p) {
        var y = c.call(this, l, p);
        return y !== !1 && h.delete(l), y;
      } })), h.size && h.forEach(function(l) {
        return n.maybeBroadcastWatch.dirty(l);
      })) : this.broadcastWatches(r), u;
    }, e.prototype.performTransaction = function(r, n) {
      return this.batch({
        update: r,
        optimistic: n || n !== null
      });
    }, e.prototype.transformDocument = function(r) {
      return this.addTypenameToDocument(this.addFragmentsToDocument(r));
    }, e.prototype.broadcastWatches = function(r) {
      var n = this;
      this.txCount || this.watches.forEach(function(i) {
        return n.maybeBroadcastWatch(i, r);
      });
    }, e.prototype.addFragmentsToDocument = function(r) {
      var n = this.config.fragments;
      return n ? n.transform(r) : r;
    }, e.prototype.addTypenameToDocument = function(r) {
      return this.addTypename ? this.addTypenameTransform.transformDocument(r) : r;
    }, e.prototype.broadcastWatch = function(r, n) {
      var i = r.lastDiff, a = this.diff(r);
      n && (r.optimistic && typeof n.optimistic == "string" && (a.fromOptimisticTransaction = !0), n.onWatchUpdated && n.onWatchUpdated.call(this, r, a, i) === !1) || (!i || !M(i.result, a.result)) && r.callback(r.lastDiff = a, i);
    }, e;
  }(Ni)
);
globalThis.__DEV__ !== !1 && (zi.prototype.getMemoryInternals = La);
var P;
(function(t) {
  t[t.loading = 1] = "loading", t[t.setVariables = 2] = "setVariables", t[t.fetchMore = 3] = "fetchMore", t[t.refetch = 4] = "refetch", t[t.poll = 6] = "poll", t[t.ready = 7] = "ready", t[t.error = 8] = "error";
})(P || (P = {}));
function We(t) {
  return t ? t < 7 : !1;
}
var En = Object.assign, Ls = Object.hasOwnProperty, Zt = (
  /** @class */
  function(t) {
    W(e, t);
    function e(r) {
      var n = r.queryManager, i = r.queryInfo, a = r.options, o = t.call(this, function(b) {
        try {
          var v = b._subscription._observer;
          v && !v.error && (v.error = xs);
        } catch {
        }
        var E = !o.observers.size;
        o.observers.add(b);
        var g = o.last;
        return g && g.error ? b.error && b.error(g.error) : g && g.result && b.next && b.next(g.result), E && o.reobserve().catch(function() {
        }), function() {
          o.observers.delete(b) && !o.observers.size && o.tearDownQuery();
        };
      }) || this;
      o.observers = /* @__PURE__ */ new Set(), o.subscriptions = /* @__PURE__ */ new Set(), o.queryInfo = i, o.queryManager = n, o.waitForOwnResult = Nt(a.fetchPolicy), o.isTornDown = !1;
      var s = n.defaultOptions.watchQuery, c = s === void 0 ? {} : s, u = c.fetchPolicy, f = u === void 0 ? "cache-first" : u, h = a.fetchPolicy, l = h === void 0 ? f : h, p = a.initialFetchPolicy, y = p === void 0 ? l === "standby" ? f : l : p;
      o.options = d(d({}, a), {
        // Remember the initial options.fetchPolicy so we can revert back to this
        // policy when variables change. This information can also be specified
        // (or overridden) by providing options.initialFetchPolicy explicitly.
        initialFetchPolicy: y,
        // This ensures this.options.fetchPolicy always has a string value, in
        // case options.fetchPolicy was not provided.
        fetchPolicy: l
      }), o.queryId = i.queryId || n.generateQueryId();
      var m = He(o.query);
      return o.queryName = m && m.name && m.name.value, o;
    }
    return Object.defineProperty(e.prototype, "query", {
      // The `query` computed property will always reflect the document transformed
      // by the last run query. `this.options.query` will always reflect the raw
      // untransformed query to ensure document transforms with runtime conditionals
      // are run on the original document.
      get: function() {
        return this.lastQuery || this.options.query;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(e.prototype, "variables", {
      // Computed shorthand for this.options.variables, preserved for
      // backwards compatibility.
      /**
       * An object containing the variables that were provided for the query.
       */
      get: function() {
        return this.options.variables;
      },
      enumerable: !1,
      configurable: !0
    }), e.prototype.result = function() {
      var r = this;
      return new Promise(function(n, i) {
        var a = {
          next: function(s) {
            n(s), r.observers.delete(a), r.observers.size || r.queryManager.removeQuery(r.queryId), setTimeout(function() {
              o.unsubscribe();
            }, 0);
          },
          error: i
        }, o = r.subscribe(a);
      });
    }, e.prototype.resetDiff = function() {
      this.queryInfo.resetDiff();
    }, e.prototype.getCurrentResult = function(r) {
      r === void 0 && (r = !0);
      var n = this.getLastResult(!0), i = this.queryInfo.networkStatus || n && n.networkStatus || P.ready, a = d(d({}, n), { loading: We(i), networkStatus: i }), o = this.options.fetchPolicy, s = o === void 0 ? "cache-first" : o;
      if (
        // These fetch policies should never deliver data from the cache, unless
        // redelivering a previously delivered result.
        !(Nt(s) || // If this.options.query has @client(always: true) fields, we cannot
        // trust diff.result, since it was read from the cache without running
        // local resolvers (and it's too late to run resolvers now, since we must
        // return a result synchronously).
        this.queryManager.getDocumentInfo(this.query).hasForcedResolvers)
      )
        if (this.waitForOwnResult)
          this.queryInfo.updateWatch();
        else {
          var c = this.queryInfo.getDiff();
          (c.complete || this.options.returnPartialData) && (a.data = c.result), M(a.data, {}) && (a.data = void 0), c.complete ? (delete a.partial, c.complete && a.networkStatus === P.loading && (s === "cache-first" || s === "cache-only") && (a.networkStatus = P.ready, a.loading = !1)) : a.partial = !0, globalThis.__DEV__ !== !1 && !c.complete && !this.options.partialRefetch && !a.loading && !a.data && !a.error && Bi(c.missing);
        }
      return r && this.updateLastResult(a), a;
    }, e.prototype.isDifferentFromLastResult = function(r, n) {
      if (!this.last)
        return !0;
      var i = this.queryManager.getDocumentInfo(this.query).hasNonreactiveDirective ? !Ri(this.query, this.last.result, r, this.variables) : !M(this.last.result, r);
      return i || n && !M(this.last.variables, n);
    }, e.prototype.getLast = function(r, n) {
      var i = this.last;
      if (i && i[r] && (!n || M(i.variables, this.variables)))
        return i[r];
    }, e.prototype.getLastResult = function(r) {
      return this.getLast("result", r);
    }, e.prototype.getLastError = function(r) {
      return this.getLast("error", r);
    }, e.prototype.resetLastResults = function() {
      delete this.last, this.isTornDown = !1;
    }, e.prototype.resetQueryStoreErrors = function() {
      this.queryManager.resetErrors(this.queryId);
    }, e.prototype.refetch = function(r) {
      var n, i = {
        // Always disable polling for refetches.
        pollInterval: 0
      }, a = this.options.fetchPolicy;
      if (a === "cache-and-network" ? i.fetchPolicy = a : a === "no-cache" ? i.fetchPolicy = "no-cache" : i.fetchPolicy = "network-only", globalThis.__DEV__ !== !1 && r && Ls.call(r, "variables")) {
        var o = Kn(this.query), s = o.variableDefinitions;
        (!s || !s.some(function(c) {
          return c.variable.name.value === "variables";
        })) && globalThis.__DEV__ !== !1 && I.warn(
          20,
          r,
          ((n = o.name) === null || n === void 0 ? void 0 : n.value) || o
        );
      }
      return r && !M(this.options.variables, r) && (i.variables = this.options.variables = d(d({}, this.options.variables), r)), this.queryInfo.resetLastWrite(), this.reobserve(i, P.refetch);
    }, e.prototype.fetchMore = function(r) {
      var n = this, i = d(d({}, r.query ? r : d(d(d(d({}, this.options), { query: this.options.query }), r), { variables: d(d({}, this.options.variables), r.variables) })), {
        // The fetchMore request goes immediately to the network and does
        // not automatically write its result to the cache (hence no-cache
        // instead of network-only), because we allow the caller of
        // fetchMore to provide an updateQuery callback that determines how
        // the data gets written to the cache.
        fetchPolicy: "no-cache"
      });
      i.query = this.transformDocument(i.query);
      var a = this.queryManager.generateQueryId();
      this.lastQuery = r.query ? this.transformDocument(this.options.query) : i.query;
      var o = this.queryInfo, s = o.networkStatus;
      o.networkStatus = P.fetchMore, i.notifyOnNetworkStatusChange && this.observe();
      var c = /* @__PURE__ */ new Set();
      return this.queryManager.fetchQuery(a, i, P.fetchMore).then(function(u) {
        return n.queryManager.removeQuery(a), o.networkStatus === P.fetchMore && (o.networkStatus = s), n.queryManager.cache.batch({
          update: function(f) {
            var h = r.updateQuery;
            h ? f.updateQuery({
              query: n.query,
              variables: n.variables,
              returnPartialData: !0,
              optimistic: !1
            }, function(l) {
              return h(l, {
                fetchMoreResult: u.data,
                variables: i.variables
              });
            }) : f.writeQuery({
              query: i.query,
              variables: i.variables,
              data: u.data
            });
          },
          onWatchUpdated: function(f) {
            c.add(f.query);
          }
        }), u;
      }).finally(function() {
        c.has(n.query) || Wi(n);
      });
    }, e.prototype.subscribeToMore = function(r) {
      var n = this, i = this.queryManager.startGraphQLSubscription({
        query: r.document,
        variables: r.variables,
        context: r.context
      }).subscribe({
        next: function(a) {
          var o = r.updateQuery;
          o && n.updateQuery(function(s, c) {
            var u = c.variables;
            return o(s, {
              subscriptionData: a,
              variables: u
            });
          });
        },
        error: function(a) {
          if (r.onError) {
            r.onError(a);
            return;
          }
          globalThis.__DEV__ !== !1 && I.error(21, a);
        }
      });
      return this.subscriptions.add(i), function() {
        n.subscriptions.delete(i) && i.unsubscribe();
      };
    }, e.prototype.setOptions = function(r) {
      return this.reobserve(r);
    }, e.prototype.silentSetOptions = function(r) {
      var n = Ie(this.options, r || {});
      En(this.options, n);
    }, e.prototype.setVariables = function(r) {
      return M(this.variables, r) ? this.observers.size ? this.result() : Promise.resolve() : (this.options.variables = r, this.observers.size ? this.reobserve({
        // Reset options.fetchPolicy to its original value.
        fetchPolicy: this.options.initialFetchPolicy,
        variables: r
      }, P.setVariables) : Promise.resolve());
    }, e.prototype.updateQuery = function(r) {
      var n = this.queryManager, i = n.cache.diff({
        query: this.options.query,
        variables: this.variables,
        returnPartialData: !0,
        optimistic: !1
      }).result, a = r(i, {
        variables: this.variables
      });
      a && (n.cache.writeQuery({
        query: this.options.query,
        data: a,
        variables: this.variables
      }), n.broadcastQueries());
    }, e.prototype.startPolling = function(r) {
      this.options.pollInterval = r, this.updatePolling();
    }, e.prototype.stopPolling = function() {
      this.options.pollInterval = 0, this.updatePolling();
    }, e.prototype.applyNextFetchPolicy = function(r, n) {
      if (n.nextFetchPolicy) {
        var i = n.fetchPolicy, a = i === void 0 ? "cache-first" : i, o = n.initialFetchPolicy, s = o === void 0 ? a : o;
        a === "standby" || (typeof n.nextFetchPolicy == "function" ? n.fetchPolicy = n.nextFetchPolicy(a, {
          reason: r,
          options: n,
          observable: this,
          initialFetchPolicy: s
        }) : r === "variables-changed" ? n.fetchPolicy = s : n.fetchPolicy = n.nextFetchPolicy);
      }
      return n.fetchPolicy;
    }, e.prototype.fetch = function(r, n, i) {
      return this.queryManager.setObservableQuery(this), this.queryManager.fetchConcastWithInfo(this.queryId, r, n, i);
    }, e.prototype.updatePolling = function() {
      var r = this;
      if (!this.queryManager.ssrMode) {
        var n = this, i = n.pollingInfo, a = n.options.pollInterval;
        if (!a || !this.hasObservers()) {
          i && (clearTimeout(i.timeout), delete this.pollingInfo);
          return;
        }
        if (!(i && i.interval === a)) {
          I(a, 22);
          var o = i || (this.pollingInfo = {});
          o.interval = a;
          var s = function() {
            var u, f;
            r.pollingInfo && (!We(r.queryInfo.networkStatus) && !(!((f = (u = r.options).skipPollAttempt) === null || f === void 0) && f.call(u)) ? r.reobserve({
              // Most fetchPolicy options don't make sense to use in a polling context, as
              // users wouldn't want to be polling the cache directly. However, network-only and
              // no-cache are both useful for when the user wants to control whether or not the
              // polled results are written to the cache.
              fetchPolicy: r.options.initialFetchPolicy === "no-cache" ? "no-cache" : "network-only"
            }, P.poll).then(c, c) : c());
          }, c = function() {
            var u = r.pollingInfo;
            u && (clearTimeout(u.timeout), u.timeout = setTimeout(s, u.interval));
          };
          c();
        }
      }
    }, e.prototype.updateLastResult = function(r, n) {
      n === void 0 && (n = this.variables);
      var i = this.getLastError();
      return i && this.last && !M(n, this.last.variables) && (i = void 0), this.last = d({ result: this.queryManager.assumeImmutableResults ? r : gi(r), variables: n }, i ? { error: i } : null);
    }, e.prototype.reobserveAsConcast = function(r, n) {
      var i = this;
      this.isTornDown = !1;
      var a = (
        // Refetching uses a disposable Concast to allow refetches using different
        // options/variables, without permanently altering the options of the
        // original ObservableQuery.
        n === P.refetch || // The fetchMore method does not actually call the reobserve method, but,
        // if it did, it would definitely use a disposable Concast.
        n === P.fetchMore || // Polling uses a disposable Concast so the polling options (which force
        // fetchPolicy to be "network-only" or "no-cache") won't override the original options.
        n === P.poll
      ), o = this.options.variables, s = this.options.fetchPolicy, c = Ie(this.options, r || {}), u = a ? (
        // Disposable Concast fetches receive a shallow copy of this.options
        // (merged with newOptions), leaving this.options unmodified.
        c
      ) : En(this.options, c), f = this.transformDocument(u.query);
      this.lastQuery = f, a || (this.updatePolling(), r && r.variables && !M(r.variables, o) && // Don't mess with the fetchPolicy if it's currently "standby".
      u.fetchPolicy !== "standby" && // If we're changing the fetchPolicy anyway, don't try to change it here
      // using applyNextFetchPolicy. The explicit options.fetchPolicy wins.
      u.fetchPolicy === s && (this.applyNextFetchPolicy("variables-changed", u), n === void 0 && (n = P.setVariables))), this.waitForOwnResult && (this.waitForOwnResult = Nt(u.fetchPolicy));
      var h = function() {
        i.concast === y && (i.waitForOwnResult = !1);
      }, l = u.variables && d({}, u.variables), p = this.fetch(u, n, f), y = p.concast, m = p.fromLink, b = {
        next: function(v) {
          M(i.variables, l) && (h(), i.reportResult(v, l));
        },
        error: function(v) {
          M(i.variables, l) && (h(), i.reportError(v, l));
        }
      };
      return !a && (m || !this.concast) && (this.concast && this.observer && this.concast.removeObserver(this.observer), this.concast = y, this.observer = b), y.addObserver(b), y;
    }, e.prototype.reobserve = function(r, n) {
      return this.reobserveAsConcast(r, n).promise;
    }, e.prototype.resubscribeAfterError = function() {
      for (var r = [], n = 0; n < arguments.length; n++)
        r[n] = arguments[n];
      var i = this.last;
      this.resetLastResults();
      var a = this.subscribe.apply(this, r);
      return this.last = i, a;
    }, e.prototype.observe = function() {
      this.reportResult(
        // Passing false is important so that this.getCurrentResult doesn't
        // save the fetchMore result as this.lastResult, causing it to be
        // ignored due to the this.isDifferentFromLastResult check in
        // this.reportResult.
        this.getCurrentResult(!1),
        this.variables
      );
    }, e.prototype.reportResult = function(r, n) {
      var i = this.getLastError(), a = this.isDifferentFromLastResult(r, n);
      (i || !r.partial || this.options.returnPartialData) && this.updateLastResult(r, n), (i || a) && Ce(this.observers, "next", r);
    }, e.prototype.reportError = function(r, n) {
      var i = d(d({}, this.getLastResult()), { error: r, errors: r.graphQLErrors, networkStatus: P.error, loading: !1 });
      this.updateLastResult(i, n), Ce(this.observers, "error", this.last.error = r);
    }, e.prototype.hasObservers = function() {
      return this.observers.size > 0;
    }, e.prototype.tearDownQuery = function() {
      this.isTornDown || (this.concast && this.observer && (this.concast.removeObserver(this.observer), delete this.concast, delete this.observer), this.stopPolling(), this.subscriptions.forEach(function(r) {
        return r.unsubscribe();
      }), this.subscriptions.clear(), this.queryManager.stopQuery(this.queryId), this.observers.clear(), this.isTornDown = !0);
    }, e.prototype.transformDocument = function(r) {
      return this.queryManager.transform(r);
    }, e;
  }(A)
);
bi(Zt);
function Wi(t) {
  var e = t.options, r = e.fetchPolicy, n = e.nextFetchPolicy;
  return r === "cache-and-network" || r === "network-only" ? t.reobserve({
    fetchPolicy: "cache-first",
    // Use a temporary nextFetchPolicy function that replaces itself with the
    // previous nextFetchPolicy value and returns the original fetchPolicy.
    nextFetchPolicy: function(i, a) {
      return this.nextFetchPolicy = n, typeof this.nextFetchPolicy == "function" ? this.nextFetchPolicy(i, a) : r;
    }
  }) : t.reobserve();
}
function xs(t) {
  globalThis.__DEV__ !== !1 && I.error(23, t.message, t.stack);
}
function Bi(t) {
  globalThis.__DEV__ !== !1 && t && globalThis.__DEV__ !== !1 && I.debug(24, t);
}
function Nt(t) {
  return t === "network-only" || t === "no-cache" || t === "standby";
}
var Gi = (
  /** @class */
  function() {
    function t(e) {
      var r = e.cache, n = e.client, i = e.resolvers, a = e.fragmentMatcher;
      this.selectionsToResolveCache = /* @__PURE__ */ new WeakMap(), this.cache = r, n && (this.client = n), i && this.addResolvers(i), a && this.setFragmentMatcher(a);
    }
    return t.prototype.addResolvers = function(e) {
      var r = this;
      this.resolvers = this.resolvers || {}, Array.isArray(e) ? e.forEach(function(n) {
        r.resolvers = Ur(r.resolvers, n);
      }) : this.resolvers = Ur(this.resolvers, e);
    }, t.prototype.setResolvers = function(e) {
      this.resolvers = {}, this.addResolvers(e);
    }, t.prototype.getResolvers = function() {
      return this.resolvers || {};
    }, t.prototype.runResolvers = function(e) {
      return te(this, arguments, void 0, function(r) {
        var n = r.document, i = r.remoteResult, a = r.context, o = r.variables, s = r.onlyRunForcedResolvers, c = s === void 0 ? !1 : s;
        return re(this, function(u) {
          return n ? [2, this.resolveDocument(n, i.data, a, o, this.fragmentMatcher, c).then(function(f) {
            return d(d({}, i), { data: f.result });
          })] : [2, i];
        });
      });
    }, t.prototype.setFragmentMatcher = function(e) {
      this.fragmentMatcher = e;
    }, t.prototype.getFragmentMatcher = function() {
      return this.fragmentMatcher;
    }, t.prototype.clientQuery = function(e) {
      return je(["client"], e) && this.resolvers ? e : null;
    }, t.prototype.serverQuery = function(e) {
      return di(e);
    }, t.prototype.prepareContext = function(e) {
      var r = this.cache;
      return d(d({}, e), {
        cache: r,
        // Getting an entry's cache key is useful for local state resolvers.
        getCacheKey: function(n) {
          return r.identify(n);
        }
      });
    }, t.prototype.addExportedVariables = function(e) {
      return te(this, arguments, void 0, function(r, n, i) {
        return n === void 0 && (n = {}), i === void 0 && (i = {}), re(this, function(a) {
          return r ? [2, this.resolveDocument(r, this.buildRootValueFromCache(r, n) || {}, this.prepareContext(i), n).then(function(o) {
            return d(d({}, n), o.exportedVariables);
          })] : [2, d({}, n)];
        });
      });
    }, t.prototype.shouldForceResolvers = function(e) {
      var r = !1;
      return K(e, {
        Directive: {
          enter: function(n) {
            if (n.name.value === "client" && n.arguments && (r = n.arguments.some(function(i) {
              return i.name.value === "always" && i.value.kind === "BooleanValue" && i.value.value === !0;
            }), r))
              return ar;
          }
        }
      }), r;
    }, t.prototype.buildRootValueFromCache = function(e, r) {
      return this.cache.diff({
        query: _o(e),
        variables: r,
        returnPartialData: !0,
        optimistic: !1
      }).result;
    }, t.prototype.resolveDocument = function(e, r) {
      return te(this, arguments, void 0, function(n, i, a, o, s, c) {
        var u, f, h, l, p, y, m, b, v, E, g;
        return a === void 0 && (a = {}), o === void 0 && (o = {}), s === void 0 && (s = function() {
          return !0;
        }), c === void 0 && (c = !1), re(this, function(S) {
          return u = $e(n), f = ht(n), h = ct(f), l = this.collectSelectionsToResolve(u, h), p = u.operation, y = p ? p.charAt(0).toUpperCase() + p.slice(1) : "Query", m = this, b = m.cache, v = m.client, E = {
            fragmentMap: h,
            context: d(d({}, a), { cache: b, client: v }),
            variables: o,
            fragmentMatcher: s,
            defaultOperationType: y,
            exportedVariables: {},
            selectionsToResolve: l,
            onlyRunForcedResolvers: c
          }, g = !1, [2, this.resolveSelectionSet(u.selectionSet, g, i, E).then(function(O) {
            return {
              result: O,
              exportedVariables: E.exportedVariables
            };
          })];
        });
      });
    }, t.prototype.resolveSelectionSet = function(e, r, n, i) {
      return te(this, void 0, void 0, function() {
        var a, o, s, c, u, f = this;
        return re(this, function(h) {
          return a = i.fragmentMap, o = i.context, s = i.variables, c = [n], u = function(l) {
            return te(f, void 0, void 0, function() {
              var p, y;
              return re(this, function(m) {
                return !r && !i.selectionsToResolve.has(l) ? [
                  2
                  /*return*/
                ] : Be(l, s) ? se(l) ? [2, this.resolveField(l, r, n, i).then(function(b) {
                  var v;
                  typeof b < "u" && c.push((v = {}, v[oe(l)] = b, v));
                })] : (ro(l) ? p = l : (p = a[l.name.value], I(p, 18, l.name.value)), p && p.typeCondition && (y = p.typeCondition.name.value, i.fragmentMatcher(n, y, o)) ? [2, this.resolveSelectionSet(p.selectionSet, r, n, i).then(function(b) {
                  c.push(b);
                })] : [
                  2
                  /*return*/
                ]) : [
                  2
                  /*return*/
                ];
              });
            });
          }, [2, Promise.all(e.selections.map(u)).then(function() {
            return yt(c);
          })];
        });
      });
    }, t.prototype.resolveField = function(e, r, n, i) {
      return te(this, void 0, void 0, function() {
        var a, o, s, c, u, f, h, l, p, y = this;
        return re(this, function(m) {
          return n ? (a = i.variables, o = e.name.value, s = oe(e), c = o !== s, u = n[s] || n[o], f = Promise.resolve(u), (!i.onlyRunForcedResolvers || this.shouldForceResolvers(e)) && (h = n.__typename || i.defaultOperationType, l = this.resolvers && this.resolvers[h], l && (p = l[c ? o : s], p && (f = Promise.resolve(
            // In case the resolve function accesses reactive variables,
            // set cacheSlot to the current cache instance.
            mr.withValue(this.cache, p, [
              n,
              lt(e, a),
              i.context,
              { field: e, fragmentMap: i.fragmentMap }
            ])
          )))), [2, f.then(function(b) {
            var v, E;
            if (b === void 0 && (b = u), e.directives && e.directives.forEach(function(S) {
              S.name.value === "export" && S.arguments && S.arguments.forEach(function(O) {
                O.name.value === "as" && O.value.kind === "StringValue" && (i.exportedVariables[O.value.value] = b);
              });
            }), !e.selectionSet || b == null)
              return b;
            var g = (E = (v = e.directives) === null || v === void 0 ? void 0 : v.some(function(S) {
              return S.name.value === "client";
            })) !== null && E !== void 0 ? E : !1;
            if (Array.isArray(b))
              return y.resolveSubSelectedArray(e, r || g, b, i);
            if (e.selectionSet)
              return y.resolveSelectionSet(e.selectionSet, r || g, b, i);
          })]) : [2, null];
        });
      });
    }, t.prototype.resolveSubSelectedArray = function(e, r, n, i) {
      var a = this;
      return Promise.all(n.map(function(o) {
        if (o === null)
          return null;
        if (Array.isArray(o))
          return a.resolveSubSelectedArray(e, r, o, i);
        if (e.selectionSet)
          return a.resolveSelectionSet(e.selectionSet, r, o, i);
      }));
    }, t.prototype.collectSelectionsToResolve = function(e, r) {
      var n = function(o) {
        return !Array.isArray(o);
      }, i = this.selectionsToResolveCache;
      function a(o) {
        if (!i.has(o)) {
          var s = /* @__PURE__ */ new Set();
          i.set(o, s), K(o, {
            Directive: function(c, u, f, h, l) {
              c.name.value === "client" && l.forEach(function(p) {
                n(p) && Fr(p) && s.add(p);
              });
            },
            FragmentSpread: function(c, u, f, h, l) {
              var p = r[c.name.value];
              I(p, 19, c.name.value);
              var y = a(p);
              y.size > 0 && (l.forEach(function(m) {
                n(m) && Fr(m) && s.add(m);
              }), s.add(c), y.forEach(function(m) {
                s.add(m);
              }));
            }
          });
        }
        return i.get(o);
      }
      return a(e);
    }, t;
  }()
), be = new (ke ? WeakMap : Map)();
function At(t, e) {
  var r = t[e];
  typeof r == "function" && (t[e] = function() {
    return be.set(
      t,
      // The %1e15 allows the count to wrap around to 0 safely every
      // quadrillion evictions, so there's no risk of overflow. To be
      // clear, this is more of a pedantic principle than something
      // that matters in any conceivable practical scenario.
      (be.get(t) + 1) % 1e15
    ), r.apply(this, arguments);
  });
}
function On(t) {
  t.notifyTimeout && (clearTimeout(t.notifyTimeout), t.notifyTimeout = void 0);
}
var Ft = (
  /** @class */
  function() {
    function t(e, r) {
      r === void 0 && (r = e.generateQueryId()), this.queryId = r, this.listeners = /* @__PURE__ */ new Set(), this.document = null, this.lastRequestId = 1, this.stopped = !1, this.dirty = !1, this.observableQuery = null;
      var n = this.cache = e.cache;
      be.has(n) || (be.set(n, 0), At(n, "evict"), At(n, "modify"), At(n, "reset"));
    }
    return t.prototype.init = function(e) {
      var r = e.networkStatus || P.loading;
      return this.variables && this.networkStatus !== P.loading && !M(this.variables, e.variables) && (r = P.setVariables), M(e.variables, this.variables) || (this.lastDiff = void 0), Object.assign(this, {
        document: e.document,
        variables: e.variables,
        networkError: null,
        graphQLErrors: this.graphQLErrors || [],
        networkStatus: r
      }), e.observableQuery && this.setObservableQuery(e.observableQuery), e.lastRequestId && (this.lastRequestId = e.lastRequestId), this;
    }, t.prototype.reset = function() {
      On(this), this.dirty = !1;
    }, t.prototype.resetDiff = function() {
      this.lastDiff = void 0;
    }, t.prototype.getDiff = function() {
      var e = this.getDiffOptions();
      if (this.lastDiff && M(e, this.lastDiff.options))
        return this.lastDiff.diff;
      this.updateWatch(this.variables);
      var r = this.observableQuery;
      if (r && r.options.fetchPolicy === "no-cache")
        return { complete: !1 };
      var n = this.cache.diff(e);
      return this.updateLastDiff(n, e), n;
    }, t.prototype.updateLastDiff = function(e, r) {
      this.lastDiff = e ? {
        diff: e,
        options: r || this.getDiffOptions()
      } : void 0;
    }, t.prototype.getDiffOptions = function(e) {
      var r;
      return e === void 0 && (e = this.variables), {
        query: this.document,
        variables: e,
        returnPartialData: !0,
        optimistic: !0,
        canonizeResults: (r = this.observableQuery) === null || r === void 0 ? void 0 : r.options.canonizeResults
      };
    }, t.prototype.setDiff = function(e) {
      var r = this, n, i = this.lastDiff && this.lastDiff.diff;
      e && !e.complete && (!((n = this.observableQuery) === null || n === void 0) && n.getLastError()) || (this.updateLastDiff(e), !this.dirty && !M(i && i.result, e && e.result) && (this.dirty = !0, this.notifyTimeout || (this.notifyTimeout = setTimeout(function() {
        return r.notify();
      }, 0))));
    }, t.prototype.setObservableQuery = function(e) {
      var r = this;
      e !== this.observableQuery && (this.oqListener && this.listeners.delete(this.oqListener), this.observableQuery = e, e ? (e.queryInfo = this, this.listeners.add(this.oqListener = function() {
        var n = r.getDiff();
        n.fromOptimisticTransaction ? e.observe() : Wi(e);
      })) : delete this.oqListener);
    }, t.prototype.notify = function() {
      var e = this;
      On(this), this.shouldNotify() && this.listeners.forEach(function(r) {
        return r(e);
      }), this.dirty = !1;
    }, t.prototype.shouldNotify = function() {
      if (!this.dirty || !this.listeners.size)
        return !1;
      if (We(this.networkStatus) && this.observableQuery) {
        var e = this.observableQuery.options.fetchPolicy;
        if (e !== "cache-only" && e !== "cache-and-network")
          return !1;
      }
      return !0;
    }, t.prototype.stop = function() {
      if (!this.stopped) {
        this.stopped = !0, this.reset(), this.cancel(), this.cancel = t.prototype.cancel;
        var e = this.observableQuery;
        e && e.stopPolling();
      }
    }, t.prototype.cancel = function() {
    }, t.prototype.updateWatch = function(e) {
      var r = this;
      e === void 0 && (e = this.variables);
      var n = this.observableQuery;
      if (!(n && n.options.fetchPolicy === "no-cache")) {
        var i = d(d({}, this.getDiffOptions(e)), { watcher: this, callback: function(a) {
          return r.setDiff(a);
        } });
        (!this.lastWatch || !M(i, this.lastWatch)) && (this.cancel(), this.cancel = this.cache.watch(this.lastWatch = i));
      }
    }, t.prototype.resetLastWrite = function() {
      this.lastWrite = void 0;
    }, t.prototype.shouldWrite = function(e, r) {
      var n = this.lastWrite;
      return !(n && // If cache.evict has been called since the last time we wrote this
      // data into the cache, there's a chance writing this result into
      // the cache will repair what was evicted.
      n.dmCount === be.get(this.cache) && M(r, n.variables) && M(e.data, n.result.data));
    }, t.prototype.markResult = function(e, r, n, i) {
      var a = this, o = new ce(), s = X(e.errors) ? e.errors.slice(0) : [];
      if (this.reset(), "incremental" in e && X(e.incremental)) {
        var c = Ei(this.getDiff().result, e);
        e.data = c;
      } else if ("hasNext" in e && e.hasNext) {
        var u = this.getDiff();
        e.data = o.merge(u.result, e.data);
      }
      this.graphQLErrors = s, n.fetchPolicy === "no-cache" ? this.updateLastDiff({ result: e.data, complete: !0 }, this.getDiffOptions(n.variables)) : i !== 0 && (er(e, n.errorPolicy) ? this.cache.performTransaction(function(f) {
        if (a.shouldWrite(e, n.variables))
          f.writeQuery({
            query: r,
            data: e.data,
            variables: n.variables,
            overwrite: i === 1
          }), a.lastWrite = {
            result: e,
            variables: n.variables,
            dmCount: be.get(a.cache)
          };
        else if (a.lastDiff && a.lastDiff.diff.complete) {
          e.data = a.lastDiff.diff.result;
          return;
        }
        var h = a.getDiffOptions(n.variables), l = f.diff(h);
        !a.stopped && M(a.variables, n.variables) && a.updateWatch(n.variables), a.updateLastDiff(l, h), l.complete && (e.data = l.result);
      }) : this.lastWrite = void 0);
    }, t.prototype.markReady = function() {
      return this.networkError = null, this.networkStatus = P.ready;
    }, t.prototype.markError = function(e) {
      return this.networkStatus = P.error, this.lastWrite = void 0, this.reset(), e.graphQLErrors && (this.graphQLErrors = e.graphQLErrors), e.networkError && (this.networkError = e.networkError), e;
    }, t;
  }()
);
function er(t, e) {
  e === void 0 && (e = "none");
  var r = e === "ignore" || e === "all", n = !tt(t);
  return !n && r && t.data && (n = !0), n;
}
var qs = Object.prototype.hasOwnProperty, Sn = /* @__PURE__ */ Object.create(null), js = (
  /** @class */
  function() {
    function t(e) {
      var r = e.cache, n = e.link, i = e.defaultOptions, a = e.documentTransform, o = e.queryDeduplication, s = o === void 0 ? !1 : o, c = e.onBroadcast, u = e.ssrMode, f = u === void 0 ? !1 : u, h = e.clientAwareness, l = h === void 0 ? {} : h, p = e.localState, y = e.assumeImmutableResults, m = y === void 0 ? !!r.assumeImmutableResults : y, b = e.defaultContext, v = this;
      this.clientAwareness = {}, this.queries = /* @__PURE__ */ new Map(), this.fetchCancelFns = /* @__PURE__ */ new Map(), this.transformCache = new Gn(
        Z["queryManager.getDocumentInfo"] || 2e3
        /* defaultCacheSizes["queryManager.getDocumentInfo"] */
      ), this.queryIdCounter = 1, this.requestIdCounter = 1, this.mutationIdCounter = 1, this.inFlightLinkObservables = new De(!1);
      var E = new li(
        function(g) {
          return v.cache.transformDocument(g);
        },
        // Allow the apollo cache to manage its own transform caches
        { cache: !1 }
      );
      this.cache = r, this.link = n, this.defaultOptions = i || /* @__PURE__ */ Object.create(null), this.queryDeduplication = s, this.clientAwareness = l, this.localState = p || new Gi({ cache: r }), this.ssrMode = f, this.assumeImmutableResults = m, this.documentTransform = a ? E.concat(a).concat(E) : E, this.defaultContext = b || /* @__PURE__ */ Object.create(null), (this.onBroadcast = c) && (this.mutationStore = /* @__PURE__ */ Object.create(null));
    }
    return t.prototype.stop = function() {
      var e = this;
      this.queries.forEach(function(r, n) {
        e.stopQueryNoBroadcast(n);
      }), this.cancelPendingFetches(U(25));
    }, t.prototype.cancelPendingFetches = function(e) {
      this.fetchCancelFns.forEach(function(r) {
        return r(e);
      }), this.fetchCancelFns.clear();
    }, t.prototype.mutate = function(e) {
      return te(this, arguments, void 0, function(r) {
        var n, i, a, o, s, c, u, f = r.mutation, h = r.variables, l = r.optimisticResponse, p = r.updateQueries, y = r.refetchQueries, m = y === void 0 ? [] : y, b = r.awaitRefetchQueries, v = b === void 0 ? !1 : b, E = r.update, g = r.onQueryUpdated, S = r.fetchPolicy, O = S === void 0 ? ((c = this.defaultOptions.mutate) === null || c === void 0 ? void 0 : c.fetchPolicy) || "network-only" : S, _ = r.errorPolicy, T = _ === void 0 ? ((u = this.defaultOptions.mutate) === null || u === void 0 ? void 0 : u.errorPolicy) || "none" : _, D = r.keepRootFields, k = r.context;
        return re(this, function(N) {
          switch (N.label) {
            case 0:
              return I(f, 26), I(O === "network-only" || O === "no-cache", 27), n = this.generateMutationId(), f = this.cache.transformForLink(this.transform(f)), i = this.getDocumentInfo(f).hasClientExports, h = this.getVariables(f, h), i ? [4, this.localState.addExportedVariables(f, h, k)] : [3, 2];
            case 1:
              h = N.sent(), N.label = 2;
            case 2:
              return a = this.mutationStore && (this.mutationStore[n] = {
                mutation: f,
                variables: h,
                loading: !0,
                error: null
              }), o = l && this.markMutationOptimistic(l, {
                mutationId: n,
                document: f,
                variables: h,
                fetchPolicy: O,
                errorPolicy: T,
                context: k,
                updateQueries: p,
                update: E,
                keepRootFields: D
              }), this.broadcastQueries(), s = this, [2, new Promise(function(C, Y) {
                return wt(s.getObservableFromLink(f, d(d({}, k), { optimisticResponse: o ? l : void 0 }), h, !1), function(q) {
                  if (tt(q) && T === "none")
                    throw new de({
                      graphQLErrors: Gt(q)
                    });
                  a && (a.loading = !1, a.error = null);
                  var J = d({}, q);
                  return typeof m == "function" && (m = m(J)), T === "ignore" && tt(J) && delete J.errors, s.markMutationResult({
                    mutationId: n,
                    result: J,
                    document: f,
                    variables: h,
                    fetchPolicy: O,
                    errorPolicy: T,
                    context: k,
                    update: E,
                    updateQueries: p,
                    awaitRefetchQueries: v,
                    refetchQueries: m,
                    removeOptimistic: o ? n : void 0,
                    onQueryUpdated: g,
                    keepRootFields: D
                  });
                }).subscribe({
                  next: function(q) {
                    s.broadcastQueries(), (!("hasNext" in q) || q.hasNext === !1) && C(q);
                  },
                  error: function(q) {
                    a && (a.loading = !1, a.error = q), o && s.cache.removeOptimistic(n), s.broadcastQueries(), Y(q instanceof de ? q : new de({
                      networkError: q
                    }));
                  }
                });
              })];
          }
        });
      });
    }, t.prototype.markMutationResult = function(e, r) {
      var n = this;
      r === void 0 && (r = this.cache);
      var i = e.result, a = [], o = e.fetchPolicy === "no-cache";
      if (!o && er(i, e.errorPolicy)) {
        if (Oe(i) || a.push({
          result: i.data,
          dataId: "ROOT_MUTATION",
          query: e.document,
          variables: e.variables
        }), Oe(i) && X(i.incremental)) {
          var s = r.diff({
            id: "ROOT_MUTATION",
            // The cache complains if passed a mutation where it expects a
            // query, so we transform mutations and subscriptions to queries
            // (only once, thanks to this.transformCache).
            query: this.getDocumentInfo(e.document).asQuery,
            variables: e.variables,
            optimistic: !1,
            returnPartialData: !0
          }), c = void 0;
          s.result && (c = Ei(s.result, i)), typeof c < "u" && (i.data = c, a.push({
            result: c,
            dataId: "ROOT_MUTATION",
            query: e.document,
            variables: e.variables
          }));
        }
        var u = e.updateQueries;
        u && this.queries.forEach(function(h, l) {
          var p = h.observableQuery, y = p && p.queryName;
          if (!(!y || !qs.call(u, y))) {
            var m = u[y], b = n.queries.get(l), v = b.document, E = b.variables, g = r.diff({
              query: v,
              variables: E,
              returnPartialData: !0,
              optimistic: !1
            }), S = g.result, O = g.complete;
            if (O && S) {
              var _ = m(S, {
                mutationResult: i,
                queryName: v && Qt(v) || void 0,
                queryVariables: E
              });
              _ && a.push({
                result: _,
                dataId: "ROOT_QUERY",
                query: v,
                variables: E
              });
            }
          }
        });
      }
      if (a.length > 0 || (e.refetchQueries || "").length > 0 || e.update || e.onQueryUpdated || e.removeOptimistic) {
        var f = [];
        if (this.refetchQueries({
          updateCache: function(h) {
            o || a.forEach(function(m) {
              return h.write(m);
            });
            var l = e.update, p = !qo(i) || Oe(i) && !i.hasNext;
            if (l) {
              if (!o) {
                var y = h.diff({
                  id: "ROOT_MUTATION",
                  // The cache complains if passed a mutation where it expects a
                  // query, so we transform mutations and subscriptions to queries
                  // (only once, thanks to this.transformCache).
                  query: n.getDocumentInfo(e.document).asQuery,
                  variables: e.variables,
                  optimistic: !1,
                  returnPartialData: !0
                });
                y.complete && (i = d(d({}, i), { data: y.result }), "incremental" in i && delete i.incremental, "hasNext" in i && delete i.hasNext);
              }
              p && l(h, i, {
                context: e.context,
                variables: e.variables
              });
            }
            !o && !e.keepRootFields && p && h.modify({
              id: "ROOT_MUTATION",
              fields: function(m, b) {
                var v = b.fieldName, E = b.DELETE;
                return v === "__typename" ? m : E;
              }
            });
          },
          include: e.refetchQueries,
          // Write the final mutation.result to the root layer of the cache.
          optimistic: !1,
          // Remove the corresponding optimistic layer at the same time as we
          // write the final non-optimistic result.
          removeOptimistic: e.removeOptimistic,
          // Let the caller of client.mutate optionally determine the refetching
          // behavior for watched queries after the mutation.update function runs.
          // If no onQueryUpdated function was provided for this mutation, pass
          // null instead of undefined to disable the default refetching behavior.
          onQueryUpdated: e.onQueryUpdated || null
        }).forEach(function(h) {
          return f.push(h);
        }), e.awaitRefetchQueries || e.onQueryUpdated)
          return Promise.all(f).then(function() {
            return i;
          });
      }
      return Promise.resolve(i);
    }, t.prototype.markMutationOptimistic = function(e, r) {
      var n = this, i = typeof e == "function" ? e(r.variables, { IGNORE: Sn }) : e;
      return i === Sn ? !1 : (this.cache.recordOptimisticTransaction(function(a) {
        try {
          n.markMutationResult(d(d({}, r), { result: { data: i } }), a);
        } catch (o) {
          globalThis.__DEV__ !== !1 && I.error(o);
        }
      }, r.mutationId), !0);
    }, t.prototype.fetchQuery = function(e, r, n) {
      return this.fetchConcastWithInfo(e, r, n).concast.promise;
    }, t.prototype.getQueryStore = function() {
      var e = /* @__PURE__ */ Object.create(null);
      return this.queries.forEach(function(r, n) {
        e[n] = {
          variables: r.variables,
          networkStatus: r.networkStatus,
          networkError: r.networkError,
          graphQLErrors: r.graphQLErrors
        };
      }), e;
    }, t.prototype.resetErrors = function(e) {
      var r = this.queries.get(e);
      r && (r.networkError = void 0, r.graphQLErrors = []);
    }, t.prototype.transform = function(e) {
      return this.documentTransform.transformDocument(e);
    }, t.prototype.getDocumentInfo = function(e) {
      var r = this.transformCache;
      if (!r.has(e)) {
        var n = {
          // TODO These three calls (hasClientExports, shouldForceResolvers, and
          // usesNonreactiveDirective) are performing independent full traversals
          // of the transformed document. We should consider merging these
          // traversals into a single pass in the future, though the work is
          // cached after the first time.
          hasClientExports: ba(e),
          hasForcedResolvers: this.localState.shouldForceResolvers(e),
          hasNonreactiveDirective: je(["nonreactive"], e),
          clientQuery: this.localState.clientQuery(e),
          serverQuery: pi([
            { name: "client", remove: !0 },
            { name: "connection" },
            { name: "nonreactive" }
          ], e),
          defaultVars: or(He(e)),
          // Transform any mutation or subscription operations to query operations
          // so we can read/write them from/to the cache.
          asQuery: d(d({}, e), { definitions: e.definitions.map(function(i) {
            return i.kind === "OperationDefinition" && i.operation !== "query" ? d(d({}, i), { operation: "query" }) : i;
          }) })
        };
        r.set(e, n);
      }
      return r.get(e);
    }, t.prototype.getVariables = function(e, r) {
      return d(d({}, this.getDocumentInfo(e).defaultVars), r);
    }, t.prototype.watchQuery = function(e) {
      var r = this.transform(e.query);
      e = d(d({}, e), { variables: this.getVariables(r, e.variables) }), typeof e.notifyOnNetworkStatusChange > "u" && (e.notifyOnNetworkStatusChange = !1);
      var n = new Ft(this), i = new Zt({
        queryManager: this,
        queryInfo: n,
        options: e
      });
      return i.lastQuery = r, this.queries.set(i.queryId, n), n.init({
        document: r,
        observableQuery: i,
        variables: i.variables
      }), i;
    }, t.prototype.query = function(e, r) {
      var n = this;
      return r === void 0 && (r = this.generateQueryId()), I(e.query, 28), I(e.query.kind === "Document", 29), I(!e.returnPartialData, 30), I(!e.pollInterval, 31), this.fetchQuery(r, d(d({}, e), { query: this.transform(e.query) })).finally(function() {
        return n.stopQuery(r);
      });
    }, t.prototype.generateQueryId = function() {
      return String(this.queryIdCounter++);
    }, t.prototype.generateRequestId = function() {
      return this.requestIdCounter++;
    }, t.prototype.generateMutationId = function() {
      return String(this.mutationIdCounter++);
    }, t.prototype.stopQueryInStore = function(e) {
      this.stopQueryInStoreNoBroadcast(e), this.broadcastQueries();
    }, t.prototype.stopQueryInStoreNoBroadcast = function(e) {
      var r = this.queries.get(e);
      r && r.stop();
    }, t.prototype.clearStore = function(e) {
      return e === void 0 && (e = {
        discardWatches: !0
      }), this.cancelPendingFetches(U(32)), this.queries.forEach(function(r) {
        r.observableQuery ? r.networkStatus = P.loading : r.stop();
      }), this.mutationStore && (this.mutationStore = /* @__PURE__ */ Object.create(null)), this.cache.reset(e);
    }, t.prototype.getObservableQueries = function(e) {
      var r = this;
      e === void 0 && (e = "active");
      var n = /* @__PURE__ */ new Map(), i = /* @__PURE__ */ new Map(), a = /* @__PURE__ */ new Set();
      return Array.isArray(e) && e.forEach(function(o) {
        typeof o == "string" ? i.set(o, !1) : Wa(o) ? i.set(r.transform(o), !1) : L(o) && o.query && a.add(o);
      }), this.queries.forEach(function(o, s) {
        var c = o.observableQuery, u = o.document;
        if (c) {
          if (e === "all") {
            n.set(s, c);
            return;
          }
          var f = c.queryName, h = c.options.fetchPolicy;
          if (h === "standby" || e === "active" && !c.hasObservers())
            return;
          (e === "active" || f && i.has(f) || u && i.has(u)) && (n.set(s, c), f && i.set(f, !0), u && i.set(u, !0));
        }
      }), a.size && a.forEach(function(o) {
        var s = Ct("legacyOneTimeQuery"), c = r.getQuery(s).init({
          document: o.query,
          variables: o.variables
        }), u = new Zt({
          queryManager: r,
          queryInfo: c,
          options: d(d({}, o), { fetchPolicy: "network-only" })
        });
        I(u.queryId === s), c.setObservableQuery(u), n.set(s, u);
      }), globalThis.__DEV__ !== !1 && i.size && i.forEach(function(o, s) {
        o || globalThis.__DEV__ !== !1 && I.warn(typeof s == "string" ? 33 : 34, s);
      }), n;
    }, t.prototype.reFetchObservableQueries = function(e) {
      var r = this;
      e === void 0 && (e = !1);
      var n = [];
      return this.getObservableQueries(e ? "all" : "active").forEach(function(i, a) {
        var o = i.options.fetchPolicy;
        i.resetLastResults(), (e || o !== "standby" && o !== "cache-only") && n.push(i.refetch()), r.getQuery(a).setDiff(null);
      }), this.broadcastQueries(), Promise.all(n);
    }, t.prototype.setObservableQuery = function(e) {
      this.getQuery(e.queryId).setObservableQuery(e);
    }, t.prototype.startGraphQLSubscription = function(e) {
      var r = this, n = e.query, i = e.fetchPolicy, a = e.errorPolicy, o = a === void 0 ? "none" : a, s = e.variables, c = e.context, u = c === void 0 ? {} : c;
      n = this.transform(n), s = this.getVariables(n, s);
      var f = function(l) {
        return r.getObservableFromLink(n, u, l).map(function(p) {
          i !== "no-cache" && (er(p, o) && r.cache.write({
            query: n,
            result: p.data,
            dataId: "ROOT_SUBSCRIPTION",
            variables: l
          }), r.broadcastQueries());
          var y = tt(p), m = ts(p);
          if (y || m) {
            var b = {};
            if (y && (b.graphQLErrors = p.errors), m && (b.protocolErrors = p.extensions[yr]), o === "none" || m)
              throw new de(b);
          }
          return o === "ignore" && delete p.errors, p;
        });
      };
      if (this.getDocumentInfo(n).hasClientExports) {
        var h = this.localState.addExportedVariables(n, s, u).then(f);
        return new A(function(l) {
          var p = null;
          return h.then(function(y) {
            return p = y.subscribe(l);
          }, l.error), function() {
            return p && p.unsubscribe();
          };
        });
      }
      return f(s);
    }, t.prototype.stopQuery = function(e) {
      this.stopQueryNoBroadcast(e), this.broadcastQueries();
    }, t.prototype.stopQueryNoBroadcast = function(e) {
      this.stopQueryInStoreNoBroadcast(e), this.removeQuery(e);
    }, t.prototype.removeQuery = function(e) {
      this.fetchCancelFns.delete(e), this.queries.has(e) && (this.getQuery(e).stop(), this.queries.delete(e));
    }, t.prototype.broadcastQueries = function() {
      this.onBroadcast && this.onBroadcast(), this.queries.forEach(function(e) {
        return e.notify();
      });
    }, t.prototype.getLocalState = function() {
      return this.localState;
    }, t.prototype.getObservableFromLink = function(e, r, n, i) {
      var a = this, o;
      i === void 0 && (i = (o = r == null ? void 0 : r.queryDeduplication) !== null && o !== void 0 ? o : this.queryDeduplication);
      var s, c = this.getDocumentInfo(e), u = c.serverQuery, f = c.clientQuery;
      if (u) {
        var h = this, l = h.inFlightLinkObservables, p = h.link, y = {
          query: u,
          variables: n,
          operationName: Qt(u) || void 0,
          context: this.prepareContext(d(d({}, r), { forceFetch: !i }))
        };
        if (r = y.context, i) {
          var m = vt(u), b = ie(n), v = l.lookup(m, b);
          if (s = v.observable, !s) {
            var E = new ye([
              Ht(p, y)
            ]);
            s = v.observable = E, E.beforeNext(function() {
              l.remove(m, b);
            });
          }
        } else
          s = new ye([
            Ht(p, y)
          ]);
      } else
        s = new ye([A.of({ data: {} })]), r = this.prepareContext(r);
      return f && (s = wt(s, function(g) {
        return a.localState.runResolvers({
          document: f,
          remoteResult: g,
          context: r,
          variables: n
        });
      })), s;
    }, t.prototype.getResultsFromLink = function(e, r, n) {
      var i = e.lastRequestId = this.generateRequestId(), a = this.cache.transformForLink(n.query);
      return wt(this.getObservableFromLink(a, n.context, n.variables), function(o) {
        var s = Gt(o), c = s.length > 0;
        if (i >= e.lastRequestId) {
          if (c && n.errorPolicy === "none")
            throw e.markError(new de({
              graphQLErrors: s
            }));
          e.markResult(o, a, n, r), e.markReady();
        }
        var u = {
          data: o.data,
          loading: !1,
          networkStatus: P.ready
        };
        return c && n.errorPolicy !== "ignore" && (u.errors = s, u.networkStatus = P.error), u;
      }, function(o) {
        var s = rs(o) ? o : new de({ networkError: o });
        throw i >= e.lastRequestId && e.markError(s), s;
      });
    }, t.prototype.fetchConcastWithInfo = function(e, r, n, i) {
      var a = this;
      n === void 0 && (n = P.loading), i === void 0 && (i = r.query);
      var o = this.getVariables(i, r.variables), s = this.getQuery(e), c = this.defaultOptions.watchQuery, u = r.fetchPolicy, f = u === void 0 ? c && c.fetchPolicy || "cache-first" : u, h = r.errorPolicy, l = h === void 0 ? c && c.errorPolicy || "none" : h, p = r.returnPartialData, y = p === void 0 ? !1 : p, m = r.notifyOnNetworkStatusChange, b = m === void 0 ? !1 : m, v = r.context, E = v === void 0 ? {} : v, g = Object.assign({}, r, {
        query: i,
        variables: o,
        fetchPolicy: f,
        errorPolicy: l,
        returnPartialData: y,
        notifyOnNetworkStatusChange: b,
        context: E
      }), S = function(k) {
        g.variables = k;
        var N = a.fetchQueryByPolicy(s, g, n);
        return (
          // If we're in standby, postpone advancing options.fetchPolicy using
          // applyNextFetchPolicy.
          g.fetchPolicy !== "standby" && // The "standby" policy currently returns [] from fetchQueryByPolicy, so
          // this is another way to detect when nothing was done/fetched.
          N.sources.length > 0 && s.observableQuery && s.observableQuery.applyNextFetchPolicy("after-fetch", r), N
        );
      }, O = function() {
        return a.fetchCancelFns.delete(e);
      };
      this.fetchCancelFns.set(e, function(k) {
        O(), setTimeout(function() {
          return _.cancel(k);
        });
      });
      var _, T;
      if (this.getDocumentInfo(g.query).hasClientExports)
        _ = new ye(this.localState.addExportedVariables(g.query, g.variables, g.context).then(S).then(function(k) {
          return k.sources;
        })), T = !0;
      else {
        var D = S(g.variables);
        T = D.fromLink, _ = new ye(D.sources);
      }
      return _.promise.then(O, O), {
        concast: _,
        fromLink: T
      };
    }, t.prototype.refetchQueries = function(e) {
      var r = this, n = e.updateCache, i = e.include, a = e.optimistic, o = a === void 0 ? !1 : a, s = e.removeOptimistic, c = s === void 0 ? o ? Ct("refetchQueries") : void 0 : s, u = e.onQueryUpdated, f = /* @__PURE__ */ new Map();
      i && this.getObservableQueries(i).forEach(function(l, p) {
        f.set(p, {
          oq: l,
          lastDiff: r.getQuery(p).getDiff()
        });
      });
      var h = /* @__PURE__ */ new Map();
      return n && this.cache.batch({
        update: n,
        // Since you can perform any combination of cache reads and/or writes in
        // the cache.batch update function, its optimistic option can be either
        // a boolean or a string, representing three distinct modes of
        // operation:
        //
        // * false: read/write only the root layer
        // * true: read/write the topmost layer
        // * string: read/write a fresh optimistic layer with that ID string
        //
        // When typeof optimistic === "string", a new optimistic layer will be
        // temporarily created within cache.batch with that string as its ID. If
        // we then pass that same string as the removeOptimistic option, we can
        // make cache.batch immediately remove the optimistic layer after
        // running the updateCache function, triggering only one broadcast.
        //
        // However, the refetchQueries method accepts only true or false for its
        // optimistic option (not string). We interpret true to mean a temporary
        // optimistic layer should be created, to allow efficiently rolling back
        // the effect of the updateCache function, which involves passing a
        // string instead of true as the optimistic option to cache.batch, when
        // refetchQueries receives optimistic: true.
        //
        // In other words, we are deliberately not supporting the use case of
        // writing to an *existing* optimistic layer (using the refetchQueries
        // updateCache function), since that would potentially interfere with
        // other optimistic updates in progress. Instead, you can read/write
        // only the root layer by passing optimistic: false to refetchQueries,
        // or you can read/write a brand new optimistic layer that will be
        // automatically removed by passing optimistic: true.
        optimistic: o && c || !1,
        // The removeOptimistic option can also be provided by itself, even if
        // optimistic === false, to remove some previously-added optimistic
        // layer safely and efficiently, like we do in markMutationResult.
        //
        // If an explicit removeOptimistic string is provided with optimistic:
        // true, the removeOptimistic string will determine the ID of the
        // temporary optimistic layer, in case that ever matters.
        removeOptimistic: c,
        onWatchUpdated: function(l, p, y) {
          var m = l.watcher instanceof Ft && l.watcher.observableQuery;
          if (m) {
            if (u) {
              f.delete(m.queryId);
              var b = u(m, p, y);
              return b === !0 && (b = m.refetch()), b !== !1 && h.set(m, b), b;
            }
            u !== null && f.set(m.queryId, { oq: m, lastDiff: y, diff: p });
          }
        }
      }), f.size && f.forEach(function(l, p) {
        var y = l.oq, m = l.lastDiff, b = l.diff, v;
        if (u) {
          if (!b) {
            var E = y.queryInfo;
            E.reset(), b = E.getDiff();
          }
          v = u(y, b, m);
        }
        (!u || v === !0) && (v = y.refetch()), v !== !1 && h.set(y, v), p.indexOf("legacyOneTimeQuery") >= 0 && r.stopQueryNoBroadcast(p);
      }), c && this.cache.removeOptimistic(c), h;
    }, t.prototype.fetchQueryByPolicy = function(e, r, n) {
      var i = this, a = r.query, o = r.variables, s = r.fetchPolicy, c = r.refetchWritePolicy, u = r.errorPolicy, f = r.returnPartialData, h = r.context, l = r.notifyOnNetworkStatusChange, p = e.networkStatus;
      e.init({
        document: a,
        variables: o,
        networkStatus: n
      });
      var y = function() {
        return e.getDiff();
      }, m = function(S, O) {
        O === void 0 && (O = e.networkStatus || P.loading);
        var _ = S.result;
        globalThis.__DEV__ !== !1 && !f && !M(_, {}) && Bi(S.missing);
        var T = function(D) {
          return A.of(d({ data: D, loading: We(O), networkStatus: O }, S.complete ? null : { partial: !0 }));
        };
        return _ && i.getDocumentInfo(a).hasForcedResolvers ? i.localState.runResolvers({
          document: a,
          remoteResult: { data: _ },
          context: h,
          variables: o,
          onlyRunForcedResolvers: !0
        }).then(function(D) {
          return T(D.data || void 0);
        }) : u === "none" && O === P.refetch && Array.isArray(S.missing) ? T(void 0) : T(_);
      }, b = s === "no-cache" ? 0 : n === P.refetch && c !== "merge" ? 1 : 2, v = function() {
        return i.getResultsFromLink(e, b, {
          query: a,
          variables: o,
          context: h,
          fetchPolicy: s,
          errorPolicy: u
        });
      }, E = l && typeof p == "number" && p !== n && We(n);
      switch (s) {
        default:
        case "cache-first": {
          var g = y();
          return g.complete ? {
            fromLink: !1,
            sources: [m(g, e.markReady())]
          } : f || E ? {
            fromLink: !0,
            sources: [m(g), v()]
          } : { fromLink: !0, sources: [v()] };
        }
        case "cache-and-network": {
          var g = y();
          return g.complete || f || E ? {
            fromLink: !0,
            sources: [m(g), v()]
          } : { fromLink: !0, sources: [v()] };
        }
        case "cache-only":
          return {
            fromLink: !1,
            sources: [m(y(), e.markReady())]
          };
        case "network-only":
          return E ? {
            fromLink: !0,
            sources: [m(y()), v()]
          } : { fromLink: !0, sources: [v()] };
        case "no-cache":
          return E ? {
            fromLink: !0,
            // Note that queryInfo.getDiff() for no-cache queries does not call
            // cache.diff, but instead returns a { complete: false } stub result
            // when there is no queryInfo.diff already defined.
            sources: [m(e.getDiff()), v()]
          } : { fromLink: !0, sources: [v()] };
        case "standby":
          return { fromLink: !1, sources: [] };
      }
    }, t.prototype.getQuery = function(e) {
      return e && !this.queries.has(e) && this.queries.set(e, new Ft(this, e)), this.queries.get(e);
    }, t.prototype.prepareContext = function(e) {
      e === void 0 && (e = {});
      var r = this.localState.prepareContext(e);
      return d(d(d({}, this.defaultContext), r), { clientAwareness: this.clientAwareness });
    }, t;
  }()
), _n = !1, Hi = (
  /** @class */
  function() {
    function t(e) {
      var r = this;
      if (this.resetStoreCallbacks = [], this.clearStoreCallbacks = [], !e.cache)
        throw U(15);
      var n = e.uri, i = e.credentials, a = e.headers, o = e.cache, s = e.documentTransform, c = e.ssrMode, u = c === void 0 ? !1 : c, f = e.ssrForceFetchDelay, h = f === void 0 ? 0 : f, l = e.connectToDevTools, p = l === void 0 ? typeof window == "object" && !window.__APOLLO_CLIENT__ && globalThis.__DEV__ !== !1 : l, y = e.queryDeduplication, m = y === void 0 ? !0 : y, b = e.defaultOptions, v = e.defaultContext, E = e.assumeImmutableResults, g = E === void 0 ? o.assumeImmutableResults : E, S = e.resolvers, O = e.typeDefs, _ = e.fragmentMatcher, T = e.name, D = e.version, k = e.link;
      k || (k = n ? new ds({ uri: n, credentials: i, headers: a }) : $.empty()), this.link = k, this.cache = o, this.disableNetworkFetches = u || h > 0, this.queryDeduplication = m, this.defaultOptions = b || /* @__PURE__ */ Object.create(null), this.typeDefs = O, h && setTimeout(function() {
        return r.disableNetworkFetches = !1;
      }, h), this.watchQuery = this.watchQuery.bind(this), this.query = this.query.bind(this), this.mutate = this.mutate.bind(this), this.watchFragment = this.watchFragment.bind(this), this.resetStore = this.resetStore.bind(this), this.reFetchObservableQueries = this.reFetchObservableQueries.bind(this), this.version = rr, this.localState = new Gi({
        cache: o,
        client: this,
        resolvers: S,
        fragmentMatcher: _
      }), this.queryManager = new js({
        cache: this.cache,
        link: this.link,
        defaultOptions: this.defaultOptions,
        defaultContext: v,
        documentTransform: s,
        queryDeduplication: m,
        ssrMode: u,
        clientAwareness: {
          name: T,
          version: D
        },
        localState: this.localState,
        assumeImmutableResults: g,
        onBroadcast: p ? function() {
          r.devToolsHookCb && r.devToolsHookCb({
            action: {},
            state: {
              queries: r.queryManager.getQueryStore(),
              mutations: r.queryManager.mutationStore || {}
            },
            dataWithOptimisticResults: r.cache.extract(!0)
          });
        } : void 0
      }), p && this.connectToDevTools();
    }
    return t.prototype.connectToDevTools = function() {
      if (typeof window == "object") {
        var e = window, r = Symbol.for("apollo.devtools");
        (e[r] = e[r] || []).push(this), e.__APOLLO_CLIENT__ = this;
      }
      !_n && globalThis.__DEV__ !== !1 && (_n = !0, setTimeout(function() {
        if (typeof window < "u" && window.document && window.top === window.self && !window.__APOLLO_DEVTOOLS_GLOBAL_HOOK__ && /^(https?|file):$/.test(window.location.protocol)) {
          var n = window.navigator, i = n && n.userAgent, a = void 0;
          typeof i == "string" && (i.indexOf("Chrome/") > -1 ? a = "https://chrome.google.com/webstore/detail/apollo-client-developer-t/jdkknkkbebbapilgoeccciglkfbmbnfm" : i.indexOf("Firefox/") > -1 && (a = "https://addons.mozilla.org/en-US/firefox/addon/apollo-developer-tools/")), a && globalThis.__DEV__ !== !1 && I.log("Download the Apollo DevTools for a better development experience: %s", a);
        }
      }, 1e4));
    }, Object.defineProperty(t.prototype, "documentTransform", {
      /**
       * The `DocumentTransform` used to modify GraphQL documents before a request
       * is made. If a custom `DocumentTransform` is not provided, this will be the
       * default document transform.
       */
      get: function() {
        return this.queryManager.documentTransform;
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype.stop = function() {
      this.queryManager.stop();
    }, t.prototype.watchQuery = function(e) {
      return this.defaultOptions.watchQuery && (e = Tt(this.defaultOptions.watchQuery, e)), this.disableNetworkFetches && (e.fetchPolicy === "network-only" || e.fetchPolicy === "cache-and-network") && (e = d(d({}, e), { fetchPolicy: "cache-first" })), this.queryManager.watchQuery(e);
    }, t.prototype.query = function(e) {
      return this.defaultOptions.query && (e = Tt(this.defaultOptions.query, e)), I(e.fetchPolicy !== "cache-and-network", 16), this.disableNetworkFetches && e.fetchPolicy === "network-only" && (e = d(d({}, e), { fetchPolicy: "cache-first" })), this.queryManager.query(e);
    }, t.prototype.mutate = function(e) {
      return this.defaultOptions.mutate && (e = Tt(this.defaultOptions.mutate, e)), this.queryManager.mutate(e);
    }, t.prototype.subscribe = function(e) {
      return this.queryManager.startGraphQLSubscription(e);
    }, t.prototype.readQuery = function(e, r) {
      return r === void 0 && (r = !1), this.cache.readQuery(e, r);
    }, t.prototype.watchFragment = function(e) {
      return this.cache.watchFragment(e);
    }, t.prototype.readFragment = function(e, r) {
      return r === void 0 && (r = !1), this.cache.readFragment(e, r);
    }, t.prototype.writeQuery = function(e) {
      var r = this.cache.writeQuery(e);
      return e.broadcast !== !1 && this.queryManager.broadcastQueries(), r;
    }, t.prototype.writeFragment = function(e) {
      var r = this.cache.writeFragment(e);
      return e.broadcast !== !1 && this.queryManager.broadcastQueries(), r;
    }, t.prototype.__actionHookForDevTools = function(e) {
      this.devToolsHookCb = e;
    }, t.prototype.__requestRaw = function(e) {
      return Ht(this.link, e);
    }, t.prototype.resetStore = function() {
      var e = this;
      return Promise.resolve().then(function() {
        return e.queryManager.clearStore({
          discardWatches: !1
        });
      }).then(function() {
        return Promise.all(e.resetStoreCallbacks.map(function(r) {
          return r();
        }));
      }).then(function() {
        return e.reFetchObservableQueries();
      });
    }, t.prototype.clearStore = function() {
      var e = this;
      return Promise.resolve().then(function() {
        return e.queryManager.clearStore({
          discardWatches: !0
        });
      }).then(function() {
        return Promise.all(e.clearStoreCallbacks.map(function(r) {
          return r();
        }));
      });
    }, t.prototype.onResetStore = function(e) {
      var r = this;
      return this.resetStoreCallbacks.push(e), function() {
        r.resetStoreCallbacks = r.resetStoreCallbacks.filter(function(n) {
          return n !== e;
        });
      };
    }, t.prototype.onClearStore = function(e) {
      var r = this;
      return this.clearStoreCallbacks.push(e), function() {
        r.clearStoreCallbacks = r.clearStoreCallbacks.filter(function(n) {
          return n !== e;
        });
      };
    }, t.prototype.reFetchObservableQueries = function(e) {
      return this.queryManager.reFetchObservableQueries(e);
    }, t.prototype.refetchQueries = function(e) {
      var r = this.queryManager.refetchQueries(e), n = [], i = [];
      r.forEach(function(o, s) {
        n.push(s), i.push(o);
      });
      var a = Promise.all(i);
      return a.queries = n, a.results = i, a.catch(function(o) {
        globalThis.__DEV__ !== !1 && I.debug(17, o);
      }), a;
    }, t.prototype.getObservableQueries = function(e) {
      return e === void 0 && (e = "active"), this.queryManager.getObservableQueries(e);
    }, t.prototype.extract = function(e) {
      return this.cache.extract(e);
    }, t.prototype.restore = function(e) {
      return this.cache.restore(e);
    }, t.prototype.addResolvers = function(e) {
      this.localState.addResolvers(e);
    }, t.prototype.setResolvers = function(e) {
      this.localState.setResolvers(e);
    }, t.prototype.getResolvers = function() {
      return this.localState.getResolvers();
    }, t.prototype.setLocalStateFragmentMatcher = function(e) {
      this.localState.setFragmentMatcher(e);
    }, t.prototype.setLink = function(e) {
      this.link = this.queryManager.link = e;
    }, Object.defineProperty(t.prototype, "defaultContext", {
      get: function() {
        return this.queryManager.defaultContext;
      },
      enumerable: !1,
      configurable: !0
    }), t;
  }()
);
globalThis.__DEV__ !== !1 && (Hi.prototype.getMemoryInternals = Ca);
function $i(t) {
  return new $(function(e, r) {
    return new A(function(n) {
      var i, a, o;
      try {
        i = r(e).subscribe({
          next: function(s) {
            if (s.errors && (o = t({
              graphQLErrors: s.errors,
              response: s,
              operation: e,
              forward: r
            }), o)) {
              a = o.subscribe({
                next: n.next.bind(n),
                error: n.error.bind(n),
                complete: n.complete.bind(n)
              });
              return;
            }
            n.next(s);
          },
          error: function(s) {
            if (o = t({
              operation: e,
              networkError: s,
              //Network errors can return GraphQL errors on for example a 403
              graphQLErrors: s && s.result && s.result.errors,
              forward: r
            }), o) {
              a = o.subscribe({
                next: n.next.bind(n),
                error: n.error.bind(n),
                complete: n.complete.bind(n)
              });
              return;
            }
            n.error(s);
          },
          complete: function() {
            o || n.complete.bind(n)();
          }
        });
      } catch (s) {
        t({ networkError: s, operation: e, forward: r }), n.error(s);
      }
      return function() {
        i && i.unsubscribe(), a && i.unsubscribe();
      };
    });
  });
}
(function(t) {
  W(e, t);
  function e(r) {
    var n = t.call(this) || this;
    return n.link = $i(r), n;
  }
  return e.prototype.request = function(r, n) {
    return this.link.request(r, n);
  }, e;
})($);
var Vs = function() {
  function t() {
    this.queuedRequests = [], this.queuedRequests = [];
  }
  return t.prototype.enqueueRequest = function(e) {
    var r = this, n = d({}, e);
    return n.observable = n.observable || new A(function(i) {
      r.queuedRequests.push(n), typeof n.subscriber > "u" && (n.subscriber = {}), n.subscriber.next = n.next || i.next.bind(i), n.subscriber.error = n.error || i.error.bind(i), n.subscriber.complete = n.complete || i.complete.bind(i);
    }), n.observable;
  }, t.prototype.consumeQueue = function() {
    this.queuedRequests.forEach(function(e) {
      e.forward(e.operation).subscribe(e.subscriber);
    }), this.queuedRequests = [];
  }, t;
}(), wn = function(t, e, r) {
  var n = new Error(r);
  throw n.response = t, n.statusCode = t.status, n.result = e, n;
}, Qs = function(t, e) {
  return function(r) {
    return r.text().then(function(n) {
      if (typeof n != "string" || !n.length)
        return n || "";
      try {
        return JSON.parse(n);
      } catch (a) {
        var i = a;
        return i.response = r, i.statusCode = r.status, i.bodyText = n, Promise.reject(i);
      }
    }).then(function(n) {
      return r.status >= 300 && wn(r, n, "Response not successful: Received status code ".concat(r.status)), !n.hasOwnProperty(e) && n.data && !n.data.hasOwnProperty(e) && !n.hasOwnProperty("errors") && wn(r, n, "Server response was missing for query '".concat(t.operationName, "'.")), n;
    });
  };
}, Us = function(t) {
  W(e, t);
  function e(r) {
    var n = t.call(this) || this;
    return n.extractToken = function(i) {
      return i.data ? i.data[n.accessTokenField] : i[n.accessTokenField];
    }, n.accessTokenField = r.accessTokenField || "access_token", n.isTokenValidOrUndefined = r.isTokenValidOrUndefined, n.fetchAccessToken = r.fetchAccessToken, n.handleFetch = r.handleFetch, n.handleResponse = r.handleResponse || Qs, n.handleError = typeof r.handleError == "function" ? r.handleError : function(i) {
      console.error(i);
    }, n.fetching = !1, n.queue = new Vs(), n;
  }
  return e.prototype.request = function(r, n) {
    var i = this;
    if (typeof n != "function")
      throw new Error("[Token Refresh Link]: Token Refresh Link is a non-terminating link and should not be the last in the composed chain");
    return Vo(this.isTokenValidOrUndefined(r).then(function(a) {
      return a ? n(r) : (i.fetching || (i.fetching = !0, i.fetchAccessToken().then(i.handleResponse(r, i.accessTokenField)).then(function(o) {
        var s = i.extractToken(o);
        if (!s)
          throw new Error("[Token Refresh Link]: Unable to retrieve new access token");
        return s;
      }).then(function(o) {
        return i.handleFetch(o, r);
      }).catch(function(o) {
        return i.handleError(o, r);
      }).finally(function() {
        i.fetching = !1, i.queue.consumeQueue();
      })), i.queue.enqueueRequest({
        operation: r,
        forward: n
      }));
    })).flatMap(function(a) {
      return a;
    });
  }, e;
}($);
class Pe extends Error {
}
Pe.prototype.name = "InvalidTokenError";
function zs(t) {
  return decodeURIComponent(atob(t).replace(/(.)/g, (e, r) => {
    let n = r.charCodeAt(0).toString(16).toUpperCase();
    return n.length < 2 && (n = "0" + n), "%" + n;
  }));
}
function Ws(t) {
  let e = t.replace(/-/g, "+").replace(/_/g, "/");
  switch (e.length % 4) {
    case 0:
      break;
    case 2:
      e += "==";
      break;
    case 3:
      e += "=";
      break;
    default:
      throw new Error("base64 string is not of the correct length");
  }
  try {
    return zs(e);
  } catch {
    return atob(e);
  }
}
function Bs(t, e) {
  if (typeof t != "string")
    throw new Pe("Invalid token specified: must be a string");
  e || (e = {});
  const r = e.header === !0 ? 0 : 1, n = t.split(".")[r];
  if (typeof n != "string")
    throw new Pe(`Invalid token specified: missing part #${r + 1}`);
  let i;
  try {
    i = Ws(n);
  } catch (a) {
    throw new Pe(`Invalid token specified: invalid base64 for part #${r + 1} (${a.message})`);
  }
  try {
    return JSON.parse(i);
  } catch (a) {
    throw new Pe(`Invalid token specified: invalid json for part #${r + 1} (${a.message})`);
  }
}
function Gs(t) {
  if (typeof t != "object" || t === null)
    return !1;
  const e = Object.getPrototypeOf(t);
  return (e === null || e === Object.prototype || Object.getPrototypeOf(e) === null) && !(Symbol.toStringTag in t) && !(Symbol.iterator in t);
}
function Hs(t, e, r = "") {
  if (!arguments.length)
    throw new TypeError("Argument 1 `value` is required.");
  if (typeof e != "function")
    throw new TypeError("Argument 2 `isExtractable` must be a function.");
  if (typeof r != "string")
    throw new TypeError("Argument 3 `path` must be a string.");
  const n = /* @__PURE__ */ new Map(), i = /* @__PURE__ */ new Map();
  function a(o, s, c) {
    if (e(o)) {
      const h = i.get(o);
      return h ? h.push(s) : i.set(o, [s]), null;
    }
    const u = Array.isArray(o) || typeof FileList < "u" && o instanceof FileList, f = Gs(o);
    if (u || f) {
      let h = n.get(o);
      const l = !h;
      if (l && (h = u ? [] : (
        // Replicate if the plain object is an `Object` instance.
        o instanceof /** @type {any} */
        Object ? {} : /* @__PURE__ */ Object.create(null)
      ), n.set(
        o,
        /** @type {Clone} */
        h
      )), !c.has(o)) {
        const p = s ? `${s}.` : "", y = new Set(c).add(o);
        if (u) {
          let m = 0;
          for (const b of o) {
            const v = a(
              b,
              p + m++,
              y
            );
            l && h.push(v);
          }
        } else
          for (const m in o) {
            const b = a(
              o[m],
              p + m,
              y
            );
            l && (h[m] = b);
          }
      }
      return h;
    }
    return o;
  }
  return {
    clone: a(t, r, /* @__PURE__ */ new Set()),
    files: i
  };
}
function $s(t, e, r) {
  "name" in r ? t.append(e, r, r.name) : t.append(e, r);
}
function Ys(t) {
  return typeof File < "u" && t instanceof File || typeof Blob < "u" && t instanceof Blob;
}
function Js({
  uri: t = "/graphql",
  useGETForQueries: e,
  isExtractableFile: r = Ys,
  FormData: n,
  formDataAppendFile: i = $s,
  print: a = Ti,
  fetch: o,
  fetchOptions: s,
  credentials: c,
  headers: u,
  includeExtensions: f
} = {}) {
  const h = {
    http: { includeExtensions: f },
    options: s,
    credentials: c,
    headers: u
  };
  return new $((l) => {
    const p = (
      /**
       * @type {import("@apollo/client/core/types.js").DefaultContext & {
       *   clientAwareness?: {
       *     name?: string,
       *     version?: string,
       *   },
       * }}
       */
      l.getContext()
    ), {
      // Apollo Studio client awareness `name` and `version` can be configured
      // via `ApolloClient` constructor options:
      // https://www.apollographql.com/docs/graphos/metrics/client-awareness/#setup
      clientAwareness: { name: y, version: m } = {},
      headers: b
    } = p, v = {
      http: p.http,
      options: p.fetchOptions,
      credentials: p.credentials,
      headers: {
        // Client awareness headers can be overridden by context `headers`.
        ...y && { "apollographql-client-name": y },
        ...m && { "apollographql-client-version": m },
        ...b
      }
    }, { options: E, body: g } = Ii(
      l,
      a,
      wi,
      h,
      v
    ), { clone: S, files: O } = Hs(g, r, "");
    let _ = Di(l, t);
    if (O.size) {
      E.headers && delete E.headers["content-type"];
      const k = n || FormData, N = new k();
      N.append("operations", Ue(S, "Payload"));
      const C = {};
      let Y = 0;
      O.forEach((q) => {
        C[++Y] = q;
      }), N.append("map", JSON.stringify(C)), Y = 0, O.forEach((q, J) => {
        i(N, String(++Y), J);
      }), E.body = N;
    } else if (e && // If the operation contains some mutations GET shouldn’t be used.
    !l.query.definitions.some(
      (k) => k.kind === "OperationDefinition" && k.operation === "mutation"
    ) && (E.method = "GET"), E.method === "GET") {
      const { newURI: k, parseError: N } = ki(_, g);
      if (N)
        return new A((C) => {
          C.error(N);
        });
      _ = k;
    } else
      E.body = Ue(S, "Payload");
    const { controller: T } = hs();
    typeof T != "boolean" && (E.signal && (E.signal.aborted ? (
      // Signal already aborted, so immediately abort.
      T.abort()
    ) : (
      // Signal not already aborted, so setup a listener to abort when it
      // does.
      E.signal.addEventListener(
        "abort",
        () => {
          T.abort();
        },
        {
          // Prevent a memory leak if the user configured abort controller
          // is long lasting, or controls multiple things.
          once: !0
        }
      )
    )), E.signal = T.signal);
    const D = o || fetch;
    return new A((k) => {
      let N;
      return D(_, E).then((C) => (l.setContext({ response: C }), C)).then(_i(l)).then((C) => {
        k.next(C), k.complete();
      }).catch((C) => {
        N || (C.result && C.result.errors && C.result.data && k.next(C.result), k.error(C));
      }), () => {
        N = !0, typeof T != "boolean" && T.abort();
      };
    });
  });
}
var tr;
(function(t) {
  t.INVALID_EMAIL_PASSWORD = "INVALID_EMAIL_PASSWORD", t.EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS", t.EMAIL_NOT_CONFIRMED = "EMAIL_NOT_CONFIRMED", t.EMAIL_ALREADY_CONFIRMED = "EMAIL_ALREADY_CONFIRMED", t.INVALID_RESET_PASSWORD_TOKEN = "INVALID_RESET_PASSWORD_TOKEN", t.INVALID_CONFIRM_EMAIL_TOKEN = "INVALID_CONFIRM_EMAIL_TOKEN", t.ACCESS_TOKEN_EXPIRED = "ACCESS_TOKEN_EXPIRED", t.NOT_AUTHENTICATED = "NOT_AUTHENTICATED";
})(tr || (tr = {}));
var Tn;
(function(t) {
  t.NOT_FOUND = "NOT_FOUND";
})(Tn || (Tn = {}));
var In;
(function(t) {
  t.SESSION_HAS_REGISTRATIONS = "SESSION_HAS_REGISTRATIONS", t.SESSION_HAS_NO_REGISTRATION = "SESSION_HAS_NO_REGISTRATION", t.SESSION_DONE = "SESSION_DONE", t.SESSION_STARTED = "SESSION_STARTED", t.SESSION_IS_NOT_STARTED = "SESSION_IS_NOT_STARTED", t.SESSION_DEBRIEF_IS_NOT_DONE = "SESSION_DEBRIEF_IS_NOT_DONE";
})(In || (In = {}));
var Dn;
(function(t) {
  t.DUPLICATE_CODE = "MISSION_DUPLICATE_CODE", t.ALREADY_ASSIGNED = "MISSION_ALREADY_ASSIGNED", t.MISSION_HAS_BEEN_UPDATED = "MISSION_HAS_BEEN_UPDATED";
})(Dn || (Dn = {}));
var kn;
(function(t) {
  t.STUDY_HAS_BEEN_UPDATED = "STUDY_HAS_BEEN_UPDATED", t.STUDY_IS_HANDLED_FROM_ADMIN = "STUDY_IS_HANDLED_FROM_ADMIN", t.IS_TOO_EARLY = "IS_TOO_EARLY", t.IS_TOO_LATE = "IS_TOO_LATE", t.INVALID_STATUS = "INVALID_STATUS";
})(kn || (kn = {}));
var Rn;
(function(t) {
  t.OVERLAPPING_PERIODS = "OVERLAPPING_PERIODS", t.NO_SET_PERIOD = "NO_SET_PERIOD";
})(Rn || (Rn = {}));
var Nn;
(function(t) {
  t.UNIQUE_NAME_FOR_ESTABLISHMENT = "CLASS_ROOM_UNIQUE_NAME_FOR_ESTABLISHMENT";
})(Nn || (Nn = {}));
var An;
(function(t) {
  t.OVERLAPPING_STUDIES = "OVERLAPPING_STUDIES_CODE";
})(An || (An = {}));
var Fn;
(function(t) {
  t.EMPTY_COLUMN = "EMPTY_COLUMN", t.MISSING_ESTABLISHMENT = "MISSING_ESTABLISHMENT", t.MISSING_TIME_SLOT = "MISSING_TIME_SLOT", t.MISSING_PLAN = "MISSING_PLAN", t.MISSING_THEMATIC = "MISSING_THEMATIC", t.MISSING_LEVEL = "MISSING_LEVEL", t.MISSING_INTERNSHIP = "MISSING_INTERNSHIP", t.UNKNOWN_LEVEL = "UNKNOWN_LEVEL", t.UNKNOWN_WEEKDAY = "UNKNOWN_WEEKDAY", t.INVALID_DATE = "INVALID_DATE", t.INVALID_EMAIL = "INVALID_EMAIL", t.INVALID_PHONE = "INVALID_PHONE", t.INVALID_STRIPE_CUSTOMER_ID = "INVALID_STRIPE_CUSTOMER_ID", t.INVALID_REGISTRATION_TYPE = "INVALID_REGISTRATION_TYPE";
})(Fn || (Fn = {}));
var Pn;
(function(t) {
  t.TUTOR_IS_IN_MISSION = "IS_IN_MISSION";
})(Pn || (Pn = {}));
var Mn;
(function(t) {
  t.RESET_PASSWORD_MAX_RETRIES_EXCEEDED = "RESET_PASSWORD_MAX_RETRIES_EXCEEDED", t.INVALID_ACCESS_TOKEN = "INVALID_ACCESS_TOKEN", t.ROLE_NOT_ALLOWED = "ROLE_NOT_ALLOWED", t.FORBIDDEN = "FORBIDDEN";
})(Mn || (Mn = {}));
function Xs() {
  const t = $i(({ graphQLErrors: i, networkError: a }) => {
    i && i.forEach(({ message: o, locations: s, path: c, extensions: u }) => {
      u.code !== tr.NOT_AUTHENTICATED && console.error(
        `[GraphQL error]: Message: ${o}, Location: ${JSON.stringify(
          s
        )}, Path: ${c}, Extensions: ${JSON.stringify(u)}`
      );
    }), a && console.error(`[Network error]: ${a}`);
  }), e = new $(
    (i, a) => new A((o) => {
      let s;
      return Promise.resolve(i).then((c) => {
        const u = Cn();
        u && c.setContext({
          headers: {
            Authorization: `Bearer ${u}`,
            "Apollo-Require-Preflight": !0
          }
        });
      }).then(() => {
        s = a(i).subscribe({
          next: o.next.bind(o),
          error: o.error.bind(o),
          complete: o.complete.bind(o)
        });
      }).catch(o.error.bind(o)), () => {
        s && s.unsubscribe();
      };
    })
  ), r = new Us({
    accessTokenField: "accessToken",
    isTokenValidOrUndefined: async () => {
      const i = Cn();
      if (!i)
        return !0;
      try {
        const { exp: a } = Bs(i);
        return Date.now() < a * 1e3;
      } catch {
        return !1;
      }
    },
    fetchAccessToken: () => fetch(`${import.meta.env.VITE_APP_API_URL}/refresh-token`, {
      method: "POST",
      credentials: "include"
    }),
    handleFetch: (i) => {
      typeof localStorage < "u" && i && localStorage.setItem(Ye, i);
    },
    handleError: () => {
      console.error("Your refresh token is invalid. Try to relogin"), Ks().then(() => window.location.assign(`${import.meta.env.VITE_APP_ROOT_URL}/login`));
    }
  }), n = Js({
    uri: `${import.meta.env.VITE_APP_API_URL || "http://localhost:3000"}/graphql`,
    credentials: "include"
  });
  return $.from([r, t, e, n]);
}
const Ye = "apollo-token";
let qe;
function eu() {
  return qe || (qe = new Hi({
    cache: new zi(),
    link: Xs(),
    credentials: "include",
    defaultOptions: {
      query: {
        fetchPolicy: "network-only"
      },
      watchQuery: {
        fetchPolicy: "cache-and-network"
      }
    }
  })), qe;
}
async function tu(t) {
  typeof localStorage < "u" && t && localStorage.setItem(Ye, t);
  try {
    await qe.resetStore();
  } catch (e) {
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}
async function Ks() {
  typeof localStorage < "u" && localStorage.removeItem(Ye);
  try {
    await qe.resetStore();
  } catch (t) {
    console.log("%cError on cache reset (logout)", "color: orange;", t.message);
  }
}
function Cn() {
  let t = null;
  return typeof localStorage < "u" && (t = localStorage.getItem(Ye)), t;
}
const ru = () => {
  let t = !1;
  return typeof localStorage < "u" && (t = localStorage.getItem(Ye) !== null), t;
}, nu = (t, e, r) => {
  const n = e.meta.authorize, i = encodeURIComponent(`${window.location.pathname}${window.location.search}`);
  if (n) {
    if (!t)
      return window.location.assign(`${import.meta.env.VITE_APP_ROOT_URL}/login?redirect=${i}`), !0;
    if (t.role === "SUPER_ADMIN")
      return !0;
    if (n.length && !n.includes(t.role))
      return r.path === "/" ? (window.location.assign(`${import.meta.env.VITE_APP_ROOT_URL}/login`), !0) : !1;
  }
  const a = [
    "login",
    "confirmation",
    "confirm",
    "forgot-password",
    "reset-password",
    "register"
  ];
  return e.matched.some((o) => a.includes(String(o.name))) && t ? (Ki(t.role, e), !1) : !0;
};
export {
  Ye as AUTH_TOKEN,
  nu as canUserAccess,
  Cn as getAccessToken,
  Xs as getApolloLink,
  eu as getClient,
  Xi as getRoleRootPath,
  ru as isLoggedIn,
  tu as onLogin,
  Ks as onLogout,
  Ki as redirectAfterLogin
};
